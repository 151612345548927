import { handleApiCalls } from ".";
import { showGlobalSnackbar } from "../globalSnackbarActions";

export async function LoggedInPasswordReset(email,oldPassword,password,confirmPassword,navigate,dispatch){
    console.log("LoggedInPasswordResetInputs",{
        "username": email,
        "current_password": oldPassword,
        "new_password": password,
        "confirm_new_password": confirmPassword 
    })
    try {
        const PasswordResetResp = await handleApiCalls(
            "post",
            `admin/password-reset`,
            `modifyPassword`,
            {
                "username": email,
                "current_password": oldPassword,
                "new_password": password,
                "confirm_new_password": confirmPassword 
            }
        )
        
        if(PasswordResetResp[2]!==""){
            throw new Error(PasswordResetResp[2])
        }
        await dispatch(showGlobalSnackbar(PasswordResetResp[1],"success"))
        await navigate("/")
    } catch (error) {
        dispatch(showGlobalSnackbar(error.message,"error"))
    }
}