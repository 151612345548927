import {
  DASHBOARD_LAYOUT_REQUEST,
  DASHBOARD_LAYOUT_SUCCESS,
  DASHBOARD_LAYOUT_FAIL,
  DASHBOARD_LAYOUT_KEY_DELETE,
  DASHBOARD_LAYOUT_EDIT_REQUEST,
  DASHBOARD_LAYOUT_EDIT_SUCCESS,
  DASHBOARD_LAYOUT_EDIT_FAIL,
  DASHBOARD_ALL_KPIS_LIST_REQUEST,
  DASHBOARD_ALL_KPIS_LIST_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_FAIL,
  DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_REQUEST,
  DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_FAIL,
  DASHBOARD_KPI_DATA_REQUEST,
  DASHBOARD_KPI_DATA_SUCCESS,
  DASHBOARD_KPI_DATA_FAIL,
  DASHBOARD_KPI_DATA_DELETE,
  DASHBOARD_KPI_DATA_GRAPH_TYPE_CHANGE,
  DASHBOARD_KPI_DATA_UPDATE,
} from "../constants/dashboardConstants";


let dashboardLayoutinitialState = {
  loading: true,
  dashboardLayout: {},
  error: "",
};
export const dashboardLayOutReducer = (
  state = dashboardLayoutinitialState,
  action
) => {
  let updated_layout_info = {};
  switch (action.type) {
    case DASHBOARD_LAYOUT_REQUEST:
      return { loading: true, dashboardLayout: {} };

    case DASHBOARD_LAYOUT_EDIT_REQUEST:
      return { loading: false, dashboardLayout: { ...state.dashboardLayout } };

    case DASHBOARD_LAYOUT_SUCCESS:
      return { loading: false, dashboardLayout: action.payload };

    case DASHBOARD_LAYOUT_EDIT_SUCCESS:
      return { loading: false, dashboardLayout: action.payload };

    case DASHBOARD_LAYOUT_EDIT_FAIL:
      return { loading: false, error: action.payload };

    case DASHBOARD_LAYOUT_FAIL:
      return { loading: false, error: action.payload };

    case DASHBOARD_LAYOUT_KEY_DELETE:
      updated_layout_info = {};
      Object.entries(state.dashboardLayout).forEach(([key, value]) => {
        updated_layout_info[key] = [];
        Object.entries(value).forEach(([key1, value1]) => {
          if (value1["i"] !== `${action.payload}`) {
            updated_layout_info[key].push(value1);
          }
        });
      });

      return {
        ...state,
        loading: false,
        dashboardLayout: { ...updated_layout_info },
      };

    default:
      return state;
  }
};


let dashboardKpisAllListinitialState = {
  loading: true,
  dashboardAllKpisNameList: {},
  error: "",
};
export const dashboardKpisListReducer = (
  state = dashboardKpisAllListinitialState,
  action
) => {
  switch (action.type) {
    case DASHBOARD_ALL_KPIS_LIST_REQUEST:
      return { loading: true, dashboardAllKpisNameList: {} };

    case DASHBOARD_ALL_KPIS_LIST_SUCCESS:
      return {
        loading: false,
        dashboardAllKpisNameList: action.payload,
      };

    case DASHBOARD_ALL_KPIS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_REQUEST:
      return {
        loading: false,
        dashboardAllKpisNameList: { ...state.dashboardAllKpisNameList },
      };

    case DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS:
      var newData = {};
      var newValue = state.dashboardAllKpisNameList[action.payload];
      newValue[1] = false;
      newData[action.payload] = newValue;
      return {
        ...state,
        loading: false,
        dashboardAllKpisNameList: {
          ...state.dashboardAllKpisNameList,
          ...newData,
        },
      };

    case DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS:
      var newData = {};
      var newValue = state.dashboardAllKpisNameList[action.payload];
      newValue[1] = true;
      newData[action.payload] = newValue;
      return {
        ...state,
        loading: false,
        dashboardAllKpisNameList: {
          ...state.dashboardAllKpisNameList,
          ...newData,
        },
      };

    case DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


let dashboardKpisDatainitialState = {
  loading: true,
  dashboardKpisData: {},
  error: {},
};
export const dashboardKpisDataReducer = (
  state = dashboardKpisDatainitialState,
  action
) => {
  switch (action.type) {
    case DASHBOARD_KPI_DATA_REQUEST:
      return {
        loading: false,
        dashboardKpisData: { ...state.dashboardKpisData },
        error: { ...state.error },
      };

    case DASHBOARD_KPI_DATA_SUCCESS:
      var item = action.payload;
      var ItemKey = item.kpiId;
      var newData = state.dashboardKpisData;
      newData[ItemKey] = item;
      var error_status = state.error
      error_status[ItemKey] = '';
      return {
        ...state,
        loading: false,
        dashboardKpisData: newData,
        error: error_status
      };

    case DASHBOARD_KPI_DATA_FAIL:
      var item = action.kpiData;
      var ItemKey = action.kpiId;
      var newData = state.dashboardKpisData;
      newData[ItemKey] = item;
      var error_status = state.error
      error_status[ItemKey] = action.payload;
      return {
        ...state,
        loading: false,
        dashboardKpisData: newData,
        error: error_status
      };

    case DASHBOARD_KPI_DATA_DELETE:
      const nextData = { ...state.dashboardKpisData };
      delete nextData[action.payload];
      return {
        ...state,
        loading: false,
        dashboardKpisData: { ...nextData },
      };

    case DASHBOARD_KPI_DATA_GRAPH_TYPE_CHANGE:
      var type = action.payload[0];
      var ItemKey = action.payload[1].toString();
      var newData = state.dashboardKpisData[ItemKey];
      newData["type"] = type;
      return {
        ...state,
        loading: false,
        dashboardKpisData: { ...state.dashboardKpisData, ...newData },
      };

    case DASHBOARD_KPI_DATA_UPDATE:
      var item = action.payload;
      var ItemKey = item.kpiId;
      var newData = state.dashboardKpisData;
      newData[ItemKey] = item;
      return {
        ...state,
        loading: false,
        dashboardKpisData: newData,
      };

    default:
      return state;
  }
};
