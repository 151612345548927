import * as React from 'react';
import {Box,Button,Stack,Divider,TextField,MenuItem,IconButton, Skeleton,  Typography,FormControl, Select } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Slide from '@mui/material/Slide';
import ToggleSlideDialog from './toggleSlideDialog';

import CustomSwitch from "../customSwitchIcon";
import {WhereConditionBuilder} from "./whereCondition";
import AddJoins from "./joinClause";
import AddGroupBy from "./groupByClause";
import AddHavingClause from "./havingClause";
import { handleApiCallsVisualizationEngine } from "../../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../../actions/globalSnackbarActions";

function isDeepEmpty(input) {
  // catch null or undefined object
  if (!input && !(typeof input === 'boolean')) return true
  if (typeof input === 'object') {
    for (const entry of Object.values(input)) {
      if (isDeepEmpty(entry)) {
        return true
      }
    }
    return false
  } else if (typeof input === 'string') {
  //   console.log(input)
    if (input === ""){
      return true
    }
  }
  // if input is not an object return false
  return false
}


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    borderRadius: 10,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#e0e0e0',
  },
  '& .MuiInputLabel-root': {
    borderRadius: 10,
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 10,
    // background: "#f2f2f2",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
    '&.Mui-focused fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function KpiDetailsForm({selecetedDatabase, kpiDetailsData, setkpiDetailsData, DataTables, setDataTables, errorActive, seterrorActive}){
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `database-connection/${selecetedDatabase}/database-tables`,
      ""
    ).then((responseData) => {
      // console.log(responseData[1]['tables'])
      setDataTables(responseData[1]['tables']);
    }).catch((error) => {
      console.log(error);
      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    });
  }, []);
  
  const [whereConditionEnable, setwhereConditionEnable] = React.useState(kpiDetailsData.whereClauseValue !== '(1 = 1)');

  const addJoin = () => {
    setkpiDetailsData({...kpiDetailsData, joinClauseValue : [...kpiDetailsData.joinClauseValue, {"rightTable": "", "leftTable": "", "joinType": "", "onCondition": {
      condition: "AND", 
      rule: [{leftTableColumn : "", rightTableColumn :"", operator: ""}]
  }}]})}

  const addHaving = () => {
    setkpiDetailsData({...kpiDetailsData, havingClauseValue : [...kpiDetailsData.havingClauseValue, {
      'agrFunction': '',
      'havingColumn': '',
      'havingOperator': '',
      'havingValue': ''
    }]})}

  const addGroupBy = () => {
    if(kpiDetailsData.groupByClauseValue.length === 0){
      setkpiDetailsData({...kpiDetailsData, groupByClauseValue : [{"columns": ""}]})
    }
  }

  const collectSelectedTableInfo = () => {
    let data = {
      primaryTableValue : kpiDetailsData['primaryTableValue'],
      joinClauseValue : [...kpiDetailsData['joinClauseValue']]
    } 
    if(isDeepEmpty(data)){
      seterrorActive(true)
    } else {
      let selectedCols = []
      if(kpiDetailsData.tableSelectEnable){
        let check = []
        check.push(kpiDetailsData['primaryTableValue'])
        for(let i = 0; i < Object.keys(DataTables[kpiDetailsData['primaryTableValue']]).length; i++){
          selectedCols.push(`${kpiDetailsData['primaryTableValue']}.${Object.keys(DataTables[kpiDetailsData['primaryTableValue']])[i]}`)
        }

        for(let j = 0; j < kpiDetailsData['joinClauseValue'].length; j++) {
          let rtable = kpiDetailsData['joinClauseValue'][j].rightTable
          let ltable = kpiDetailsData['joinClauseValue'][j].leftTable
          
          if(check.indexOf(rtable) === -1){
            check.push(rtable)
            for(let i = 0; i < Object.keys(DataTables[rtable]).length; i++) {
              selectedCols.push(`${rtable}.${Object.keys(DataTables[rtable])[i]}`)
            }
          }
          if(check.indexOf(ltable) === -1){
            check.push(ltable)
            for(let i = 0; i < Object.keys(DataTables[ltable]).length; i++) {
              selectedCols.push(`${ltable}.${Object.keys(DataTables[ltable])[i]}`)
            }
          }
        }
        
        // setkpiDetailsData({...kpiDetailsData, selectedTableColumns: [1,2,3]});
      }
      setcardExpanded('');  //to close all Accordion
      setkpiDetailsData({...kpiDetailsData, selectedTableColumns: selectedCols, tableSelectEnable: !kpiDetailsData.tableSelectEnable, whereClauseValue : '(1 = 1)', groupByClauseValue : [], havingClauseValue : []});
    }
  }

  // const handleRemoveItem = (index, keyName) => {
  //   let newObj={}
  //   const allItems = [...kpiDetailsData[keyName]];
  //   allItems.splice(index, 1);
  //   newObj[keyName]=[...allItems]
  //   setkpiDetailsData({...kpiDetailsData, ...newObj});
  // };

  // React.useEffect(() => {
  //   console.log(kpiDetailsData)  
  // }, [kpiDetailsData]);



  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [cardExpanded, setcardExpanded] = React.useState('');

  // setdatabaseValue(() => Databases[0].value);
  return (
    
    DataTables === null ?
      <Box
        pt={2}
        component="form"
        noValidate
        autoComplete="off"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between", }}
      >
        <Skeleton variant="rounded" sx={{ width: '90%' }} height={'300px'} />
      </Box> :
      Object.keys(DataTables).length ?
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between", }}
        >
          <Stack sx={{ width: '90%' }} spacing={2} pt={3} pb={3}>
            <Box>
              <CustomTextField
                // InputProps={{ sx: { borderRadius: 3,  border: `none !important` }, }}
                // className={}
                disabled={!kpiDetailsData.tableSelectEnable}
                fullWidth
                id="primaryTable"
                select
                label="Select Primary Table"
                variant="outlined"
                value={kpiDetailsData.primaryTableValue}
                error={errorActive ? kpiDetailsData.primaryTableValue === '' : false}
                onChange={e => { setkpiDetailsData({ ...kpiDetailsData, primaryTableValue: e.target.value }) }}
              >
                <MenuItem key={`please_select_primary_table`} value='' disabled>
                  Please Select A Primary Table
                </MenuItem>
                {Object.keys(DataTables).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Box>
            {/* <Box sx={{display: 'flex', flexDirection: 'row',alignItems: 'center',  width:'100%'}}> */}
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'Left', }}>
              <Accordion key={`panel_add_join`} expanded={cardExpanded === `panel_add_join`}
                onChange={handleCardExpansionChange(`panel_add_join`)} 
                // disabled={!kpiDetailsData.tableSelectEnable}
              >
                <AccordionSummary aria-controls={`panel_add_join-content`} id={`panel_add_join-header`}>
                  <Typography sx={{ fontSize: "20px" }}>Add Joins</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "15px" }}>Add Joins to fetch data from different tables by using right, left or inner join</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addJoin}
                      disabled={!kpiDetailsData.tableSelectEnable}
                    >
                      Add Joins
                    </Button>
                  </Box>
                  <AddJoins kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} DataTables={DataTables} errorActive={errorActive} disabled={!kpiDetailsData.tableSelectEnable} />

                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', }}>
              <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked={!kpiDetailsData.tableSelectEnable}
                onChange={collectSelectedTableInfo
                  // () => {
                  // setcardExpanded('');  //to close all Accordion
                  // setkpiDetailsData({...kpiDetailsData, tableSelectEnable: !kpiDetailsData.tableSelectEnable});}
                }
              ></CustomSwitch>
              <Typography>Toggle it on to add Condition / Group By Clause / Having Clause</Typography>
            </Box> */}
              <ToggleSlideDialog kpiDetailsDatatableSelectEnable={kpiDetailsData.tableSelectEnable} collectSelectedTableInfo={collectSelectedTableInfo}/>

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'Left', }}>
              <Accordion key={`panel_add_condition`} expanded={cardExpanded === `panel_add_condition`}
                onChange={handleCardExpansionChange(`panel_add_condition`)} disabled={kpiDetailsData.tableSelectEnable}
              >
                <AccordionSummary aria-controls={`panel_add_condition-content`} id={`panel_add_condition-header`}>
                  <Typography sx={{ fontSize: "20px" }}>Add Condition</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "15px" }}>Add Condition to fetch data</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setwhereConditionEnable(true)}
                    >
                      Add Condition
                    </Button>
                  </Box>
                  {whereConditionEnable ? <Box
                    pt={2}
                  >
                    <WhereConditionBuilder kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} />
                  </Box> : <></>}
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'Left', }}>
              <Accordion key={`panel_add_group_by`} expanded={cardExpanded === `panel_add_group_by`}
                onChange={handleCardExpansionChange(`panel_add_group_by`)} disabled={kpiDetailsData.tableSelectEnable}
              >
                <AccordionSummary aria-controls={`panel_add_group_by-content`} id={`panel_add_group_by-header`}>
                  <Typography sx={{ fontSize: "20px" }}>Add Group By</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "15px" }}>Add Group By clause</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addGroupBy}
                    >
                      Add Group By
                    </Button>
                  </Box>
                  <AddGroupBy kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} errorActive={errorActive} />
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'Left', }}>
              <Accordion key={`panel_add_having`} expanded={cardExpanded === `panel_add_having`}
                onChange={handleCardExpansionChange(`panel_add_having`)} disabled={kpiDetailsData.tableSelectEnable || !kpiDetailsData.groupByClauseValue?.length || kpiDetailsData.groupByClauseValue[0]['columns'] === ''}
              >
                <AccordionSummary aria-controls={`panel_add_having-content`} id={`panel_add_having-header`}>
                  <Typography sx={{ fontSize: "20px" }}>Add Having Clause</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "15px" }}>Add Having Clause</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addHaving}
                    >
                      Add Having Clause
                    </Button>
                  </Box>
                  <AddHavingClause kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} errorActive={errorActive}>
                  </AddHavingClause>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'Left', }}>
              <Accordion key={`panel_add_distinct`} expanded={cardExpanded === `panel_add_distinct`}
                onChange={() => (
                  setkpiDetailsData({ ...kpiDetailsData, distinctAdd: !kpiDetailsData.distinctAdd })
                )}
              >
                <AccordionSummary width={'100%'} aria-controls={`panel_add_distinct-content`} id={`panel_add_distinct-header`}>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "20px" }}>Add Distinct</Typography>
                    <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked={kpiDetailsData.distinctAdd}> </CustomSwitch>
                  </Box>
                </AccordionSummary>
              </Accordion>
            </Box>

            {/* </Box> */}

          </Stack>

        </Box>
        :
        <Box width={"90%"} margin="auto" paddingTop={"20px"}>
          <Typography variant="h3"> No Data found</Typography>
        </Box>
  
  );
}

export default KpiDetailsForm;
  