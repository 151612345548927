import React from "react";
import LineGraph from "../../../../components/Graphs/recharts/LineGraph";
import LineGraphKPI from "../../../../components/Graphs/recharts/LineGraph KPI";
import StackedBarGraphKPI from "../../../../components/Graphs/recharts/StackedBarGraphKPI";
import AreaGraph from "../../../../components/Graphs/recharts/AreaGraph";
import StackedBarGraph from "../../../../components/Graphs/recharts/StackedBarGraph";
import PieGraph from "../../../../components/Graphs/recharts/PieGraph";
import PieGraphKPI from "../../../../components/Graphs/recharts/PieGraphKPI";
import { ResponsiveContainer } from 'recharts';
import InfoCard from "../../../../components/Graphs/recharts/infoCard";
import BoxPlot from "../../../../components/Graphs/recharts/BoxPlot";
import Radialchart from "../../../../components/Graphs/recharts/RadialChart";
import Scatterplot from "../../../../components/Graphs/recharts/ScatterChart";
import HeatmapChart from "../../../../components/Graphs/recharts/HeatMap";

const KpiCardBody = ({ data, width, height }) => {
  // console.log(data)
  switch (data.type) {
    case "pie":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <PieGraph width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "line":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <LineGraph width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "Line Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <LineGraphKPI width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "Bar Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <StackedBarGraphKPI width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "Pie Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <PieGraphKPI width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "bar":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <StackedBarGraph width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "Area Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <AreaGraph width={width} height={height} data={data} />
        </ResponsiveContainer>
      );
    case "Heatmap Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <HeatmapChart width={width} height={height} data={data} />
        </ResponsiveContainer>
      )
    case "Scatterplot Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <Scatterplot width={width} height={height} data={data} />
        </ResponsiveContainer>
      )
    case "Radial Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <Radialchart width={width} height={height} data={data} />
        </ResponsiveContainer>
      )
    case "Box Plot Chart":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <BoxPlot width={width} height={height} data={data} />
        </ResponsiveContainer>
      )
    case "InfoCard":
      return (
        <ResponsiveContainer height={height} width={width}>
                  <InfoCard width={width} height={height} tableData={data} type={"InfoCard"} />
        </ResponsiveContainer>
      )
    default:
      return null;
  }

  // switch (data.type) {
  //   case "pie":
  //     return (
  //       <PieGraph width={width} height={height} data={data} />
  //     );
  //   case "line":
  //     return (
  //       <LineGraph width={width} height={height} data={data} />
  //     );
  //   case "Line Chart":
  //     return (
  //       <ResponsiveContainer width="100%" height="100%">
  //         <LineGraphKPI width={width} height={height} data={data} />
  //       </ResponsiveContainer>
  //     );
  //   case "Bar Chart":
  //     return (
  //       <ResponsiveContainer width="100%" height="100%">
  //         <StackedBarGraphKPI width={width} height={height} data={data} />
  //       </ResponsiveContainer>
  //     );
  //   case "Pie Chart":
  //     return (
  //       <ResponsiveContainer width="100%" height="100%">
  //         <PieGraphKPI width={width} height={height} data={data} />
  //       </ResponsiveContainer>
  //     );
  //   case "bar":
  //     return (
  //       <StackedBarGraph width={width} height={height} data={data} />
  //     );
  //   case "area":
  //     return (
  //       <AreaGraph width={width} height={height} data={data} />
  //     );
  //   default:
  //     return null;
  }


export default KpiCardBody;
