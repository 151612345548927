import { 
    SEARCHING_TEXT,
    SET_ADMIN_DATA ,
    UPDATE_ADMIN_DATA,
    SLIDE_UP_EDIT_INSTANCE,
    SLIDE_STATUS,
    DELETE_ADMIN_DATA,
    GO_SEARCH,
    GET_ROLES
} from "../constants/adminManagementConstants"

const initalState={
    searchTerm:"",
    allAdminData:null,
    slideUpEditInstance:null,
    slideStatus:false,
    searchResult:null,
    getRoles:[]
}
export let adminManagementReducer =(state=initalState,action)=>{
    switch(action.type){
        case SEARCHING_TEXT : return (
            state = {
                ...state,
                searchTerm:action.payload
            }
        )

        case SET_ADMIN_DATA: return (
            state={
                ...state,
                allAdminData:action.payload
            }
        )

        case UPDATE_ADMIN_DATA: return (
            state={
                ...state,
                allAdminData:action.payload
            }
        )

        case SLIDE_UP_EDIT_INSTANCE: return (
            state={
                ...state,
                slideUpEditInstance:action.payload
            }
        )

        case SLIDE_STATUS: return (
            state={
                ...state,
                slideStatus:action.payload
            }
        )

        case DELETE_ADMIN_DATA: return (
            state={
                ...state,
                allAdminData:action.payload
            }
        )

        case GO_SEARCH : return (
            state={
                ...state,
                searchResult:action.payload
            }
        )

        case GET_ROLES : return (
            state={
                ...state,
                getRoles:action.payload
            }
        )

        default : return state
    }
}

