export const SET_ADMIN_FAQ_SEARCH = "SET_ADMIN_FAQ_SEARCH"
    
export const SET_ADMIN_FAQ_DATA = "SET_ADMIN_FAQ_DATA"

export const EDIT_ADMIN_FAQ_DATA = "EDIT_ADMIN_FAQ_DATA"

export const ADD_ADMIN_FAQ_DATA = "ADD_ADMIN_FAQ_DATA"

export const SET_ADMIN_FAQ_SEARCH_RESULT = "SET_ADMIN_FAQ_SEARCH_RESULT"

export const DEL_ADMIN_FAQ_DATA = "DEL_ADMIN_FAQ_DATA"

export const SET_ADMIN_FAQ_LOADING_RESULT = "SET_ADMIN_FAQ_LOADING_RESULT"