import { handleApiCalls } from "./actionHelper";
import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAIL,
} from "../constants/userFeedbackConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { enqueueSnackbar } from "notistack";

export const sumitUserfeedbackData = (feedbackData) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: SUBMIT_FEEDBACK_REQUEST });

    responseData = await handleApiCalls(
      "post",
      "feedback/create",
      "createFeedbackAndSupport",
      feedbackData
    );

    console.log(responseData)
    if (responseData[0] === 201) {
      dispatch({
        type: SUBMIT_FEEDBACK_SUCCESS,
      });
      dispatch(showGlobalSnackbar("Successfully Submitted...", "success"));
    } else {
      dispatch({
        type: SUBMIT_FEEDBACK_FAIL,
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }
  } catch (error) {
    // console.log("userFeedBack error",error)
    if(error.message === "Network Error"){
      dispatch(showGlobalSnackbar(error.message || "Error!", "error"));
    }
    else{
      dispatch(showGlobalSnackbar(error.response.data["error-message"] || "Error!", "error"));
    }
  }
};
