import * as React from 'react';
import {Box,Button,TextField, Tooltip,Typography, Checkbox, Autocomplete, Skeleton, IconButton, Chip, Stack} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { shadows, useTheme } from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: '13px'
  },
});


export default function DashboardAssignModal({open, setOpen, dashboardId, setdashboardId }) {

  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    // boxShadow: 200,
    borderRadius : "20px",
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [roleList, setroleList] = React.useState(null);
  const [showDeleteModal, setshowDeleteModal] = React.useState(false);
  const [deleteItem, setdeleteItem] = React.useState(null);
  const [errorActive, seterrorActive] = React.useState(false);
  const [dashboardRoleList, setdashboardRoleList] = React.useState(undefined);
  const [kpiAdd, setkpiAdd] = React.useState({});


  const handleOpen = () => {
    setOpen(true);
  };
  
  const assignRole = (id) => {
    handleApiCallsVisualizationEngine(
      "post",
      `dashboard/${dashboardId[0]}/role`,
      "",
      {"roleId": id}
      ).then((responseData) => {
        // setroleList([...responseData[1]]);
        getAssignedRoleList()
        dispatch(showGlobalSnackbar("Role assigned successfully.", "success"));
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    // setdashboardRoleList([...dashboardRoleList, id]);
  };
  
  const unassignRole = (id) => {
    setdeleteItem(id)
    setshowDeleteModal(true)
  };

  const getAllRoleList = () => {
    handleApiCallsVisualizationEngine(
      "get",
      `roles/basic-information`,
      ""
      ).then((responseData) => {
        setroleList([...responseData[1]]);
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
  }

  const getAssignedRoleList = () => {
    handleApiCallsVisualizationEngine(
      "get",
      `dashboard/${dashboardId}/roles`,
      ""
      ).then((responseData) => {
        let assignedRoles = []
        let assignedTempRoles = [...responseData[1]]
        assignedTempRoles.map((item, index)=> {
          assignedRoles.push(item.roleId)
        })
        setdashboardRoleList([...assignedRoles]);
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
  }

  React.useEffect(() => {
    if(open){
      getAllRoleList()
      getAssignedRoleList()
    }
  }, [open]);


  const removeRole = (id) => {
    if (id !== ''){
      handleApiCallsVisualizationEngine(
        "post",
        `dashboard/role/delete`,
        "",
        {dashboardId: dashboardId[0], roleId: id}
        ).then((responseData) => {
          setdeleteItem(null)
          dispatch(showGlobalSnackbar("Role removed successfully.", "success"));
          getAssignedRoleList()
        }).catch((error) => {
            // setconfirmDbAdapter(false)
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        }
      );
      }
    }
  


  return (
    open === true && dashboardId !== null?
    <Box>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '40%', height: '60%', minWidth:"310px", minHeight:'200px', display:'flex', flexDirection:'column', alignItems:'start' }}>
          <Box sx={{display:'flex', flexDirection:'row', alignItems:'start', justifyContent:"space-between", width:'100%' }}>
            <Box width={'70%'} pb={3}>
              <CustomWidthTooltip title={`${dashboardId[1]}`} arrow> 
                <Typography noWrap sx={{fontSize:"23px"}}  fontWeight={600}>{`${dashboardId[1]}`}</Typography>
              </CustomWidthTooltip>
              <Typography variant='subtitle1' fontWeight={500}>Assign Dashboard To Users Based On Role - Select Roles : </Typography>
            </Box>
            <Box>
              <IconButton
                sx={{background:'#FF7A7A6b', '&:hover': {background:'#FF5959'}}}
                size='small'
                onClick={() => {
                  setdashboardId(null);
                  setroleList([])
                  setdashboardRoleList(undefined)
                  setOpen(false)
                }}
              >
                <ClearOutlinedIcon/>
              </IconButton>
            </Box>  
          </Box>
          {showDeleteModal === true && deleteItem !== null ?
          <DeleteButtonWithConfirmationPopUp mainData={dashboardRoleList} deleteitem={deleteItem} deleteFunc={removeRole} needButton={false}> </DeleteButtonWithConfirmationPopUp>
          : <></>}
          <Box  height={'250px'} sx={{overflow: 'auto'}} width={'100%'}>
            {
              roleList === null || dashboardRoleList === undefined ?
              <Skeleton variant="rectangular" height={'100%'}></Skeleton> 
              :
              roleList.length > 0 ?
              <Stack direction="column" useFlexGap  flexWrap="wrap">
              {roleList.map((item, index)=> (
                <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"flex-start", 
                  borderBottom: `2px solid ${theme.palette.divider}` , 
                  // background:theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
                  // borderRadius: '10px',
                  height:{md: '40px'}
                  }}  
                  width={'100%'}
                  onClick={()=> {
                    !dashboardRoleList.includes(item.id) ? assignRole(item.id): unassignRole(item.id)
                  }}
                >
                  {/* <Box width={'100%'}> */}
                    <Checkbox 
                      checked={dashboardRoleList.includes(item.id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography noWrap variant='subtitle1' component="div" fontWeight={600} >{`${item.role}`}</Typography>
                  {/* </Box> */}

                </Box>
              ))}
              </Stack>
              :
              <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'100%'}>
                <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Role Available.</Typography>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
    </Box>
    :<></>
  );
}
