import React, { useEffect, useState } from "react";
import { TextField, Typography,Box, Button } from "@mui/material";
import { userValContext } from "./UserAndDevicesTab";
import { handleApiCalls } from "../../../actions/actionHelper";
import { useDispatch } from "react-redux";
import { updateThreshold } from "../../../actions/userAndDevicesActions";
import { useNavigate } from "react-router-dom";
export default function UserAndDevicesThreshold({particularDevicesThresholdData,devicesId,Id,particularThresholdKey,devicesVal}){
    const [thresholdState,setThresholdState] = useState({...particularDevicesThresholdData})
    const [updateEnabled,setUpdateEnabled] = useState(false)
    const userValContextData = React.useContext(userValContext) 
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // useEffect(()=>{console.log(devicesVal)},[devicesVal])
    // useEffect(()=>{console.log(thresholdState)},[thresholdState])
    // useEffect(()=>{console.log(userValContextData)},[userValContextData])

    return (
        <Box key={devicesId} >
            <Typography variant="h5" paddingBottom={"15px"}>{particularThresholdKey.toUpperCase().replaceAll("_"," ")}</Typography>
            <Box display={"flex"} justifyContent={"flex-start"} flexWrap={"wrap"} gap={"15px"}>
                {
                    Object.keys(thresholdState).map((key) => {
                        return (
                            <TextField
                                // type="number"
                                width={"40%"}
                                id="outlined-basic"
                                label={key.toUpperCase().replaceAll("_", " ")}
                                variant="outlined"
                                value={thresholdState[key]}
                                onChange={
                                    (event) => {
                                        let newThresholdState = { ...thresholdState }
                                        newThresholdState[key] = event.target.value
                                        setThresholdState(newThresholdState)
                                        setUpdateEnabled(true)
                                    }
                                }
                            />
                        )
                    })
                }
            </Box>
            {/* {JSON.stringify(Object.keys(thresholdState).every((key)=>{return thresholdState[key].length > 0 ? false : true}))} */}
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Button 
                    variant="contained"
                    disabled={updateEnabled ? false : true}
                    onClick={()=>{
                        let particularKeyThresholdObj = {}
                        particularKeyThresholdObj[particularThresholdKey]=thresholdState
                        dispatch(updateThreshold(
                            {...devicesVal.device_threshold,...particularKeyThresholdObj},
                            userValContextData.user_id,
                            devicesVal.device_id,
                            setUpdateEnabled,
                            navigate
                        ))
                    }}
                >Update threshold</Button>
            </Box>
            
        </Box>
    )
}