import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation ,useNavigate} from 'react-router-dom';
import moment from 'moment';
import { Button, Grid, Typography, Container, TextField, Box, CssBaseline, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { Timer } from '../TimerComponent/Timer';
// import PasswordComponent from './passwordComponent';



export default function AccountLockedComponent() {

    const navigate = useNavigate()
    
    const location = useLocation()
    const QueryParams = new URLSearchParams(location.search)
    const lockedUntil = QueryParams.get("lockedUntil")

    console.log("lockedUntil",lockedUntil)
    const dispatch = useDispatch();
    // const userDate = new Date()
    
    const futureDate=new Date(new Date(lockedUntil).getTime())
    // const futureDate=new Date(new Date(lockedUntil.replace("Z","")).getTime())
    // const futureDate=new Date(new Date().getTime()+6300000)
    const [TimeLimit, setTimelimit] = useState(futureDate.toLocaleString())
    // 12, 15, 2023, 13:55
    //December, 15, 2023, 12:52 pm
    //   use getHours



    const okay = (event) => {
        navigate("/login")
    }
    
    
    return (
        <Container component="main" maxWidth="lg">
            <Box
                sx={{
                    marginTop: 16,
                }}
            >
                <Grid container>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: `url(../../../assets/TempPasswordd.svg)`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) =>
                                t.palette.mode === "light"
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                        pt={1}
                        pb={4}
                        pl={4}
                        pr={4}
                        minHeight={400}
                    >
                        <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                            <Box component="img"
                                sx={{
                                    height: 30,
                                    // display: { xs: 'flex', md: 'none' },
                                    mt: 2
                                }}
                                alt="BridgeWater Logo"
                                src="../../assets/town_of_bridge.png"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant='h3'>
                                Energy Management Information System
                            </Typography>

                            <Typography variant='h3'>
                                User account locked
                            </Typography>
                            <Timer fetchedTimeLimit={TimeLimit}/>
                            <br />
                            <Typography variant='h5'>
                                This account has been locked until 
                                <Typography variant='h3'> {moment(TimeLimit).format('MMMM Do YYYY, h:mm:ss a')} </Typography>
                                {/* <Typography variant='h3'> {new Date(TimeLimit).toLocaleString().replaceAll("/","-")} </Typography>  */}
                            </Typography>
                            <Box width="100%" pt={3}>
                                    
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}

                                        onClick={okay}
                                    >
                                        Okay
                                    </Button>
                            </Box>
                            
                        </Box>
                    </Grid>
                    <Grid container>
                        <Grid item xs >
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}