import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect,useState } from 'react';
import { TextField } from '@mui/material';
import {Tooltip} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {editCategoryData,addCategoryData} from "../../actions/adminDocumentationAction"
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {useTheme} from '@mui/material/styles';
import { tokens } from '../../theme';
import {Typography} from '@mui/material';
import {Box} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useRef } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocumentationCategoryModify({categoryDetails,action}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let dispatch= useDispatch()
  const [open, setOpen] = React.useState(false);
let [importedCategoryDetails,setImportedCategoryDetails]=useState({category_name:null,category_details:null})
  // console.log("categoryDetails",categoryDetails)
  let inputRefs=useRef([])
  const handleClickOpen = () => {
    setOpen(true);
    // console.log("importedCategoryDetails",importedCategoryDetails)
  };

  const handleClose = () => {
    
    if(action=="add"){
      // console.log("Add Category cleared")
      for (let x of inputRefs.current) {
        x.value = null
        // console.log(x.value)
      }
    }
    setOpen(false);  
  };

  useEffect(()=>{
    if(action=='edit'){
      setImportedCategoryDetails({...categoryDetails})
    }
  },[])

  // useEffect(()=>{
  //   console.log(importedCategoryDetails)
  // },[importedCategoryDetails])

  return (
    <React.Fragment>
      {
        action == "edit" ?
          <Button
            sx={{
              height: "25px",
              width: "30px",
              minWidth:"30px",
              marginLeft:"-7px",
              marginTop:"-17px"
            }}
            onClick={handleClickOpen}>
            <Tooltip
              title="Edit Category"
              placement="bottom"
            >
              <EditIcon
                sx={{
                  height: "14px",
                  width: "14px",
                  // marginTop: "-3px"
                }}
              />
            </Tooltip>
          </Button> :
          <Button
            // component="label"
            variant="contained"

            aria-haspopup="true"
            onClick={handleClickOpen}
            startIcon={<AddIcon style={{ color: `${colors.primary[100]}` }} />}
            style={{ backgroundColor: `${colors.primary[700]}` }}

          >
            <Tooltip
              title="Add Category"
              placement="bottom"
            >
              <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.primary[100]}`}>
              {/* <FAQPopUp specificFAQData={{}} action="add"/> */}
              Add Category
            </Typography>
            </Tooltip>
          </Button>
      }
          
              
            
              
          
        
      
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        // maxWidth={"80%"}
        PaperProps={{ sx: { borderRadius: "10px" ,width:"100%"} }}
      >
        <DialogTitle>{action.toUpperCase()} CATEGORY</DialogTitle>
        <DialogContent>
          <DialogContentText 
          sx={{width:"100%"}}
          component="form" //for avoiding fieldset cannot appear as a descendant p error
          id="alert-dialog-slide-description">
            <Typography variant="span"
            sx={{display:"flex", justifyContent:"space-around",flexDirection:"column"}}
            >
              <TextField
                sx={{
                  width: "97.5%",
                  // position:"relative",
                  // left:"3px",
                  margin: "10px"
                }}
                fullWidth
                // multiline
                // rows={2}
                id="outlined-basic" label="Category Name" variant="outlined"
                value={
                  importedCategoryDetails.category_name &&
                  importedCategoryDetails.category_name.length > 0 ? 
                  importedCategoryDetails.category_name 
                  : null
                }
                onChange={(event) => {

                  setImportedCategoryDetails({
                    ...importedCategoryDetails,
                    category_name: event.target.value
                  })
                  // console.log("importedCategoryDetails.category_name",importedCategoryDetails)
                  // console.log(event.target.value)
                }}
                inputRef={(element) => {inputRefs.current[0]=element}}
                
              />
              {
                // action == "add" ?
                  <TextField
                    sx={{
                      width: "97.5%",
                      // position:"relative",
                      // left:"3px",
                      margin: "10px"
                    }}
                    fullWidth
                    // multiline
                    // rows={2}
                    id="outlined-basic" label="Category Details" variant="outlined"
                    value={
                      importedCategoryDetails.category_details &&
                      importedCategoryDetails.category_details.length >0 ? 
                      importedCategoryDetails.category_details 
                      : null
                    }
                    onChange={(event) => {

                      setImportedCategoryDetails({
                        ...importedCategoryDetails,
                        category_details: event.target.value
                      })
                      // console.log("importedCategoryDetails.category_name",importedCategoryDetails)
                      // console.log(event.target.value)
                    }}
                    inputRef={(element) => {inputRefs.current[1]=element}}
                  /> 
                  // :
                  // null
              }
            </Typography>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions 
        // sx={{
        //   position:"relative",
        //   left:"-0px"
        // }}
        >
          <Button onClick={()=>{
            handleClose()
            // console.log("Add Category cleared")
            setImportedCategoryDetails({})
            // console.log("inputRef",inputRefs)
          }}
          variant='contained'
          >Close</Button>
          <Button 
          variant='contained'
          onClick={()=>{
            if(action=="edit"){
              dispatch(editCategoryData(importedCategoryDetails))
              handleClose()
            }
            else{
              dispatch(addCategoryData(importedCategoryDetails))
              setImportedCategoryDetails({})
              handleClose()              
            }
          }}
          disabled={importedCategoryDetails.category_name && importedCategoryDetails.category_details ? false : true}
          >Submit
          </Button>
          {/* {(!importedCategoryDetails.category_name && !importedCategoryDetails.category_details) && 
            (importedCategoryDetails.category_name.length === 0 && importedCategoryDetails.category_details.length) === 0 
            ? true : false} */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


// <Box
//     component={ButtonBase}
//     sx={{
//         borderRadius: "30%",
//         marginTop: "-3px"
//     }}
//     onClick={() => {
//         console.log("edit category")
//     }}
// >
//     <Tooltip
//         title="Edit Category"
//         placement="right"
//     >
//         <EditIcon
//             sx={{
//                 height: "25px",
//                 width: "30px",
//                 // marginTop: "-3px"
//             }}
//         />
//     </Tooltip>
// </Box>