import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DashboardCard from "./dashboardCard";
import { GetAdminDashboardPreviewEachKpiData} from '../../../../../actions/dashboardPreviewActions';

const DashboardCardContainer = React.forwardRef(
  function DashboardCardContainer({children, item, ...props }, ref) {

  const dispatch = useDispatch()


  const admindashboardPreviewKpisData = useSelector(state => state.admindashboardPreviewKpisData)
  const {error: dashboardKpisDataError, dashboardPreviewKpisData} = admindashboardPreviewKpisData


  useEffect(()=>{
    dispatch(GetAdminDashboardPreviewEachKpiData(item));
  }, [ dispatch, item ])

  return (
    <DashboardCard data={dashboardPreviewKpisData[item]} root={item} kpisdataerror={dashboardKpisDataError[item]} {...props} ref={ref}>
      {children}
    </DashboardCard>
  );
})

export default (DashboardCardContainer);
