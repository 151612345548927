import * as React from 'react';
import {Box,Typography,Menu,MenuItem, InputBase, IconButton,ButtonGroup, Button, Tooltip, Skeleton, Stack, Checkbox} from '@mui/material';
import PropTypes from 'prop-types';
import { UpdateDashboardPreviewOneKpiGraphDataByFilter } from "../../actions/dashboardPreviewActions"
import { UpdateDashboardOneKpiGraphDataByFilter } from "../../actions/dashboardActions"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { handleApiCallsVisualizationEngine } from "../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../actions/globalSnackbarActions";
import SearchOutlined from "@mui/icons-material/SearchOutlined"
import { styled } from '@mui/system';

function TabPanel(props) {
  const { children, temp_value, filterValueData, setfilterValueData, filterValueList, index, ...other } = props;
  

    const addRemovefilterValue = (id, name, data) => {
        let obj = [...filterValueData]
        obj.map((item) => {
            if(item.id === id){
              item.values = name !== "Search Filter" ? item.values.includes(data) ? [...item.values.filter((x)=>x!== data)] : [...item.values, data] : data !== "" ? [data]: []
            }
        })
        setfilterValueData([...obj])
    }

  if(temp_value){
    switch (temp_value.name) {
        case "Search Filter":
          return (
            <Box  height={'100%'} width={"100%"} sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', border: '2px solid lightgrey', borderRadius: '10px', width: '95%', marginLeft:"5px" }}
                    
                    style={{ border: "1px solid grey", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                    <InputBase
                        placeholder="   Search"
                        value={temp_value.values}
                        onChange={(e) => {
                            addRemovefilterValue(temp_value.id, temp_value.name, e.target.value)
                        }}
                        style={{
                            paddingRight: "5px",
                            paddingLeft: "5px"
                        }}
                    />
                    <IconButton>
                        <SearchOutlined />
                    </IconButton>
                </Box>
            </Box>
          );
        default:
          return (
            <Box  height={'100%'} width={"100%"} sx={{overflow: 'auto'}}>
            {
              filterValueList === null ?
              <Skeleton variant="rounded" height={'99%'} sx={{ width: '90%', marginLeft:"10px" }}></Skeleton> 
              :
              filterValueList.length > 0 ?
              <Stack direction="column" useFlexGap  flexWrap="wrap">
              {filterValueList.map((item, index)=> (
                <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"flex-start", 
                //   height:{md: '40px'}
                  }}  
                  width={'100%'}
                  onClick={()=> {
                    addRemovefilterValue(temp_value.id, temp_value.name, item)
                  }}
                >
                  {/* <Box width={'100%'}> */}
                    <Checkbox 
                      checked={temp_value.values.includes(item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography noWrap variant='subtitle1' component="div" fontWeight={600} >{`${item}`}</Typography>
                  {/* </Box> */}

                </Box>
              ))}
              </Stack>
              :
              <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'100%'}>
                <Typography fontWeight={700}>No Data Available.</Typography>
              </Box>
            }
          </Box>
          );
    }
    } else{
        return <></>
    }
}


export default function VerticalTabs({filterData, kpiId, handleGraphFilterMenuClose, source=''}) {
  const [value, setValue] = React.useState(null);
  const [filterValueData, setfilterValueData] = React.useState([...filterData])
  const [filterValueList, setfilterValueList] = React.useState(null)
  const dispatch = useDispatch();

  const handlegraphFilterValueChange = (filterDataValue, kpi_id) => {
    let temData = []
    filterDataValue.map((item, index)=> {
      if(item.values.length > 0){
        temData.push(item)
      }
    })
    // console.log(filterData);
    source !== '' ? dispatch(UpdateDashboardPreviewOneKpiGraphDataByFilter(temData, kpi_id)) : dispatch(UpdateDashboardOneKpiGraphDataByFilter(temData, kpi_id))
  };


  const handleChange = (event, newValue) => {
    setValue((value) => null);
    setValue((value) => newValue);
    setfilterValueList((data)=> null)
    if(newValue.name !== "Search Filter"){
        handleApiCallsVisualizationEngine(
            "get",
            `kpi/filterQueryData/${newValue.id}`,
            ""
        ).then((responseData) => {
            setfilterValueList((data)=> responseData[1]['filterList']);
        }).catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }
  };
  const WrappedLabelTypography = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  });

  return (
    <Box pb={1}>
        <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 180, width:"100%" }}
        p={1}
        >
            <Tabs
            width="30%"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', float: 'left', 
                // paddingRight: 2,
                '& .MuiTabs-scroller': { marginRight: -1 },  
                '& .MuiTabScrollButton-root': { display: 'none' }, 
                '& .MuiTab-root': {padding:"0px", minWidth:"fit-content", width: "fit-content", marginRight: "10px" },
            }} 
            >
                {/* <Tab label="Item One"  value={1}/>
                <Tab label="Item 2"  value={2}/> */}
                {filterValueData.map((item, index) => {
                    return <Tab key={index} label={<WrappedLabelTypography>{item.name}</WrappedLabelTypography>} sx={{color: `${Array.isArray(item.values)?  item.values.length ? "green" : "inherit" : "inherit"}`}} value={item}/>
                    // return <Tab key={index} label={<Typography width={'99%'} noWrap={true}>{item.name}</Typography>} sx={{color: `${Array.isArray(item.value)?  item.value.length ? "green" : "inherit" : item.value !== '' ? "green": "inherit"}`}} value={item}/>
                })
            }  
            </Tabs>
            <Box width="70%">
            <TabPanel temp_value={value} filterValueData={filterValueData} setfilterValueData={setfilterValueData} filterValueList={filterValueList}/>
            </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} pr={2}>
            <Button
            variant="contained"
            onClick={()=> {
              // console.log("filterValueData",filterValueData);
              handlegraphFilterValueChange(filterValueData , kpiId); 
              handleGraphFilterMenuClose();
            }} //filterValueData , kpiId
            size="small"
            >
                Apply Filter
            </Button>
        </Box>
    </Box>
  );
}