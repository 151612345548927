import { handleApiCalls } from "./actionHelper";
import {
  GET_ALL_FAQ_REQUEST,
  GET_ALL_FAQ_SUCCESS,
  GET_ALL_FAQ_FAIL,
  FILTER_FAQ_DATA,
} from "../constants/faqConstants";

import { showGlobalSnackbar } from "./globalSnackbarActions";
import { enqueueSnackbar } from "notistack";

export const GetAllFaqData = () => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: GET_ALL_FAQ_REQUEST });

    responseData = await handleApiCalls("get",`faq/fetch-all`, "viewFaq");

     if (responseData[2] === "") {
       dispatch({
         type: GET_ALL_FAQ_SUCCESS,
         payload: responseData[1],
       });
     } else {
       dispatch({
         type: GET_ALL_FAQ_FAIL,
         payload: responseData[2],
       });
       dispatch(showGlobalSnackbar(responseData[2], "error"));
     }

  } catch (error) {
    dispatch({
      type: GET_ALL_FAQ_FAIL,
      payload: error,
    });
    if(typeof error === "string"){
      dispatch(showGlobalSnackbar(error, "error"));
    }
    else{
      dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"));
    }
  }
};


export const FilterFaqData = (searchTerm) => async (dispatch) => {

    dispatch({
      type: FILTER_FAQ_DATA,
      payload: searchTerm,
    });

};