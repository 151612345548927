import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useDispatch } from 'react-redux';

import {IconButton} from "@mui/material"
import {Tooltip,Box} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDocumentationData } from '../../actions/adminDocumentationAction';
import {ButtonBase} from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocumentaionDeletePopUp({CardData}) {
    let dispatch=useDispatch()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <Tooltip title="Delete Document">
        <Box
          sx={{
            height: "25px"
          }}
          component={ButtonBase}
          onClick={() => {
            //   console.log("faqDetails", faqDetails)
            // console.log("Delete Document")
            handleClickOpen()

          }}
          // for disabling parents ripple effect and redirect
          onMouseDown={(event)=>{
            event.stopPropagation()
            
          }}
        >
          <DeleteIcon
            sx={{
              height: "18px",
              width: "18px",
            }}
          />
        </Box>
        </Tooltip>
      {/* </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to delete this Document ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          variant="contained"
        //   color="success"
          onClick={handleClose}>Cancel</Button>
          <Button 
          variant="contained"
          color="error"
          startIcon={<DeleteIcon/>}
          onClick={()=>{
            dispatch(deleteDocumentationData(CardData))
            handleClose()
          }}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
