import { IDENTIFY_USER_RESP,SET_USER_PASSWORD_RESP,LOCAL_USER_CREDENTIALS,AUTHENTICATION_TYPE } from "../constants/userConstants";


let inital_state = {
    IDENTIFY_USER_RESP:null,
    SET_USER_PASSWORD_RESP:null,
    LOCAL_USER_CREDENTIALS:null,
    AUTHENTICATION_TYPE:""
}

export const userReducer2 = (state=inital_state,action)=>{
    switch (action.type) {
        case IDENTIFY_USER_RESP:
            return (
                state={
                    ...state,
                    IDENTIFY_USER_RESP:action.payload
                }
            )
        case SET_USER_PASSWORD_RESP :
            return (
                state={
                    ...state,
                    SET_USER_PASSWORD_RESP:action.payload
                }
            )
        case LOCAL_USER_CREDENTIALS: return (
            state={
                ...state,
                LOCAL_USER_CREDENTIALS:action.payload
            }
        )
        case AUTHENTICATION_TYPE:return (
            state={
                ...state,
                AUTHENTICATION_TYPE:action.payload
            }
        )   
    
        default:
            return state
            
    }
}

// { username: email.trim(), new_password: password, temporary_password: tempPassword },
