import { handleApiCalls } from "./actionHelper";
import {
  GET_ALL_PERMISSIONS_REQUEST,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAIL,
} from "../constants/permissionsConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { enqueueSnackbar } from "notistack";

export const GetAllPermissionsData = () => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: GET_ALL_PERMISSIONS_REQUEST });

    responseData = await handleApiCalls("get", `admin/permission-list`, 'viewPermissions');

    if (responseData[2] === "") {
      dispatch({
        type: GET_ALL_PERMISSIONS_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: GET_ALL_PERMISSIONS_FAIL,
        payload: responseData[2],
      });
      dispatch(
        showGlobalSnackbar(responseData[2], "error")
      );
    }

    
  } catch (error) {
    dispatch({
      type: GET_ALL_PERMISSIONS_FAIL,
      payload: error,
    });
    dispatch(showGlobalSnackbar(error, "error"));
  }
};
