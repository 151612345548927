import * as React from 'react';
import { useDispatch } from 'react-redux';
import DashboardCardContainer from './dashboardCardContainer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { UpdatePreviewDashboardLayoutData } from '../../../../../actions/dashboardPreviewActions'
import { useRef } from 'react';
import CategoryCardContainer from './Category/CategoryCardContainer';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DragAndDropLayout ({dashboard_id, userDefineLayout}) {
  
  const dispatch = useDispatch()
  const dashboardCardContainerRef = useRef()

  const onLayoutChange = (layout, layouts) => {
    dispatch(UpdatePreviewDashboardLayoutData(dashboard_id, layouts));
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={userDefineLayout}
      onLayoutChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      // onBreakpointChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      isDraggable
      isRearrangeable
      draggableHandle=".grid-item__title"
      draggableCancel='.grid-item-non-draggable__title'
      // breakpoints={{ lg: 1280, md: 900, sm: 750, xs: 480, xxs: 0}}
      breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      margin={[0, -3]} // Add vertical and horizontal margin between grid items
      // containerPadding={[30, 0]} // Add padding to the container if needed
    >
      {userDefineLayout['lg'].map((item) => {
        if (item['i'].startsWith('category-')) {
          const categoryId = item['i'].split('category-')[1];
          return <CategoryCardContainer key={item['i']} categoryId={categoryId} ref={dashboardCardContainerRef} />
        } else {
          return <DashboardCardContainer key={Number(item['i'])} item={Number(item['i'])} ref={dashboardCardContainerRef} />
        }
      })}
    </ResponsiveGridLayout>
  );
}
