import {
  GET_ALL_FAQ_REQUEST,
  GET_ALL_FAQ_SUCCESS,
  GET_ALL_FAQ_FAIL,
  FILTER_FAQ_DATA,
} from "../constants/faqConstants";

let faqDataInitialstate = {
  loading: true,
  faqdata: [],
  filterFaqdata:[],
  error: "",
};
export const allFAQDataReducer = (state = faqDataInitialstate, action) => {
  switch (action.type) {
    case GET_ALL_FAQ_REQUEST:
      return {
        loading: true,
        faqdata: [],
        filterFaqdata: [],
      };

    case GET_ALL_FAQ_SUCCESS:
      return {
        loading: false,
        faqdata: action.payload,
        filterFaqdata: action.payload,
      };

    case GET_ALL_FAQ_FAIL:
      return { loading: false, faqdata: [], filterFaqdata: [],error: action.payload };

    case FILTER_FAQ_DATA:
      // const filterFaqdata = state.faqdata.filter((faqs) =>
      //   faqs.faq_tags.split(',').includes(action.payload.toLowerCase())
      // );
      const filterFaqdata = state.faqdata.filter((faqs) =>
        faqs.faq_tags
          .toLowerCase()
          .split(",")
          .find((a) => a.includes(action.payload.toLowerCase())) ||
        faqs.faq_answer
          .toLowerCase()
          .split(",")
          .find((a) => a.includes(action.payload.toLowerCase())) ||
        faqs.faq_question
          .toLowerCase()
          .split(",")
          .find((a) => a.includes(action.payload.toLowerCase())) 
      );
      return {
        ...state,
        filterFaqdata:
          action.payload.length > 0 ? filterFaqdata : [...state.faqdata],
      };

    default:
      return state;
  }
};