export const GET_ALL_ROLE_REQUEST = "GET_ALL_ROLE_REQUEST";
export const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS";
export const GET_ALL_ROLE_FAIL = "GET_ALL_ROLE_FAIL";

export const GET_ONE_ROLE_PERMISSION_REQUEST = "GET_ONE_ROLE_PERMISSION_REQUEST";
export const GET_ONE_ROLE_PERMISSION_SUCCESS = "GET_ONE_ROLE_PERMISSION_SUCCESS";
export const GET_ONE_ROLE_PERMISSION_FAIL = "GET_ONE_ROLE_PERMISSION_FAIL";

export const UPDATE_ONE_ROLE_DATA_SUCCESS = "UPDATE_ONE_ROLE_DATA_SUCCESS";

export const MODIFY_ONE_ROLE_PERMISSION_REQUEST = "MODIFY_ONE_ROLE_PERMISSION_REQUEST";
export const MODIFY_ONE_ROLE_PERMISSION_SUCCESS = "MODIFY_ONE_ROLE_PERMISSION_SUCCESS";
export const MODIFY_ONE_ROLE_PERMISSION_FAIL = "MODIFY_ONE_ROLE_PERMISSION_FAIL";

export const CREATE_NEW_ROLE_REQUEST = "CREATE_NEW_ROLE_REQUEST";
export const CREATE_NEW_ROLE_SUCCESS = "CREATE_NEW_ROLE_SUCCESS";
export const CREATE_NEW_ROLE_FAIL = "CREATE_NEW_ROLE_FAIL";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";