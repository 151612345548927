import { jwtDecode } from "jwt-decode";

export const checkTokenValid = () => {
  let tokenStatus = false;
  let token = sessionStorage.getItem("authTokens");
  if(token){
    let decodedToken = jwtDecode(token);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      tokenStatus = false;
      sessionStorage.removeItem("authTokens");
    } else {
      tokenStatus = true;
    }
  }

  return tokenStatus;
};
