import { forgotPasswordVerifyUser } from "../constants/forgotPasswordConstants";



const initialState={
    userInfo:null
}

export function forgotPasswordReducer (state=initialState,action){
    switch (action.type) {
        case forgotPasswordVerifyUser:
            return {
                ...state,
                userInfo:action.payload
            }
    
        default:
            return state;
    }
}
