import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


function StackedBarGraph({ data, width, height }) {
  return (
    <BarChart
      width={width}
      height={height}
      data={data.data.data}
      margin={{
        top: 5,
        right: 25,
        bottom: 5,
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="pv"
        stackId="a"
        fill="#9d95ff"
        barSize={20}
        animationBegin={0}
        animationDuration={400}
        animationEasing="linear"
      />
      <Bar
        dataKey="uv"
        stackId="a"
        fill="#d1a6fd"
        radius={[10, 10, 0, 0]}
        animationBegin={0}
        animationDuration={400}
        animationEasing="linear"
      />
    </BarChart>
  );
}
export default StackedBarGraph;
