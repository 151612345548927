import * as React from 'react';
import {useState,useEffect} from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Skeleton, Typography} from '@mui/material';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CardExpanded from './cardExpanded';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllPermissionsData } from '../../../actions/permissionsActions';
import { GetAllRolesData, CreateNewRole, DeleteRole, GetPermisssionListSingleRole } from '../../../actions/roleActions';
import AccessControl from "../../../AccessControl";

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// AccessList -> ["createRole","modifyRole","deleteRole","modifyPermission","deletePermission"]

export default function RoleManageAdmin() {

    const dispatch = useDispatch()

    const permissionListdetails = useSelector(state => state.permissionListdetails)
    const {error: permissionListError, loading: permissionListLoading, permissionList } = permissionListdetails

    const rolesdetails = useSelector(state => state.rolesdetails)
    const {error: rolesListError, loading: rolesListLoading, rolesList } = rolesdetails

    useEffect(()=>{
        // dispatch(GetPermisssionListSingleRole(1));
        dispatch(GetAllPermissionsData());
        dispatch(GetAllRolesData());
    }, [])


    const animatedComponents = makeAnimated();

    const [cardExpanded, setcardExpanded] = React.useState('');

    const [isNewRoleAddShown, setIsNewRoleAddShown] = useState(false);
    const [addRoleButtonText, setaddRoleButtonText] = useState('Add Role');

    const [roleName, setroleName] = useState("");
    const [roleDescription, setroleDescription] = useState("");
    const [addRolePermission, setaddRolePermission] = useState(null);

    const handleCardExpansionChange = (panel, roleId) => (event, newExpanded) => {
        // console.log(newExpanded)
        // console.log(panel)
        // console.log(roleId)
        if(newExpanded){
            dispatch(GetPermisssionListSingleRole(roleId))
        }
        
        setcardExpanded(newExpanded ? panel : false);
    };


    const NewRoleAddVisible = event => {
        // 👇️ toggle shown state
        setIsNewRoleAddShown(current => !current);
        if(addRoleButtonText==='Cancel'){
            setaddRoleButtonText("Add Role");
        }
        else{
            setaddRoleButtonText("Cancel");
        }
        
    };


    const rolesAdd = (roleData) => {
 
      if(addRolePermission!==null && addRolePermission.length>=1 && roleName!=='' && roleDescription!==''){
        var selected_permissions = [];
        addRolePermission.forEach(function (item, index) {
            selected_permissions.push(item.permission_id.toString());
          });
        dispatch(CreateNewRole(
            {
                "role_name": roleName,
                "role_description": roleDescription,
                "permissions": selected_permissions 
            }
        ))
        setroleName("");
        setroleDescription("");
        setaddRolePermission(null);
        NewRoleAddVisible()
      }
      else{
        alert('Incomplete Data...')
      }
    };
    


    const rolesDelete = (roleData) => {
      dispatch(DeleteRole(
        {
            "role_id": roleData.role_id.toString(),
        }
      ))
    };


    return(

        <div
            style={{
            //    display: "flex",
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            //    width: "100%",
            //    direction: "rtl",
            //    background: "#ef394e",
            //    boxSizing: "border-box" // <--- this line
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>Role Management</h1>
                <AccessControl allowedPermissions={["createRole"]} >
                    <Button  
                        variant="contained"
                        // size="small"
                        onClick={NewRoleAddVisible}
                    >
                        {addRoleButtonText}
                    </Button>
                </AccessControl>
            </Box>

            {isNewRoleAddShown && (
                <Box
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width :'100%',padding: "0em 0em 1em 0em",}}
                >
                    <Box width='15%' pr={1}>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Role Name"
                        size="small"
                        value={roleName}
                        onChange={e => setroleName(e.target.value)} 
                    />
                    </Box>
                    <Box width='25%' pr={1}>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Role Description"
                        size="small"
                        // multiline
                        // maxRows={2}
                        value={roleDescription}
                        onChange={e => setroleDescription(e.target.value)}
                    />
                    </Box>
                    <Box width='35%' pr={1}>
                    {/* <Select
                        placeholder='Add New Permissions'
                        isMulti
                        isSearchable
                        closeMenuOnSelect={false}
                        isClearable={true}
                        options={permissionList}
                        components={animatedComponents}
                        value={addRolePermission}
                        onChange={setaddRolePermission}
                        // onChange={this.onInputChange}
                        classNamePrefix="select"
                        maxMenuHeight={80}
                        noOptionsMessage={()=>"No More Permissions Avaliable..."}
                        styles={{
                            placeholder:(baseStyles, states) => ({
                                ...baseStyles,
                                color:"black"
                            }),
                            dropdownIndicator:(baseStyles) => ({
                                ...baseStyles,
                                color:"black"
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: 'grey',
                                height: '35px',
                                width:'300px'
                            }),
                            valueContainer: (base) => ({
                                ...base,
                                maxHeight: 35,
                                overflowY: "auto"
                            }),
                        }}
                    /> */}
                    <Autocomplete
                        fullWidth
                        multiple
                        id="checkboxes-tags-demo"
                        options={permissionList}
                        disableCloseOnSelect
                        limitTags={2}
                        getOptionLabel={(option) => option.permission_name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.permission_name}
                            </li>
                        )}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField {...params} label="Add New Permissions" placeholder="Permissions"  size='small'/>
                        )}
                        onChange={(event,value) => setaddRolePermission(value)}
                    />
                    </Box>
                    <Button  
                        variant="contained"
                        // size="small"
                        onClick={rolesAdd}
                    >
                        Save Roles
                    </Button>
                </Box>
            )}


            <Box>
                { rolesListLoading ? <Skeleton variant="rounded" animation="wave"  height={'80vh'} />
                  : rolesListError ? 
                        <Box
                            style={{
                            height:'20vh',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Typography>Unable to Collect All Roles Informations</Typography>
                        </Box>
                  : (
                        <Box>
                            {rolesList.map((role) => (
                                <CardExpanded key={role.role_id} roleData={role} permissionList={permissionList} expanded={cardExpanded} handleChange={handleCardExpansionChange} roleDeleteFunc={rolesDelete}/>
                                )
                            )}
                            
                        </Box>
                    )
                }
                
                
            </Box>
        
        </div>
    )

}

// import * as React from 'react';
// import {useState,useEffect} from 'react';
// import {Box,Button,TextField,Tooltip,IconButton,useTheme, Skeleton, Typography} from '@mui/material';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
// import CardExpanded from './cardExpanded';
// import { useDispatch, useSelector } from 'react-redux';
// import { GetAllPermissionsData } from '../../../actions/permissionsActions';
// import { GetAllRolesData, CreateNewRole, DeleteRole, GetPermisssionListSingleRole } from '../../../actions/roleActions';
// import AccessControl from "../../../AccessControl";
// import { tokens } from "../../../theme";

// // AccessList -> ["createRole","modifyRole","deleteRole","modifyPermission","deletePermission"]

// export default function RoleManageAdmin() {
//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);

//     const dispatch = useDispatch()

//     const permissionListdetails = useSelector(state => state.permissionListdetails)
//     const {error: permissionListError, loading: permissionListLoading, permissionList } = permissionListdetails

//     const rolesdetails = useSelector(state => state.rolesdetails)
//     const {error: rolesListError, loading: rolesListLoading, rolesList } = rolesdetails

//     useEffect(()=>{
//         // dispatch(GetPermisssionListSingleRole(1));
//         dispatch(GetAllPermissionsData());
//         dispatch(GetAllRolesData());
//     }, [])


//     const animatedComponents = makeAnimated();

//     const [cardExpanded, setcardExpanded] = React.useState('panel1');

//     const [isNewRoleAddShown, setIsNewRoleAddShown] = useState(false);
//     const [addRoleButtonText, setaddRoleButtonText] = useState('Add Role');

//     const [roleName, setroleName] = useState("");
//     const [roleDescription, setroleDescription] = useState("");
//     const [addRolePermission, setaddRolePermission] = useState(null);

//     const handleCardExpansionChange = (panel, roleId) => (event, newExpanded) => {
//         if(newExpanded){
//             dispatch(GetPermisssionListSingleRole(roleId))
//         }
        
//         setcardExpanded(newExpanded ? panel : false);
//     };


//     const NewRoleAddVisible = event => {
//         // 👇️ toggle shown state
//         setIsNewRoleAddShown(current => !current);
//         if(addRoleButtonText==='Cancel'){
//             setaddRoleButtonText("Add Role");
//         }
//         else{
//             setaddRoleButtonText("Cancel");
//         }
        
//     };


//     const rolesAdd = (roleData) => {
 
//       if(addRolePermission!==null && addRolePermission.length>=1 && roleName!=='' && roleDescription!==''){
//         var selected_permissions = [];
//         addRolePermission.forEach(function (item, index) {
//             selected_permissions.push(item.permission_id.toString());
//           });
//         dispatch(CreateNewRole(
//             {
//                 "role_name": roleName,
//                 "role_description": roleDescription,
//                 "permissions": selected_permissions 
//             }
//         ))
//         setroleName("");
//         setroleDescription("");
//         setaddRolePermission(null);
//         NewRoleAddVisible()
//       }
//       else{
//         alert('Incomplete Data...')
//       }
//     };
    


//     const rolesDelete = (roleData) => {
//       dispatch(DeleteRole(
//         {
//             "role_id": roleData.role_id.toString(),
//         }
//       ))
//     };


//     return(

//         <div
//             style={{
//             //    display: "flex",
//             padding: "0em 2em 2em 2em",
//             overflowX: "auto",
//             //    width: "100%",
//             //    direction: "rtl",
//             //    background: "#ef394e",
//             //    boxSizing: "border-box" // <--- this line
//             }}
//         >
//             <Box
//                 style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
//             >
//                 <h1>Role Management</h1>
//                 <AccessControl allowedPermissions={["createRole","viewPermissions"]} >
//                     <Button  
//                         variant="contained"
//                         // size="small"
//                         onClick={NewRoleAddVisible}
//                     >
//                         {addRoleButtonText}
//                     </Button>
//                 </AccessControl>
//             </Box>

//             {isNewRoleAddShown && (
//                 <Box
//                     style={{display: 'flex', flexDirection: 'row', alignItems: 'center',padding: "0em 0em 1em 0em",}}
//                 >
//                     <TextField
//                         required
//                         id="outlined-required"
//                         label="Role Name"
//                         size="small"
//                         value={roleName}
//                         onChange={e => setroleName(e.target.value)} 
//                     />&nbsp;&nbsp;&nbsp;
//                     <TextField
//                         required
//                         id="outlined-required"
//                         label="Role Description"
//                         size="small"
//                         // multiline
//                         // maxRows={2}
//                         value={roleDescription}
//                         onChange={e => setroleDescription(e.target.value)}
//                     />&nbsp;&nbsp;&nbsp;
//                     {/* replace select with autocomplete */}
//                     <Select
//                         placeholder='Add New Permissions'
//                         isMulti
//                         isSearchable
//                         closeMenuOnSelect={false}
//                         isClearable={true}
//                         options={permissionList}
//                         components={animatedComponents}
//                         value={addRolePermission}
//                         onChange={setaddRolePermission}
//                         // onChange={this.onInputChange}
//                         classNamePrefix="select"
//                         maxMenuHeight={80}
//                         noOptionsMessage={()=>"No More Permissions Avaliable..."}
//                         styles={{
//                             placeholder:(baseStyles, states) => ({
//                                 ...baseStyles,
//                                 color:"black"
//                             }),
//                             dropdownIndicator:(baseStyles) => ({
//                                 ...baseStyles,
//                                 color:"black"
//                             }),
//                             control: (baseStyles, state) => ({
//                                 ...baseStyles,
//                                 borderColor: 'grey',
//                                 height: '35px',
//                                 width:'300px'
//                             }),
//                             valueContainer: (base) => ({
//                                 ...base,
//                                 maxHeight: 35,
//                                 overflowY: "auto"
//                             }),
//                             option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
//                                 ...baseStyles,
//                                 backgroundColor: isFocused ? "grey" : colors.ReactSelectDropDownOptionsBackground,
//                             })
//                         }}
//                     />&nbsp;&nbsp;&nbsp;
//                     <Button  
//                         variant="contained"
//                         // size="small"
//                         onClick={rolesAdd}
//                     >
//                         Save Roles
//                     </Button>
//                 </Box>
//             )}


//             <Box>
//                 { rolesListLoading ? <Skeleton variant="rounded" animation="wave"  height={'80vh'} />
//                   : rolesListError ? 
//                         <Box
//                             style={{
//                             height:'20vh',
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "center",
//                         }}>
//                             <Typography>Unable to Collect All Roles Informations</Typography>
//                         </Box>
//                   : (
//                         <Box>
//                             {rolesList.map((role) => (
//                                 <CardExpanded key={role.role_id} roleData={role} permissionList={permissionList} expanded={cardExpanded} handleChange={handleCardExpansionChange} roleDeleteFunc={rolesDelete}/>
//                                 )
//                             )}
                            
//                         </Box>
//                     )
//                 }
                
                
//             </Box>
        
//         </div>
//     )

// }
