import * as React from 'react';
import { useDispatch } from 'react-redux';
import {Box,Button,TextField, Tooltip,Typography, Autocomplete, Skeleton, IconButton, Chip, Stack} from '@mui/material';
import { useTheme } from '@mui/system';
import Modal from '@mui/material/Modal';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: '13px'
  },
});

export default function EditCategoryModal({open, setOpen, categoryId, setCategoryId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius : "20px",
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [showDeleteModal, setshowDeleteModal] = React.useState(false);
  const [deleteItem, setdeleteItem] = React.useState(null);
  const [errorActive, seterrorActive] = React.useState(false);
  const [kpiList, setKpiList] = React.useState(undefined);
  const [categoryKpiList, setCategoryKpiList] = React.useState(undefined);
  const [kpiAdd, setkpiAdd] = React.useState({});

  const getAllCategoryKpi = React.useCallback(() => {
    if (categoryId && categoryId[0]) {
      handleApiCallsVisualizationEngine(
        "get",
        `categories/${categoryId[0]}/kpis`,
        ""
      ).then((responseData) => {
        if (Array.isArray(responseData) && responseData.length > 1) {
          setCategoryKpiList(responseData[1]);
        } else {
          console.error('Unexpected response format:', responseData);
          dispatch(showGlobalSnackbar('Unexpected response format', "error"));
        }
      }).catch((error) => {
        console.error('Error fetching category KPIs:', error);
        dispatch(showGlobalSnackbar(error.response?.data?.error || 'Error fetching category KPIs', "error"));
      });
    }
  }, [categoryId, dispatch]);

  const removeKpi = (id) => {
    if (id !== ''){
      handleApiCallsVisualizationEngine(
        "delete",
        `categories/${categoryId[0]}/kpis/${id}`,
        ""
        ).then(() => {
          setdeleteItem(null)
          dispatch(showGlobalSnackbar("Kpi removed successfully.", "success"));
          getAllCategoryKpi()
        }).catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        }
      );
      }
    }
  
  const assignNewKpi = () => {
    if (Object.keys(kpiAdd).length === 0){
      seterrorActive(true)
    } else {
      seterrorActive(false)
      handleApiCallsVisualizationEngine(
          "post",
          `categories/${categoryId[0]}/kpis/${kpiAdd.value}`,
          ""
          ).then(() => {
            setkpiAdd({})
            dispatch(showGlobalSnackbar("Kpi assigned successfully.", "success"));
            getAllCategoryKpi()
          }).catch((error) => {
              console.log(error);
              dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
          }
        );
      }
    }

  React.useEffect(() => {
    if(categoryId !== null){
      getAllCategoryKpi();
    }
  }, [categoryId, getAllCategoryKpi]);


  React.useEffect(() => {
    if(categoryKpiList !== undefined){
    handleApiCallsVisualizationEngine(
      "get",
      `kpis`,
      ""
      ).then((responseData) => {
        let value_list = []
        categoryKpiList.forEach((item) =>{
          value_list.push(item.KpiBasicInformation.value)
        })
        let not_assigned = [...responseData[1]].filter((element) => !value_list.includes(element.value)); 
        setKpiList([...not_assigned]);
      }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    }
  }, [categoryKpiList, dispatch]);


  return (
    open === true && categoryId !== null?
    <Box>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '60%', height: '60%', minWidth:"200px", minHeight:'200px', display:'flex', flexDirection:'column', alignItems:'start' }}>
          <Box sx={{display:'flex', flexDirection:'row', alignItems:'start', justifyContent:"space-between", width:'100%' }}>
            <Box width={'70%'}>
              <CustomWidthTooltip title={`${categoryId[1]}`} arrow> 
                <Typography noWrap sx={{fontSize:"23px"}}  fontWeight={500} pb={3}>{`Add/Remove Kpi From Category - ${categoryId[1]}`}</Typography>
              </CustomWidthTooltip>
            </Box>
            <Box>
              <IconButton
                sx={{background:'#FF7A7A6b', '&:hover': {background:'#FF5959'}}}
                size='small'
                onClick={() => {
                  setCategoryId(null);
                  setKpiList([])
                  setCategoryKpiList(undefined)
                  setkpiAdd({})
                  setOpen(false)
                }}
              >
                <ClearOutlinedIcon/>
              </IconButton>
            </Box>  
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            {kpiList !== undefined ? 
              <Box sx={{width:'90%', height:'100%'}}>
                <Autocomplete
                  disableClearable 
                  fullWidth id="controllable-states-demo" 
                  variant="outlined" 
                  value={Object.keys(kpiAdd).length > 0 ? kpiAdd : null}
                  options={kpiList}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.kpiName}
                  onChange={(event, newValue) => {setkpiAdd(newValue)} }
                  renderOption={(props, option) => <li {...props}>{option.kpiName}</li>}
                  renderInput={(params) => <TextField {...params} label="Assign Kpi" error={errorActive ? Object.keys(kpiAdd).length === 0: false}/>}
                /> 
              </Box>
              : <Skeleton variant="rectangular" height={'52px'} width={'90%'}></Skeleton>
            }
            <Box>
              <Button
                variant="contained"
                onClick={assignNewKpi}
              >
                Add Kpi
              </Button>
            </Box>
          </Box>
          {showDeleteModal === true && deleteItem !== null ?
          <DeleteButtonWithConfirmationPopUp mainData={categoryKpiList} deleteitem={deleteItem} deleteFunc={removeKpi} needButton={false}> </DeleteButtonWithConfirmationPopUp>
          : <></>}
          <Box height={'250px'} width='100%' mt={2} borderRadius={'10px'} bgcolor='#6f655521' p={2} sx={{overflow: 'auto'}}>
            {categoryKpiList === undefined ?
              <Skeleton variant="rectangular" height={'100%'}></Skeleton> 
              :
              categoryKpiList.length > 0 ?
              <Stack direction="row" spacing={1} useFlexGap  flexWrap="wrap">
                {categoryKpiList.map((item, index) => (
                  <Chip 
                    key={index} 
                    label={`${item.KpiBasicInformation.kpiName}`} 
                    sx={{background: `${theme.palette.mode === 'dark'? '#000': '#fff'}`, boxShadow:2,
                    color: `${theme.palette.mode === 'dark'? '#fff': '#000'}`,
                    fontSize:'15px', fontWeight:'500', minWidth:'70px',
                    display:'flex', alignItems:'center', justifyContent:'space-between'
                    }}
                    
                    onDelete={() => {
                      setshowDeleteModal(true)
                      setdeleteItem(item.KpiBasicInformation.value)
                    }} 
                  />
                ))}
              </Stack> 
              : 
              <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'100%'}>
                <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Kpi assigned to this category.</Typography>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
    </Box>
    :<></>
  );
}
