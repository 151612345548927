import React from 'react';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Box,Tooltip} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {ButtonBase} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import  axios  from 'axios';

import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from '../../../actions/globalSnackbarActions';
import { jwtDecode } from "jwt-decode"
import { checkTokenValid } from '../../../Auth/authHelper';
import { getImagesHelper } from '../../../components/Documentation/getImagesHelper';
import { deleteDocumentationData } from '../../../actions/adminDocumentationAction';
import DocumentaionDeletePopUp from '../../../components/Documentation/DocumentationDeletePopUp';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
// import {DeleteSweepIcon,EditNoteIcon} from "@mui/icons-material"




function ManualArticleCards({CardData}) {
  let dispatch=useDispatch()
  let navigate=useNavigate()
  const isComponentMounted = useRef();
  const {defaultImage}=useSelector((state)=>state.adminDocumentationReducer)

  // console.log("CardData from documentation/documentation-get",CardData)
  
  let [imgURL,setImgURL]=useState("")

  useEffect(function () {
    isComponentMounted.current = true;
    return function () {
       isComponentMounted.current = false;
    };
  }, []);
  
  useEffect(
    ()=>{
      if(isComponentMounted.current===true){
        getImagesHelper(dispatch,setImgURL,CardData)
      }
      // memeory leak error only occurs if we add images greater than around 182kb
      // getImagesHelper(dispatch,setImgURL,CardData) firing after the manualArticleCards has been unmounted
      // tries to setImg url in parallel while ManualArticleCards is being mounted

      // solution maybe
      // if(CardData){
      //   getImagesHelper(dispatch,setImgURL,CardData)
      // }

      // return () => {
      //   // console.log('clean up')
      //   isComponentMounted.current=false
      // }
      
    },
  [isComponentMounted])
  
  
  
  return (
      <Card
          sx={{
            m:0.5,
            borderRadius:3,
            background:'#00000000',
            boxShadow: 1,
            // height:"30vh",
            // width:"20vw"
          }}
      >
        <CardActionArea component={Link} to={`/documentation/${CardData.documentation_id}`}> 
        
          <CardMedia
              // sx={{borderRadius:3}}
              style={{
                borderTopLeftRadius:3, 
                borderTopRightRadius:3,
                
              }}
              component="img"
              height="155vh"
              // width={"2vw"}
              image={
                imgURL==''?
                defaultImage:
                imgURL
                // CardData.documentation_image
              }
              alt="green iguana"
          />
          <CardContent sx={{p:0.95, paddingLeft:1}}>
            {/* DocumentationID :{CardData.documentation_id} */}
            <Typography gutterBottom 
            variant='subtitle1'
            component="div" 
            fontWeight={600} 
            sx={{display:"grid",gridTemplateColumns:"63.5% 7% 7%", columnGap:"2.5vw"}}>
               
            {CardData.documentation_title}

            {/* <Box
              component={ButtonBase}
              sx={{
                borderRadius: "10%",
                height: "25px",
                paddingLeft:"2px"
              }}
              onClick={(event)=>{
                event.preventDefault()
                navigate(`/manage-documentation/documentation-authoring?ArticleId=${CardData.documentation_id}`)
              }}
              // for disabling parents ripple effect
              onMouseDown={(event)=>{
                event.stopPropagation()
              }}
            >
              <Tooltip
                title="Edit Document"
                // placement="left"
              >
                <EditNoteIcon
                  sx={{
                    height: "22px",
                    width: "22px",
                    marginLeft: "2px"
                  }}
                />
              </Tooltip>
            </Box> */}

            {/* <DocumentaionDeletePopUp CardData={CardData} /> */}
            {/* <Box
            // sx={{}}
              // for disabling parents ripple effect
              onMouseDown={(event)=>{
                event.stopPropagation()
                
              }}

              onClick={(event)=>{
                event.preventDefault()
                // dispatch(deleteDocumentationData(CardData))
              }}
            >
              <DocumentaionDeletePopUp CardData={CardData} />
            </Box> */}
            
            {/* <Box
              component={ButtonBase}
              sx={{
                borderRadius: "30%",
                marginTop: "-3px"
              }}
              onClick={(event)=>{
                event.preventDefault()
                // dispatch(deleteDocumentationData(CardData))
              }}
              // for disabling parents ripple effect
              onMouseDown={(event)=>{
                event.stopPropagation()
                
              }}
            >
              <Tooltip
                title="Delete"
                placement="right"
              >
                <DeleteIcon
                  sx={{
                    // height: "25px",
                    width: "18px",
                    marginLeft: "2px"
                  }}
                />
              </Tooltip>
            </Box> */}

            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
  )
}



export default ManualArticleCards
// import * as React from 'react';
// import { Link } from 'react-router-dom';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea } from '@mui/material';



// function ManualArticleCards({CardData}) {
//   return (
//       <Card
//           sx={{m:0.5,borderRadius:3,background:'#00000000',boxShadow: 1}}
//       >
//         <CardActionArea component={Link} to={`${CardData.articleId}`}>
//           <CardMedia
//               // sx={{borderRadius:3}}
//               style={{borderTopLeftRadius:3, borderTopRightRadius:3}}
//               component="img"
//               height="100"
//               image={CardData.articleImage}
//               alt="green iguana"
//           />
//           <CardContent sx={{p:0.5, paddingLeft:1}}>
//             <Typography gutterBottom variant='subtitle1'component="div" fontWeight={600}>
//               Title : {CardData.articleName}
//             </Typography>
//           </CardContent>
//         </CardActionArea>
//       </Card>
//   )
// }

// export default ManualArticleCards