import {
    DASHBOARD_PREVIEW_LAYOUT_REQUEST,
    DASHBOARD_PREVIEW_LAYOUT_SUCCESS,
    DASHBOARD_PREVIEW_LAYOUT_FAIL,
    DASHBOARD_PREVIEW_LAYOUT_KEY_DELETE,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL,

    DASHBOARD_PREVIEW_KPI_DATA_REQUEST,
    DASHBOARD_PREVIEW_KPI_DATA_SUCCESS,
    DASHBOARD_PREVIEW_KPI_DATA_FAIL,
    DASHBOARD_PREVIEW_KPI_DATA_DELETE,
    DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE,
    DASHBOARD_PREVIEW_KPI_DATA_UPDATE,
    DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST,
    DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS,
    DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL,
    DASHBOARD_PREVIEW_CATEGORY_DATA_DELETE
  } from "../constants/adminDashboardPreviewConstants";
  
  
  let dashboardPreviewLayoutinitialState = {
    loading: true,
    dashboardPreviewLayout: {},
    dashboardPreviewKpiList: [],
    dashboardPreviewCategoryList: [],
    error: "",
  };
  
  export const dashboardPreviewLayOutReducer = (
    state = dashboardPreviewLayoutinitialState,
    action
  ) => {
    let updated_layout_info = {};
    switch (action.type) {
      case DASHBOARD_PREVIEW_LAYOUT_REQUEST:
        return { loading: true, dashboardPreviewLayout: {}, dashboardPreviewKpiList: [] };
  
      case DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST:
        return { loading: false, dashboardPreviewLayout: { ...state.dashboardPreviewLayout }, dashboardPreviewKpiList: [...state.dashboardPreviewKpiList] };
  
      case DASHBOARD_PREVIEW_LAYOUT_SUCCESS:
        return { loading: false, dashboardPreviewLayout: action.payload, dashboardPreviewKpiList: action.kpisList, dashboardPreviewCategoryList: action.categoryList };
  
      case DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS:
        return { loading: false, dashboardPreviewLayout: action.payload, dashboardPreviewKpiList: [...state.dashboardPreviewKpiList] };
  
      case DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL:
        return { loading: false, error: action.payload };
  
      case DASHBOARD_PREVIEW_LAYOUT_FAIL:
        return { loading: false, error: action.payload };
  
      case DASHBOARD_PREVIEW_LAYOUT_KEY_DELETE:
        updated_layout_info = {};
        Object.entries(state.dashboardPreviewLayout).forEach(([key, value]) => {
          updated_layout_info[key] = [];
          Object.entries(value).forEach(([key1, value1]) => {
            if (value1["i"] !== `${action.payload}`) {
              updated_layout_info[key].push(value1);
            }
          });
        });
  
        return {
          ...state,
          loading: false,
          dashboardPreviewLayout: { ...updated_layout_info },
        };
  
      default:
        return state;
    }
  };
    
  let dashboardPreviewKpisDatainitialState = {
    loading: true,
    dashboardPreviewKpisData: {},
    error: {},
  };
  export const dashboardPreviewKpisDataReducer = (
    state = dashboardPreviewKpisDatainitialState,
    action
  ) => {
    switch (action.type) {
      case DASHBOARD_PREVIEW_KPI_DATA_REQUEST:
        return {
          loading: false,
          dashboardPreviewKpisData: { ...state.dashboardPreviewKpisData },
          error: { ...state.error },
        };

      case DASHBOARD_PREVIEW_KPI_DATA_SUCCESS:
        var item = action.payload;
        var ItemKey = item.kpiId;
        var newData = state.dashboardPreviewKpisData;
        newData[ItemKey] = item;
        var error_status = state.error
        error_status[ItemKey] = '';
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: newData,
          error: error_status
        };

      case DASHBOARD_PREVIEW_KPI_DATA_FAIL:
        var item = action.kpiData;
        var ItemKey = action.kpiId;
        var newData = state.dashboardPreviewKpisData;
        newData[ItemKey] = item;
        var error_status = state.error
        error_status[ItemKey] = action.payload;
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: newData,
          error: error_status
        };
        // return { loading: false, error: action.payload };

      case DASHBOARD_PREVIEW_KPI_DATA_DELETE:
        const nextData = { ...state.dashboardPreviewKpisData };
        delete nextData[action.payload];
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: { ...nextData },
        };

      case DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE:
        var type = action.payload[0];
        var ItemKey = action.payload[1].toString();
        var newData = state.dashboardPreviewKpisData[ItemKey];
        newData["type"] = type;
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: { ...state.dashboardPreviewKpisData, ...newData },
        };

      case DASHBOARD_PREVIEW_KPI_DATA_UPDATE:
        var item = action.payload;
        var ItemKey = item.kpiId;
        var newData = state.dashboardPreviewKpisData;
        newData[ItemKey] = item;
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: newData,
        };

      default:
        return state;
    }
  };

  let dashboardPreviewCategoryDatainitialState = {
    loading: true,
    dashboardPreviewCategoryData: {},
    error: {},
  };
  export const dashboardPreviewCategoryDataReducer = (
    state = dashboardPreviewCategoryDatainitialState,
    action
  ) => {
    switch (action.type) {
      case DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST:
        return {
          ...state,
          loading: false
        };
      case DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS:
        var item = action.payload;
        var ItemKey = item.id;
        return {
          ...state,
          loading: false,
          dashboardPreviewCategoryData: {
            ...state.dashboardPreviewCategoryData,
            [ItemKey]: item
          }
        };
      case DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case DASHBOARD_PREVIEW_CATEGORY_DATA_DELETE:
        return {
          ...state,
          loading: false,
          dashboardPreviewCategoryData: Object.fromEntries(
            Object.entries(state.dashboardPreviewCategoryData).filter(
              ([key]) => key !== action.payload.toString()
            )
          ),
        };
      default:
        return state;
    }
  };
  
  /*
  let dashboardKpisAllListinitialState = {
    loading: true,
    dashboardAllKpisNameList: {},
    error: "",
  };
  export const dashboardKpisListReducer = (
    state = dashboardKpisAllListinitialState,
    action
  ) => {
    switch (action.type) {
      case DASHBOARD_ALL_KPIS_LIST_REQUEST:
        return { loading: true, dashboardAllKpisNameList: {} };
  
      case DASHBOARD_ALL_KPIS_LIST_SUCCESS:
        return {
          loading: false,
          dashboardAllKpisNameList: action.payload,
        };
  
      case DASHBOARD_ALL_KPIS_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      case DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_REQUEST:
        return {
          loading: false,
          dashboardAllKpisNameList: { ...state.dashboardAllKpisNameList },
        };
  
      case DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS:
        var newData = {};
        var newValue = state.dashboardAllKpisNameList[action.payload];
        newValue[1] = false;
        newData[action.payload] = newValue;
        return {
          ...state,
          loading: false,
          dashboardAllKpisNameList: {
            ...state.dashboardAllKpisNameList,
            ...newData,
          },
        };
  
      case DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS:
        var newData = {};
        var newValue = state.dashboardAllKpisNameList[action.payload];
        newValue[1] = true;
        newData[action.payload] = newValue;
        return {
          ...state,
          loading: false,
          dashboardAllKpisNameList: {
            ...state.dashboardAllKpisNameList,
            ...newData,
          },
        };
  
      case DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  
  let dashboardPreviewKpisDatainitialState = {
    loading: true,
    dashboardPreviewKpisData: {},
    error: "",
  };
  export const dashboardPreviewKpisDataReducer = (
    state = dashboardPreviewKpisDatainitialState,
    action
  ) => {
    switch (action.type) {
      case DASHBOARD_KPI_DATA_REQUEST:
        return {
          loading: false,
          dashboardPreviewKpisData: { ...state.dashboardPreviewKpisData },
        };
  
      case DASHBOARD_KPI_DATA_SUCCESS:
        var item = action.payload;
        var ItemKey = item.kpiId;
        var newData = state.dashboardPreviewKpisData;
        newData[ItemKey] = item;
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: newData,
        };
  
      case DASHBOARD_KPI_DATA_FAIL:
        return { loading: false, error: action.payload };
  
      case DASHBOARD_KPI_DATA_DELETE:
        const nextData = { ...state.dashboardPreviewKpisData };
        delete nextData[action.payload];
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: { ...nextData },
        };
  
      case DASHBOARD_KPI_DATA_GRAPH_TYPE_CHANGE:
        var type = action.payload[0];
        var ItemKey = action.payload[1].toString();
        var newData = state.dashboardPreviewKpisData[ItemKey];
        newData["type"] = type;
        return {
          loading: false,
          dashboardPreviewKpisData: { ...state.dashboardPreviewKpisData, ...newData },
        };
  
      case DASHBOARD_KPI_DATA_UPDATE:
        var item = action.payload;
        var ItemKey = item.kpiId;
        var newData = state.dashboardPreviewKpisData;
        newData[ItemKey] = item;
        return {
          ...state,
          loading: false,
          dashboardPreviewKpisData: newData,
        };
  
      default:
        return state;
    }
  };
  */
  