import {
    SEARCHING_TEXT,
    SET_ADMIN_DATA,
    SLIDE_UP_EDIT_INSTANCE,
    SLIDE_STATUS,
    UPDATE_ADMIN_DATA,
    DELETE_ADMIN_DATA,
    GO_SEARCH,
    GET_ROLES
} from "../constants/adminManagementConstants";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";

export function Searching(searchingText) {
    return (dispatch, getState) => {
        dispatch({
            type: SEARCHING_TEXT,
            payload: searchingText
        })
    }
}

export function setAdminData(details) {
    return async (dispatch, getState) => {
        const { allAdminData } = getState().adminManagementReducer
        try {
            console.log("addAdminData", details)
            const setAdminResp = await handleApiCalls(
                `post`,
                `admin/create-admin`,
                "createAdmin",
                details
            )
            // .catch((err)=>{
            //     throw err
            // })
            console.log("setAdminResp", setAdminResp)
            await dispatch({
                type: SET_ADMIN_DATA,
                payload: !allAdminData ? [{ ...details, role_description: setAdminResp[1]["role_description"] }] : [...allAdminData, { ...details, role_description: setAdminResp[1]["role_description"] }],
            })
            console.log("setAdminData")

            await dispatch(
                showGlobalSnackbar(
                    `Added admin successfully`,
                    `success`
                )
            )

            dispatch(goSearch())
        } catch (error) {
            if (error.message === "Network Error") {
                dispatch(
                    showGlobalSnackbar(
                        error.message,
                        `error`
                    )
                )
            }
            else {
                dispatch(
                    showGlobalSnackbar(
                        error.response.data["error-message"],
                        `error`
                    )
                )
            }
            
        }
    }
}

export function updateAdminData(details) {
    return async (dispatch, getState) => {
        const adminManagementReducer = getState().adminManagementReducer
        const { allAdminData } = adminManagementReducer
        console.log("details of updateAdminData", details)
        try {
            let updatedDetaisObj={}

            for(let prop in details){
                switch (prop) {
                    case "modified_on" :
                        break;
                    case "created_on" :
                        break;
                    case "role_name" :
                        break;
                    case "is_email_verified" :
                        break;
                    case "is_phone_verified" :
                        break;
                    case "last_login" :
                        break;
                    case "is_active" :
                        break;
                    case "mfa_enabled" :
                        break;
                    case "username" :
                        break;
                    default:
                        updatedDetaisObj[prop]=details[prop]
                        break;
                }
            }
            const updateResp = await handleApiCalls(
                `post`,
                `admin/modify-admin-info`,
                `modifyAdmin`,
                updatedDetaisObj
            ).catch((err) => {
                throw err
            })

            console.log(`updateResp`, updateResp)
            
            console.log("UPDATE_ADMIN_DATA successfully")
            await dispatch({
                type: SET_ADMIN_DATA,
                payload:null
            })

            await dispatch(
                showGlobalSnackbar(
                    `Updated admin successfully`,
                    `success`
                )
            )
            
            await dispatch(getAdminData())
            // await dispatch(goSearch())
        }
        catch (error) {
            console.error(`Failure UPDATE_ADMIN_DATA ${error}`)
            // dispatch(
            //     showGlobalSnackbar(
            //         `Failed to update admin`,
            //         `error`
            //     )
            // )
            if (error.message === 'Network Error') {
                await dispatch(showGlobalSnackbar(error.message, "error"))
            }
            else {
                await dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"))
            }
        }

    }
}

export function slideUpEditInstanceAction(adminRowDetails) {
    return (dispatch, getState) => {
        console.log("clicked slideUpEditInstanceAction", adminRowDetails)
        if (adminRowDetails) {
            dispatch({
                type: SLIDE_UP_EDIT_INSTANCE,
                payload: {
                    checked: true,
                    adminRowDetails: adminRowDetails
                }
            })
        }
        else {
            dispatch({
                type: SLIDE_UP_EDIT_INSTANCE,
                payload: null
            })
        }
    }
}

export function changeSlideStatus(bool) {
    return (dispatch, getState) => {
        console.log(SLIDE_STATUS, "action", "value", bool)
        dispatch({
            type: SLIDE_STATUS,
            payload: bool
        })
    }
}

export function deleteAdminData(details,userInfo) {
    return async (dispatch, getState) => {
        const { allAdminData } = getState().adminManagementReducer

        try {
            // let filteredAdminData = allAdminData.filter((adminDetails) => {
            //     if (adminDetails.email != details.email) {
            //         return adminDetails
            //     }
            // })

            // dispatch({
            //     type: DELETE_ADMIN_DATA,
            //     payload: filteredAdminData
            // })
            console.log("details",details)
            console.log("userInfo",userInfo)
            const delAdminResp=await handleApiCalls(
                'post',
                'admin/delete-admin',
                "deleteAdmin",
                {user_id:details.user_id}
            )
            console.log("delAdminResp",delAdminResp)
            await dispatch(
                showGlobalSnackbar(
                    delAdminResp[1],
                    `success`
                )
            )

            await dispatch(getAdminData(userInfo))

            await dispatch(goSearch())
        } catch (error) {
            // console.log("delete_admin_error",error)
            //    await dispatch(
            //         showGlobalSnackbar(
            //             `Failed to delete admin`,
            //             `error`
            //         )
            //     )
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))
            } else {
                await dispatch(showGlobalSnackbar(error.response.data["error-message"],"error"))
            }
        }
    }
}


export function goSearch() {
    return (dispatch, getState) => {
        const { searchTerm, allAdminData } = getState().adminManagementReducer


        console.log("searchTerm", searchTerm)

        if (allAdminData) {
            let searchResult = allAdminData.filter((adminDetails) => {
                // console.log("adminDetails.email",adminDetails.username)
                if (adminDetails.username.includes(searchTerm)) {

                    return adminDetails
                }
            })

            console.log("searchResultArr", searchResult)

            dispatch({
                type: GO_SEARCH,
                payload: searchResult
            })
        } else {
            dispatch(showGlobalSnackbar(
                `Add an admin before searching `,
                `error`
            ))
        }
    }
}

export function getRoles() {
    return async (dispatch, getState) => {
        try {
            const getRolesResp = await handleApiCalls(
                `get`,
                `admin/role-list`,
                `viewRoles`
            )
            console.log("getRoles", getRolesResp)
            
            if(getRolesResp[2]!==""){
                throw new Error(getRolesResp[2])
            }

            await dispatch({
                type: GET_ROLES,
                payload: getRolesResp[1]
            })
        } catch (error) {
            // console.log(error)
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))
            } else {
                await dispatch(showGlobalSnackbar(error.response.data["error-message"],"error"))
            }
        }
    }
}

export function getAdminData(userInfo) {
    return async (dispatch, getState) => {
        // console.log("userInfo",userInfo)
        try {
            const getAdminDataResp = await handleApiCalls(
                "get",
                `admin/fetch-user?admin_only=true`,
                "fetchAdmin"
            )
            console.log("getAdminDataResp", getAdminDataResp)

            dispatch({
                type: SET_ADMIN_DATA,
                payload: getAdminDataResp[1]
            })

            if(getAdminDataResp[2]!==""){
                dispatch(showGlobalSnackbar(getAdminDataResp[2],"error"))
            }
        } catch (error) {
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))
            } else {
                await dispatch(showGlobalSnackbar(error.response.data["error-message"],"error"))
            }
            // dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"))
        }
    }
}