import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography,Container,IconButton,Paper,CssBaseline,FormControl,Button,Grid,TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import PasswordChecklist from "react-password-checklist"
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
// import { UserNewPasswordSet } from "../../actions/userActions";
import validator from 'validator';
import { verifyPasswordResetUser } from '../../actions/forgotPasswordActions';

export default function ForgetPasswordPage() {

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const {error: userInfoError, loading: userInfoLoading, userInfo} = userLogin;

    const navigate=useNavigate()
    const [resetPasswordButton, setresetPasswordButton] = React.useState(true);

    // const [showPassword, setShowPassword] = React.useState(false);
    // const [showConfirmPassword, setshowConfirmPassword] = React.useState(false);
    
    const [email, setEmail] = useState('');
    const [emailEnableFlag,setemailEnableFlag] = useState(false)
    // const [password, setPassword] = useState("")
	  // const [confirmPassword, setconfirmPassword] = useState("")


    // const handleResetPassword = (event) => {
    //     event.preventDefault();
    //     if(password !=='' && confirmPassword !==''){
    //         // dispatch(UserNewPasswordSet({ email_id: email.trim(), password: password }));
    //     }

    // };


  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 16,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(../../../assets/logIn.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            pt={1}
            pb={4}
            pl={4}
            pr={4}
            minHeight={400}
          >
            <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                <Box component="img"
                    sx={{
                    height: 30,
                    // display: { xs: 'flex', md: 'none' },
                    mt:2
                    }}
                    alt="BridgeWater Logo"
                    src="../../assets/town_of_bridge.png"
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
                <Typography variant='h3'>
                    Energy Management Information System
                </Typography>
                <Typography variant='h3'>
                    Forgot Password
                </Typography>
                  <Box width="100%" pt={3}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e)=>{
                          setEmail(e.target.value)
                          if(validator.isEmail(e.target.value)){
                            setemailEnableFlag(true)                        
                          }
                          
                        }}
                        autoFocus
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled = {emailEnableFlag ? false : true}
                      onClick={()=>{
                        dispatch(verifyPasswordResetUser(email,navigate,setemailEnableFlag))
                      }}
                      >
                      Next
                    </Button>
                  </Box>
                
            </Box>
          </Grid>
          <Grid container>
              <Grid item xs >
          </Grid>
        </Grid>
        </Grid>
      </Box>
    </Container>
 
    // <Container component="main" maxWidth="lg">
    //   <Box
    //     sx={{
    //       marginTop: 4,
    //     }}
    //   >
    //     <Grid container>
    //       <CssBaseline />
    //       <Grid
    //         item
    //         xs={false}
    //         sm={4}
    //         md={7}
    //         sx={{
    //           backgroundImage: `url(../../../assets/logIn.svg)`,
    //           backgroundRepeat: "no-repeat",
    //           backgroundColor: (t) =>
    //             t.palette.mode === "light"
    //               ? t.palette.grey[50]
    //               : t.palette.grey[900],
    //           backgroundPosition: "center",
    //         }}
    //       />
    //       <Grid
    //         item
    //         xs={12}
    //         sm={8}
    //         md={5}
            
    //         component={Paper}
    //         elevation={6}
    //         square
    //         pt={1}
    //         pb={4}
    //         pl={4}
    //         pr={4}
    //         minHeight={400}
    //       >
    //         <Box alignItems='center' display='flex' justifyContent='center'>
    //             <Box component="img"
    //                 sx={{
    //                 height: 30,
    //                 display: { xs: 'flex', md: 'none' },
    //                 mt:2
    //                 }}
    //                 alt="BridgeWater Logo"
    //                 src="../../assets/town_of_bridge.png"
    //             />
    //         </Box>
    //         <Box 
    //           sx={{
    //             p:3,
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //           }}
    //         >
    //             <Typography variant='h3'>
    //                 Energy Management Information System
    //             </Typography>
    //             <Typography variant='h3'>
    //                 Reset Password
    //             </Typography>
    //             <Box
    //                 component="form"
    //                 autoComplete="on"
    //                 // onSubmit={handleLogin}
    //                 sx={{ mt: 3 }}
    //                 width={"100%"}
    //             >
    //                 <TextField
    //                     margin="normal"
    //                     required
    //                     fullWidth
    //                     id="email"
    //                     label="Email Address"
    //                     name="email"
    //                     autoComplete="email"
    //                     value={email}
    //                     onChange={(e)=>setEmail(e.target.value)}
    //                     autoFocus
    //                 />

    //                 {/* <FormControl variant="outlined" fullWidth required sx={{ mb: 1.5 }}>
    //                     <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
    //                     <OutlinedInput
    //                         id="password"
    //                         name="password"
    //                         label="Password"
    //                         type={showPassword ? 'text' : 'password'}
    //                         endAdornment={
    //                             <InputAdornment position="end">
    //                                 <IconButton
    //                                 aria-label="toggle password visibility"
    //                                 onClick={() => setShowPassword((show) => !show)}
    //                                 onMouseDown={(e)=> e.preventDefault()}
    //                                 edge="end"
    //                                 >
    //                                 {showPassword ? <VisibilityOff /> : <Visibility />}
    //                                 </IconButton>
    //                             </InputAdornment>
    //                         }
    //                         onChange={(e)=>setPassword(e.target.value)}
    //                         onCut={(e)=> e.preventDefault()}
    //                         onCopy={(e)=> e.preventDefault()}
    //                         onPaste={(e)=> e.preventDefault()}
    //                     />
    //                 </FormControl>

    //                 <FormControl variant="outlined" fullWidth required sx={{ mb: 1.5 }}>
    //                     <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
    //                     <OutlinedInput
    //                         id="confirmPassword"
    //                         name="confirmPassword"
    //                         label="Confirm Password"
    //                         type={showConfirmPassword ? 'text' : 'password'}
    //                         endAdornment={
    //                             <InputAdornment position="end">
    //                                 <IconButton
    //                                 aria-label="toggle password visibility"
    //                                 onClick={() => setshowConfirmPassword((show) => !show)}
    //                                 onMouseDown={(e)=> e.preventDefault()}
    //                                 edge="end"
    //                                 >
    //                                 {showPassword ? <VisibilityOff /> : <Visibility />}
    //                                 </IconButton>
    //                             </InputAdornment>
    //                         }
    //                         onChange={(e)=>setconfirmPassword(e.target.value)}
    //                         onCut={(e)=> e.preventDefault()}
    //                         onCopy={(e)=> e.preventDefault()}
    //                         onPaste={(e)=> e.preventDefault()}
    //                     />
    //                 </FormControl>

    //                 <PasswordChecklist 
    //                     rules={["minLength","specialChar","number","lowercase","capital","match"]}
    //                     minLength={8}
    //                     value={password}
    //                     valueAgain={confirmPassword}
    //                     onChange={(isValid) => {
    //                         if(isValid){
    //                           setresetPasswordButton(false)
    //                         }
    //                     }}
    //                     // style={{ backgroundColor : 'blue'}}
    //                     iconComponents= {{ ValidIcon: <DoneAllOutlinedIcon sx={{color:'green' , mr:0.5}}/>, InvalidIcon: <BlockOutlinedIcon sx={{color:'orange' , mr:0.5}} /> }}
    //                 />
    //                 <Button
    //                     fullWidth
    //                     variant="contained"
    //                     sx={{ mt: 3, mb: 2 }}
    //                     disabled = {resetPasswordButton}
    //                     onClick={handleResetPassword}
    //                     >
    //                     Change Password
    //                 </Button> */}
                    
    //             </Box>
    //             <Button pt={0}
    //               component={Link}
    //               // to={'/login'}
    //               onClick={()=>{

    //               }}
    //               >
    //               <Typography variant='subtitle2' textAlign='right'>
    //                   Log In
    //               </Typography>
    //             </Button>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </Container>
  );
}