import {
  GET_ALL_PERMISSIONS_REQUEST,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAIL,
} from "../constants/permissionsConstants";



let permissionsListInitialstate = {
  loading: false,
  permissionList: [],
  error: "",
};
export const permissionListReducer = (state = permissionsListInitialstate, action) => {
  switch (action.type) {
    case GET_ALL_PERMISSIONS_REQUEST:
      return { loading: true, permissionList: [] };

    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        permissionList: action.payload,
      };

    case GET_ALL_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
