import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAdminDashboardPreviewCategoryData } from '../../../../../../actions/dashboardPreviewActions';
import CategoryCard from './CategoryCard';

const CategoryCardContainer = React.forwardRef(function CategoryCardContainer({ categoryId, ...rest }, ref) {
  const dispatch = useDispatch();
  const { loading, dashboardPreviewCategoryData } = useSelector(state => state.admindashboardPreviewCategoryData);
  const { name, description, footer, kpiIds, layout, categoryStatement } = !loading ? dashboardPreviewCategoryData?.[categoryId] || {} : {};

  useEffect(() => {
    dispatch(GetAdminDashboardPreviewCategoryData(categoryId));
  }, [dispatch, categoryId]);

  if (loading || !dashboardPreviewCategoryData || !name || !description || !kpiIds || !layout || !categoryStatement) return <div>Loading...</div>;

  return (
    <CategoryCard
      ref={ref}
      name={name}
      description={description}
      footer={footer}
      kpiIds={kpiIds}
      layout={layout}
      categoryStatement={categoryStatement}
      {...rest}
    />
  );
});

export default CategoryCardContainer;
