import { 
    SET_ADMIN_FAQ_SEARCH,
    SET_ADMIN_FAQ_DATA,
    EDIT_ADMIN_FAQ_DATA,
    ADD_ADMIN_FAQ_DATA,
    SET_ADMIN_FAQ_SEARCH_RESULT,
    SET_ADMIN_FAQ_LOADING_RESULT
 } from "../constants/adminFAQConstants"

import { handleApiCalls } from "./actionHelper"
import { showGlobalSnackbar } from "./globalSnackbarActions"

export function setAdminFAQSearch(searchText){
    return (dispatch,getState)=>{
        console.log("latest getState",getState().adminFAQReducer)

        dispatch({
            type :SET_ADMIN_FAQ_SEARCH,
            payload:searchText
        })

    }
}

export function setAdminFAQData(){
    return async (dispatch,getState)=>{
            await handleApiCalls("get", "faq/fetch-all", "viewFaq")
              .then((handleApiCallsResponse) => {
                console.log("handleApiCallsResponse", handleApiCallsResponse);

                if(handleApiCallsResponse[2]!==""){
                    throw handleApiCallsResponse[2]
                }
                dispatch({
                  type: SET_ADMIN_FAQ_DATA,
                  payload: {
                    result: handleApiCallsResponse[1],
                    "error-message": handleApiCallsResponse[2],
                  },
                });
              })

              .catch((e) => {
                console.error("error in fetching FAQ's ");
                console.error(e);

                dispatch({
                  type: SET_ADMIN_FAQ_LOADING_RESULT,
                  payload: false,
                });

                if(e.message === "Network Error"){
                    dispatch(showGlobalSnackbar(e.message, "error"));
                }
                else{
                    dispatch(showGlobalSnackbar(e, "error"));  
                }
              });
        
        
    }
}

export function editAdminFAQ(payload){
    return async (dispatch,getState)=>{

        let adminFAQReducer=getState().adminFAQReducer
        let {adminFAQData,editAdminFAQData}=adminFAQReducer
        console.log("admin FAQ to be edited",payload)

        await handleApiCalls("post","faq/modify",`updateFaq`,{
            faq_id: payload.faq_id,
            question: payload.faq_question,
            answer: payload.faq_answer,
            tags: payload.faq_tags
        }).then((r)=>{
            console.log("edited Admin FAQ",r)
            let newAdminFAQData = adminFAQData ?
                {
                    ...adminFAQData,
                    result:adminFAQData["result"].map((x) => {
                        if (x.faq_id == payload.faq_id) {
                            return payload
                        }
                        else {
                            return x
                        }
                    })
                } :
                adminFAQData

                dispatch({
                    type:SET_ADMIN_FAQ_DATA,
                    payload:newAdminFAQData
                })

                if(r[1]==="Faq Modified Successfull!" && newAdminFAQData){
                    dispatch({
                        type:EDIT_ADMIN_FAQ_DATA,
                        payload:editAdminFAQData+1
                    })
                    dispatch(
                        showGlobalSnackbar(
                            "FAQ Updated Successfully!",
                            "success"
                        )
                    )
                }
                else{
                    dispatch(
                        showGlobalSnackbar(
                            "Failed to Update FAQ!",
                            "error"
                        )
                    )
                }
                
        })
        .catch((e)=>{
            console.log("error in editAdminFAQ dispatch")
            console.log(e)
            dispatch(
                showGlobalSnackbar(
                    "Failed to Update FAQ!",
                    "error"
                )
            )
        })

    }
}

export function addAdminFAQ(payload){
    return async (dispatch,getState)=>{

        let adminFAQReducer=getState().adminFAQReducer
        let {adminFAQData,addAdminFAQData}=adminFAQReducer
        console.log("admin FAQ to be added",payload)

        await handleApiCalls("post","faq/create",`createFaq`,{
            question: payload.faq_question,
            answer: payload.faq_answer,
            tags: payload.faq_tags
        }).then((r)=>{
            console.log("added Admin FAQ",r)

            dispatch(setAdminFAQData())

            // without fetch, needs "id" key
            // let newAdminFAQData = adminFAQData ?
            //     {
            //         ...adminFAQData,
            //         result:[...adminFAQData["result"],payload]
            //     } :
            //     adminFAQData

            //     dispatch({
            //         type:SET_ADMIN_FAQ_DATA,
            //         payload:newAdminFAQData
            //     })

            if(r[1]==="Faq Submited Successfull!"/*newAdminFAQData*/){
                dispatch({
                    type:ADD_ADMIN_FAQ_DATA,
                    payload:addAdminFAQData+1
                })

                dispatch(
                    showGlobalSnackbar(
                        "FAQ Added Successfully!",
                        "success"
                    )
                )


            }
            else{
                dispatch(
                    showGlobalSnackbar(
                        "Failed to Add FAQ!",
                        "error"
                    )
                )
            }
                
        })
        .catch((e)=>{
            console.log("error in addAdminFAQ dispatch")
            console.log(e)
            dispatch(
                showGlobalSnackbar(
                    "Failed to Add FAQ!",
                    "error"
                )
            )
        })

    
    }
}

export function setAdminFAQSearchResult(){
    return (dispatch,getState)=>{
        let adminFAQReducer=getState().adminFAQReducer
        let {adminFAQSearch,adminFAQData}=adminFAQReducer
        // console.log("search according to Tags")
        // console.log(adminFAQSearch)
        // console.log("adminFAQData",adminFAQData)

        let searchResult=!adminFAQData ? [] : adminFAQData.result.filter((x)=>{
            // let tagsArr=x.faq_tags.toLowerCase().split(",")
            // if(x.faq_tags.includes(adminFAQSearch)){
            //     return x
            // }

            return x.faq_tags.includes(adminFAQSearch.toLowerCase()) || x.faq_answer.includes(adminFAQSearch.toLowerCase()) || x.faq_question.includes(adminFAQSearch.toLowerCase())
        })
        
        // console.log("searchResult",searchResult)

        if(adminFAQSearch && adminFAQSearch.length && adminFAQData){
            // console.log("searchResult------->",searchResult)
            dispatch({
                type:SET_ADMIN_FAQ_SEARCH_RESULT,
                payload:{
                  ...adminFAQData,
                  result: searchResult
                }
            })
            
            dispatch({
                type:SET_ADMIN_FAQ_DATA,
                payload:{
                    ...adminFAQData,
                    result: searchResult
                }
            })
        }
        else{
            // console.log("searchResult------->",searchResult)
            dispatch({
                type:SET_ADMIN_FAQ_SEARCH_RESULT,
                payload:[]
            })
            dispatch(setAdminFAQData())
        }
        

    }
}



export function deleteAdminFAQ(payload){
    return async (dispatch,getState)=>{

        let adminFAQReducer=getState().adminFAQReducer
        let {adminFAQData,editAdminFAQData}=adminFAQReducer
        console.log("admin FAQ to be deleted",payload)

        await handleApiCalls("post","faq/delete",`deleteFaq`,{
            faq_id: payload.faq_id,
        }).then((r)=>{
            console.log("deleted Admin FAQ",r)
            let newAdminFAQData = adminFAQData ?
                {
                    ...adminFAQData,
                    result:adminFAQData["result"].filter((x) => {
                        return x.faq_id !== payload.faq_id
                        // if (x.faq_id !== payload.faq_id) {
                        //     return payload
                        // }
                        // else {
                        //     return x
                        // }
                    })
                } :
                adminFAQData

                dispatch({
                    type:SET_ADMIN_FAQ_DATA,
                    payload:newAdminFAQData
                })

                if(r[1]==="Faq Deleted Successfull!" && newAdminFAQData){
                    dispatch(
                        showGlobalSnackbar(
                            "FAQ Deleted Successfully!",
                            "success"
                        )
                    )
                }
                else{
                    dispatch(
                        showGlobalSnackbar(
                            "Failed to Delete FAQ!",
                            "error"
                        )
                    )
                }
                
        })
        .catch((e)=>{
            console.log("error in deleteAdminFAQ dispatch")
            console.log(e)
            dispatch(
                showGlobalSnackbar(
                    "Failed to Delete FAQ!",
                    "error"
                )
            )
        })

    }
}