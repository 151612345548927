import React, {useState, useEffect, useContext} from 'react'
import { Link } from "react-router-dom"
import {Box,TextField, Card,Button,IconButton, Typography} from '@mui/material';
import Lottie from "lottie-react";
import missingAnimation from "./404.json";

const MissingPage = () => {
    
    const style = {
        height: 400,
        backgroundColor:'#0f2554'
    };

    return (
        <Box p={2} >

            <Box
                display="flex"
                // justifyContent="center"
                flexDirection='column'
                alignItems="center"
                // sx={{
                //     backgroundImage: "url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);",
                //     backgroundRepeat: "no-repeat",
                //     height: '450px',
                // }}
            >
                <Lottie animationData={missingAnimation} style={style} loop={true}/>
                {/* <img src="../../../assets/404.png" width={100} height={100}/> */}
                {/* <Typography variant='h1' fontSize={150}>
                    404
                </Typography> */}
                
            </Box>
            <Box 
                pt={2}
                display="flex"
                alignItems="center"
            >
                <Typography variant='h3'>
                    Look like you're lost - The page you are looking for not avaible!
                </Typography>
                <Box pl={2}>
                    <Button component={Link} to="/" variant='contained'>
                        Go to Home
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}

export default MissingPage
