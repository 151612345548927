import * as React from 'react';
import { useDispatch } from 'react-redux';
import DashboardCardContainer from './dashboardCardContainer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { UpdateDashboardLayoutData } from '../../../actions/dashboardActions'
import { useRef } from 'react';



const ResponsiveGridLayout = WidthProvider(Responsive);


export default function DragAndDropLayout ({userDefineLayout}) {
  
  const dispatch = useDispatch()
  const dashboardCardContainerRef = useRef()


  const onLayoutChange = (layout, layouts) => {
    // console.log(layouts);
    // console.log(userDefineLayout);
    dispatch(UpdateDashboardLayoutData(layouts));
  };


  return (
    
    <ResponsiveGridLayout
      className="layout"
      layouts={userDefineLayout}
      onLayoutChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      // onBreakpointChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      isDraggable
      isRearrangeable
      draggableHandle=".grid-item__title"
      draggableCancel='.grid-item-non-draggable__title'
      // breakpoints={{ lg: 1280, md: 900, sm: 750, xs: 480, xxs: 0}}
      breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    >

      {userDefineLayout['lg'].map((item) => {
        // dispatch(GetDashboardEachKpiData(Number(item['i'])));
        return <DashboardCardContainer key={Number(item['i'])} item={Number(item['i'])} ref={dashboardCardContainerRef} />
      })}
    </ResponsiveGridLayout>
  
  );
}
