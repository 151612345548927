export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LAST_LOGIN_REQUEST = "USER_LAST_LOGIN_REQUEST";
export const USER_LAST_LOGIN_SUCCESS = "USER_LAST_LOGIN_SUCCESS";
export const USER_LAST_LOGIN_FAIL = "USER_LAST_LOGIN_FAIL";

export const IDENTIFY_USER_RESP = "IDENTIFY_USER_RESP"
export const SET_USER_PASSWORD_RESP = "SET_USER_PASSWORD_RESP"
export const LOCAL_USER_CREDENTIALS="LOCAL_USER_CREDENTIALS"

export const AUTHENTICATION_TYPE="AUTHENTICATION_TYPE"
