import * as React from 'react';
import {Box,Button,Stack,Divider,TextField,MenuItem,IconButton, Skeleton,  Typography,FormControl, Select } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Slide from '@mui/material/Slide';


import CustomSwitch from "../../../../../../components/Switch/customSwitch";
import {WhereConditionBuilder} from "./whereCondition";
import AddJoins from "./joinClause";
import AddGroupBy from "./groupByClause";
import AddHavingClause from "./havingClause";
import { handleApiCallsVisualizationEngine } from "../../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../../actions/globalSnackbarActions";

function isDeepEmpty(input) {
  // catch null or undefined object
  if (!input && !(typeof input === 'boolean')) return true
  if (typeof input === 'object') {
    for (const entry of Object.values(input)) {
      if (isDeepEmpty(entry)) {
        return true
      }
    }
    return false
  } else if (typeof input === 'string') {
  //   console.log(input)
    if (input === ""){
      return true
    }
  }
  // if input is not an object return false
  return false
}


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    borderRadius: 10,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#e0e0e0',
  },
  '& .MuiInputLabel-root': {
    borderRadius: 10,
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 10,
    // background: "#f2f2f2",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
    '&.Mui-focused fieldset': {
      borderRadius: 10,
      borderColor: '#e0e0e0',
    },
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function FilterDetailsForm({selecetedDatabase, filterDetailsData, setfilterDetailsData, DataTables, setDataTables, errorActive, seterrorActive}){
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `database-connection/${selecetedDatabase}/database-tables`,
      ""
    ).then((responseData) => {
      // console.log(responseData[1]['tables'])
      setDataTables(responseData[1]['tables']);
    }).catch((error) => {
      console.log(error);
      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    });
  }, []);
  
  const [whereConditionEnable, setwhereConditionEnable] = React.useState(filterDetailsData.whereClauseValue !== '(1 = 1)');

  const addJoin = () => {
    setfilterDetailsData({...filterDetailsData, joinClauseValue : [...filterDetailsData.joinClauseValue, {"rightTable": "", "leftTable": "", "joinType": "", "onCondition": {
      condition: "AND", 
      rule: [{leftTableColumn : "", rightTableColumn :"", operator: ""}]
  }}]})}


  const addGroupBy = () => {
    if(filterDetailsData.groupByClauseValue.length === 0){
      setfilterDetailsData({...filterDetailsData, groupByClauseValue : [{"columns": ""}]})
    }
  }

  const addHaving = () => {
    setfilterDetailsData({...filterDetailsData, havingClauseValue : [...filterDetailsData.havingClauseValue, {
      'agrFunction': '',
      'havingColumn': '',
      'havingOperator': '',
      'havingValue': ''
    }]})}

  const collectSelectedTableInfo = () => {
    let data = {
      primaryTableValue : filterDetailsData['primaryTableValue'],
      joinClauseValue : [...filterDetailsData['joinClauseValue']]
    } 
    if(isDeepEmpty(data)){
      seterrorActive(true)
    } else {
      let selectedCols = []
      if(filterDetailsData.tableSelectEnable){
        let check = []
        check.push(filterDetailsData['primaryTableValue'])
        for(let i = 0; i < Object.keys(DataTables[filterDetailsData['primaryTableValue']]).length; i++){
          selectedCols.push(`${filterDetailsData['primaryTableValue']}.${Object.keys(DataTables[filterDetailsData['primaryTableValue']])[i]}`)
        }

        for(let j = 0; j < filterDetailsData['joinClauseValue'].length; j++) {
          let rtable = filterDetailsData['joinClauseValue'][j].rightTable
          let ltable = filterDetailsData['joinClauseValue'][j].leftTable
          
          if(check.indexOf(rtable) === -1){
            check.push(rtable)
            for(let i = 0; i < Object.keys(DataTables[rtable]).length; i++) {
              selectedCols.push(`${rtable}.${Object.keys(DataTables[rtable])[i]}`)
            }
          }
          if(check.indexOf(ltable) === -1){
            check.push(ltable)
            for(let i = 0; i < Object.keys(DataTables[ltable]).length; i++) {
              selectedCols.push(`${ltable}.${Object.keys(DataTables[ltable])[i]}`)
            }
          }
        }
        
        // setfilterDetailsData({...filterDetailsData, selectedTableColumns: [1,2,3]});
      }
      setcardExpanded('');  //to close all Accordion
      setfilterDetailsData({...filterDetailsData, selectedTableColumns: selectedCols, tableSelectEnable: !filterDetailsData.tableSelectEnable, whereClauseValue : '(1 = 1)', groupByClauseValue : [], havingClauseValue : []});
    }
  }

  // const handleRemoveItem = (index, keyName) => {
  //   let newObj={}
  //   const allItems = [...filterDetailsData[keyName]];
  //   allItems.splice(index, 1);
  //   newObj[keyName]=[...allItems]
  //   setfilterDetailsData({...filterDetailsData, ...newObj});
  // };

  // React.useEffect(() => {
  //   setfilterDetailsData({...filterDetailsData, primaryTableValue: DataTables[0].value, selectedTable: [{"label": DataTables[0].value, "value":DataTables[0].value}]});
  // }, []);



  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [cardExpanded, setcardExpanded] = React.useState('');

  // setdatabaseValue(() => Databases[0].value);
  return (
    
    Object.keys(DataTables).length ? 
    <Box
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
        <Box>
          <CustomTextField
          // InputProps={{ sx: { borderRadius: 3,  border: `none !important` }, }}
          // className={}
          disabled={!filterDetailsData.tableSelectEnable}
          fullWidth
          id="primaryTable"
          select
          label="Select Primary Table"
          variant="outlined"
          value={filterDetailsData.primaryTableValue}
          error={errorActive ? filterDetailsData.primaryTableValue === '': false}
          onChange={e => {setfilterDetailsData({...filterDetailsData, primaryTableValue : e.target.value})}}
          >
            <MenuItem key={`please_select_primary_table`} value='' disabled>
                    Please Select A Primary Table
            </MenuItem>
            {Object.keys(DataTables).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        {/* <Box sx={{display: 'flex', flexDirection: 'row',alignItems: 'center',  width:'100%'}}> */}
          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}}>
            <Accordion key={`panel_add_join`} expanded={cardExpanded === `panel_add_join`} 
            onChange={handleCardExpansionChange(`panel_add_join`)} disabled={!filterDetailsData.tableSelectEnable}
            >
              <AccordionSummary aria-controls={`panel_add_join-content`} id={`panel_add_join-header`}>
                <Typography sx={{fontSize:"20px"}}>Add Joins</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography  sx={{fontSize:"15px"}}>Add Joins to fetch data from different tables by using right, left or inner join</Typography>
                  <Button 
                    variant="contained"
                    size="small"
                    onClick={addJoin}
                  >
                    Add Joins
                  </Button>
                </Box>
                <AddJoins  filterDetailsData={filterDetailsData} setfilterDetailsData={setfilterDetailsData} DataTables={DataTables} errorActive={errorActive}/>
                
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'row', alignItems: 'center', }}>
            <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked= {!filterDetailsData.tableSelectEnable}
              onChange={collectSelectedTableInfo}
            ></CustomSwitch>
            <Typography>Toggle it on to add Condition / Group By Clause </Typography>
          </Box>

          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}}>
            <Accordion key={`panel_add_condition`} expanded={cardExpanded === `panel_add_condition`} 
            onChange={handleCardExpansionChange(`panel_add_condition`)} disabled={filterDetailsData.tableSelectEnable}
            >
              <AccordionSummary aria-controls={`panel_add_condition-content`} id={`panel_add_condition-header`}>
                <Typography sx={{fontSize:"20px"}}>Add Condition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography  sx={{fontSize:"15px"}}>Add Condition to fetch data</Typography>
                  <Button 
                    variant="contained"
                    size="small"
                    onClick={() => setwhereConditionEnable(true)}
                  >
                    Add Condition
                  </Button>
                </Box>
                {whereConditionEnable ? <Box
                  pt={2}
                >
                  <WhereConditionBuilder  filterDetailsData={filterDetailsData} setfilterDetailsData={setfilterDetailsData} />
                </Box> : <></>}
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}}>
            <Accordion key={`panel_add_group_by`} expanded={cardExpanded === `panel_add_group_by`} 
            onChange={handleCardExpansionChange(`panel_add_group_by`)} disabled={filterDetailsData.tableSelectEnable}
            >
              <AccordionSummary aria-controls={`panel_add_group_by-content`} id={`panel_add_group_by-header`}>
                <Typography sx={{fontSize:"20px"}}>Add Group By</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography  sx={{fontSize:"15px"}}>Add Group By clause</Typography>
                  <Button 
                    variant="contained"
                    size="small"
                    onClick={addGroupBy}
                  >
                    Add Group By
                  </Button>
                </Box>
                <AddGroupBy  filterDetailsData={filterDetailsData} setfilterDetailsData={setfilterDetailsData} errorActive={errorActive}/>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}}>
            <Accordion key={`panel_add_having`} expanded={cardExpanded === `panel_add_having`} 
            onChange={handleCardExpansionChange(`panel_add_having`)} disabled={filterDetailsData.tableSelectEnable || !filterDetailsData.groupByClauseValue?.length || filterDetailsData.groupByClauseValue[0]['columns'] === ''}
            >
              <AccordionSummary aria-controls={`panel_add_having-content`} id={`panel_add_having-header`}>
                <Typography sx={{fontSize:"20px"}}>Add Having Clause</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography  sx={{fontSize:"15px"}}>Add Having Clause</Typography>
                  <Button 
                    variant="contained"
                    size="small"
                    onClick={addHaving}
                  >
                    Add Having Clause
                  </Button>
                </Box>
                <AddHavingClause   filterDetailsData={filterDetailsData} setfilterDetailsData={setfilterDetailsData} errorActive={errorActive}>
                </AddHavingClause>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}}>
            <Accordion key={`panel_add_distinct`} expanded={cardExpanded === `panel_add_distinct`} 
            onChange={() => (
              setfilterDetailsData({...filterDetailsData, distinctAdd : !filterDetailsData.distinctAdd})
            )} 
            >
               <AccordionSummary width={'100%'} aria-controls={`panel_add_distinct-content`} id={`panel_add_distinct-header`}>
                <Box  sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography sx={{fontSize:"20px"}}>Add Distinct</Typography>
                  <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked= {filterDetailsData.distinctAdd}> </CustomSwitch>
                </Box>
              </AccordionSummary>
            </Accordion>
          </Box>
        
      </Stack>
      
      
    </Box>
    : 
    <Box
      pt={2}
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
     
      <Skeleton variant="rounded" sx={{ width: '90%' }}  height={'300px'}/>
    </Box>
  
  );
}

export default FilterDetailsForm;
  