import React from "react";
import { Box, Typography, Card, useTheme } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { tokens } from "../../../../../../theme";
import KpiCardContainer from "./KpiCardContainer";

const ResponsiveGridLayout = WidthProvider(Responsive);

const CategoryCard = React.forwardRef(function CategoryCard({ name, description, footer, kpiIds, layout, categoryStatement, ...rest }, ref) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box {...rest} ref={ref}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 700 }}>
        {name}
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 700 }}>
        {description}
      </Typography>
      <Card sx={{ marginTop: '10px', padding: '10px', borderRadius: '16px', backgroundColor: colors.categoryCardBackground }}>
          <Box sx={{ marginBottom: '10px', marginLeft: '20px' }}>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
              Process Info placeholder
            </Typography>
          </Box>
          {categoryStatement && (
            <Box sx={{ marginBottom: '10px', marginLeft: '20px' }}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {categoryStatement}
              </Typography>
            </Box>
          )}

          <ResponsiveGridLayout
            className="layout"
            layouts={layout}
            breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            isDraggable={false}
            isResizable={false}
          >
            {kpiIds?.map(kpiId => (
              <KpiCardContainer key={Number(kpiId)} item={kpiId} {...rest} ref={ref} />
            ))}
          </ResponsiveGridLayout>
      </Card>
      <Typography variant="body2" align="left" sx={{ marginTop: '10px' }}>
        {footer}
      </Typography>
    </Box>
  );
});

export default CategoryCard;
