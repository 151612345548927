import { SET_ADMIN_FAQ_SEARCH,SET_ADMIN_FAQ_DATA,EDIT_ADMIN_FAQ_DATA,ADD_ADMIN_FAQ_DATA,DEL_ADMIN_FAQ_DATA,SET_ADMIN_FAQ_SEARCH_RESULT,SET_ADMIN_FAQ_LOADING_RESULT } from "../constants/adminFAQConstants"

let initialState={
    adminFAQSearch:null,
    adminFAQData:null,
    editAdminFAQData:0,
    addAdminFAQData:0,
    adminFAQSearchResult:null,
    adminFAQLoading:true,
    
}

export let adminFAQReducer=(state=initialState,action)=>{
    switch(action.type){
        case (SET_ADMIN_FAQ_SEARCH) : return (
            state = {
                ...state,
                adminFAQSearch:action.payload
            }
        )

        case (SET_ADMIN_FAQ_DATA) : return (
            state = {
                ...state,
                adminFAQData:action.payload
            }
        )

        case (EDIT_ADMIN_FAQ_DATA) : return (
            state = {
                ...state,
                editAdminFAQData:action.payload
            }
        )

        case (ADD_ADMIN_FAQ_DATA) : return (
            state= {
                ...state,
                addAdminFAQData:action.payload
            }
        )
        
        case (DEL_ADMIN_FAQ_DATA) : return (
            state={
                ...state,
                adminFAQData:action.payload
            }
        )

        case (SET_ADMIN_FAQ_SEARCH_RESULT) : return (
            state = {
                ...state,
                adminFAQSearchResult:action.payload
            }
        )

        case (SET_ADMIN_FAQ_LOADING_RESULT) : return (
            state = {
                ...state,
                adminFAQLoading:action.payload
            }
        )
        default : return state
    }
}