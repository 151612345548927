import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Slide from '@mui/material/Slide';

import AddDimension from "./AddDimension";
import { handleApiCallsVisualizationEngine } from "../../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../../actions/globalSnackbarActions";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


function DataMappingForm({selectedGraphType, kpiMappingFormData, setkpiMappingFormData, DataTables, selectedTableColumns, errorActive}){
  const dispatch = useDispatch();
  
  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [cardExpanded, setcardExpanded] = React.useState('');
  const [graphConfig, setgraphConfig] = React.useState({});
  
  
  
  // const graphConfig = {
  //   Measures : {
  //     maxMeasure : 3,
  //     visualizationTypeConfiguration : {'color': {inputType: "COLOR", defaultValue: '#FA9C71'}, 'label': {inputType: "TEXT", defaultValue: ''}}
  //   },
  //   Dimension : {
  //     maxMeasure : 1,
  //     visualizationTypeConfiguration : {'color': {inputType: "COLOR", defaultValue: '#34A671'}, 'label': {inputType: "TEXT", defaultValue: ''}}
  //   }
  // }
  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `visualization-type/${selectedGraphType}/configuration`,
      ""
    ).then((responseData) => {
      // console.log(responseData[1]['visualizationTypeConfiguration'])
      setgraphConfig(responseData[1]['visualizationTypeConfiguration']);
    }).catch((error) => {
      console.log(error);
      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    });
  }, []);

  React.useEffect(() => {
    if(Object.keys(graphConfig).length){
      let obj = {...kpiMappingFormData}
      // for(let i =0; i < Object.keys(graphConfig).length; i++) {
      //   obj[graphConfig[i]]
      // }
      if (!(Object.keys(obj).length > 0))
      {
        Object.keys(graphConfig).map((item, index) => {
          let temp_obj = {
            agrFunction : ' ',
            dataColumn : ''
          }
          Object.keys(graphConfig[item].visualizationTypeConfiguration).map((fields_item, index) => (
            temp_obj[fields_item] = graphConfig[item].visualizationTypeConfiguration[fields_item].defaultValue
          ))
          obj[item] = [temp_obj]
          //   graphConfig[item].visualizationTypeConfiguration
          // }
        })
        setkpiMappingFormData({...obj})
      }
    }

  }, [graphConfig]);

  const addItems = (item) => () => {
    let obj = {...kpiMappingFormData}
    if(graphConfig[item].maxMeasure > obj[item].length){
      let temp_obj = {
        agrFunction : ' ',
        dataColumn : ''
      }
      Object.keys(graphConfig[item].visualizationTypeConfiguration).map((fields_item, index) => (
        temp_obj[fields_item] = graphConfig[item].visualizationTypeConfiguration[fields_item].defaultValue
      ))
      obj[item].push(temp_obj)
    }
    setkpiMappingFormData({...kpiMappingFormData, ...obj})
  };

  return (
    Object.keys(kpiMappingFormData).length > 0 ?
    <Box
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      {Object.keys(graphConfig).length ? 
      <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
        {Object.keys(kpiMappingFormData).map((item, index) => (
          <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_mapping_box_${index}`}>
            <Accordion key={`panel_data_mapping_${index}`} expanded={cardExpanded === `panel_data_mapping_${index}`} 
            onChange={handleCardExpansionChange(`panel_data_mapping_${index}`)}
            >
              <AccordionSummary aria-controls={`panel_data_mapping_${index}-content`} id={`panel_data_mapping_${index}-header`}>
                <Typography sx={{fontSize:"20px"}}>{item}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                  <Typography  sx={{fontSize:"15px"}}>Add data for {item}</Typography>
                  { (graphConfig[item].maxMeasure > 1) ? <Button 
                    variant="contained"
                    disabled={graphConfig[item].maxMeasure <= kpiMappingFormData[item].length}
                    size="small"
                    onClick={addItems(item)}
                  >
                    Add {item} Data
                  </Button> : <></>}
                </Box>
                {/* <AddJoins  kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} DataTables={DataTables} errorActive={errorActive}/> */}
                <AddDimension DataTables={DataTables} itemName={item} graphConfig={graphConfig} kpiMappingFormData={kpiMappingFormData} setkpiMappingFormData={setkpiMappingFormData} selectedTableColumns={selectedTableColumns} errorActive={errorActive}/>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
        
        
      </Stack>
      :<Skeleton variant="rounded" height={'200px'}/>
      }
      
    </Box>
    : 
    <Box
      pt={2}
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      {/* <Skeleton variant="rounded"  height={'300px'}/> */}
      <Skeleton variant="rounded" sx={{ width: '90%' }} height={'200px'}/>
    </Box>
  );
}

export default DataMappingForm;
  