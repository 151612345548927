import {
  GLOBAL_SNACKBAR_SHOW,
  GLOBAL_SNACKBAR_HIDE,
} from "../constants/globalSnackbarConstants";

export const showGlobalSnackbar = (message, messageType) => {
  return (dispatch) => {
    dispatch({ type: GLOBAL_SNACKBAR_SHOW, message, messageType });
  };
};

export const clearGlobalSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: GLOBAL_SNACKBAR_HIDE });
  };
};
