
import React from "react";
import ReactApexChart from "react-apexcharts";
 
 const BoxPlot = ({data,height,width}) => {
    let   seriesdaata=buildseries()
    console.log("seriesData",seriesdaata);
  const series = [
    {
      data: seriesdaata
    },
  ];
 
 
  function findQ1Q3(nums) {
    const sortedNums = nums.slice().sort((a, b) => a - b);
    // console.log(sortedNums)
    const q2 = calculateMedian(sortedNums);

    const tempLowerHalf = sortedNums.filter(num => num < q2)
    const lowerHalf = tempLowerHalf.length > 0 ? tempLowerHalf : sortedNums ;
    const q1 = calculateMedian(lowerHalf);

    const tempUpperHalf = sortedNums.filter(num => num > q2)
    const upperHalf = tempUpperHalf.length > 0 ? tempUpperHalf : sortedNums;
    const q3 = calculateMedian(upperHalf);
    console.log("q's",q1,q2,q3,"sortedNum",sortedNums)
    return { q1, q3 };
}
 
function calculateMedian(sortedNums) {
    // const sortedNums = nums.slice().sort((a, b) => a - b);
    const middle = Math.floor(sortedNums.length / 2);
    
    if (sortedNums.length % 2 === 0) {
        return (sortedNums[middle - 1] + sortedNums[middle]) / 2;
    } else {
        return sortedNums[middle];
    }
}
 
 
 
  function buildseries(){
    let  seriesdaata=[]
    let maxnum=Number.MIN_SAFE_INTEGER
    let minnum=Number.MAX_SAFE_INTEGER
    data.data.infoData.yAxis.forEach((entry)=>{
        let ydata=[]
        const label = entry["label"]
        data.data.data.forEach((e)=>{

          ydata.push(e[label])
          minnum=Math.min(minnum,e[label])
          maxnum=Math.max(maxnum,e[label])
          
        })
        // console.log("ydata",ydata)
        const { q1, q3 } = findQ1Q3(ydata);
        console.log("q1",q1,"q3",q3)
        const mediannum=calculateMedian(ydata)
        
        

        seriesdaata.push({
            x:entry.label,
            y:[minnum,q1,mediannum,q3,maxnum]
        })
 
    })
    return seriesdaata
  }
 
  const options = {
    chart: {
        zoom:false,
        toolbar: { show:false },
      type: "boxPlot",
      events:{
        selection: undefined,
      }
    },
    legend: {
      show: true,
    },
    yaxis: {
    },
    xaxis:{
        title:{
            text:data.data.infoData.xAxis
        }
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: "#238823",
          lower: "#D2222D",
        },
      },
    },
  };
 
  return (
      <ReactApexChart
        options={options}
        series={series}
        type="boxPlot"
        height={height-20}
        width={width}
      />
  );
};
 
export default BoxPlot