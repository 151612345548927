import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
// import {theme-monokai} from 'ace-builds/src-noconflict';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/theme-vibrant_ink';
import { Parser } from 'node-sql-parser';
import { useTheme } from '@mui/system';
// import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
// import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
// import AddchartIcon from '@mui/icons-material/Addchart';
// import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import TuneIcon from '@mui/icons-material/Tune';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

function AdvanceQueryForm({advanceQueryFormData, setadvanceQueryFormData, errorActive}){
  
  // const handleExecuteQuery = () => {
  //   // Implement logic to execute the SQL query (e.g., send it to a server)
  //   console.log('Executing SQL query:', editorContent);
  // };
  const parser = new Parser();
  const theme = useTheme();

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between"}}
    >
      <Stack  sx={{ width: '90%' }} spacing={2} pt= {3}>
        <Typography sx={{fontSize:"20px"}}>Write your query bellow :</Typography>
        <Box 
        sx={{boxShadow: 3, border:`2px solid ${(errorActive ? advanceQueryFormData.queryData === '': false) ? '#d32f2f' : theme.palette.divider}`, borderRadius: "4px"}} //b6b6b6
        >
          <AceEditor
            mode="sql"
            theme= {theme.palette.mode === 'dark' ? "vibrant_ink" : "sqlserver"}  // "vibrant_ink"   if dark mode
            // theme= "sqlserver"   // "vibrant_ink"   if dark mode
            // theme= "vibrant_ink"  // if dark mode
            value={advanceQueryFormData.queryData}
            onChange={(newContent) => {
              // console.log('=> ', newContent)
              setadvanceQueryFormData({...advanceQueryFormData, queryData: newContent})}}
            name="sql-editor"
            editorProps={{ $blockScrolling: true }}
            height="250px"
            width="100%"
            fontSize={'16px'}
          />
        </Box>
      </Stack>
    </Box>
  );
}
export default AdvanceQueryForm;
  