import * as React from 'react';
import {Box,Button,Stack,Divider,TextField,MenuItem,IconButton,  Typography,FormControl, Select } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const CustomTextField = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        background: "rgb(232, 241, 250)"
    },
    '& label.Mui-focused': {
      borderRadius: 10,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e0e0e0',
    },
    '& .MuiInputLabel-root': {
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: 10,
      // background: "#f2f2f2",
      // fontWeight: 800
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
      '&.Mui-focused fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
    },
  });

  
function AddGroupBy({filterDetailsData, setfilterDetailsData, errorActive}){
    

    const handleRemoveGroupByItem = (index, keyName) => {
        let newObj={}
        const allItems = [...filterDetailsData[keyName]];
        allItems.splice(index, 1);
        newObj[keyName]=[...allItems]
        setfilterDetailsData({...filterDetailsData, ...newObj});
      };

    return (
        <Box p={1}>
            {filterDetailsData.groupByClauseValue.map((item, index) => (
                <Box key={`groupBy_${index}`} pb={2} 
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                >
                    {/* <Box width={"100%"}
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                    >
                        <Box width={"100%"} key={`groupBY_box_${index}`}>
                            <CustomTextField
                            fullWidth
                            id={`groupBy_cols_${index}`}
                            select
                            label="Select Column"
                            variant="outlined"
                            value={filterDetailsData.groupByClauseValue[index].column || ''}
                            error={errorActive ? filterDetailsData.groupByClauseValue[index].column === '': false}
                            onChange={e => {
                                let change_join_data = [...filterDetailsData.groupByClauseValue]
                                let {name, value} = e.target
                                change_join_data[index].column = value;
                                setfilterDetailsData({...filterDetailsData, groupByClauseValue : change_join_data});
                                }}
                            >
                                <MenuItem key={`please_select_groupBy_${index}`} value='' disabled>
                                    Please Select A Column
                                </MenuItem>
                                {DataCols.map((option) => (
                                <MenuItem key={`groupBy_${index}_${option.value}`} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </CustomTextField>
                        </Box>

                    </Box> */}
                    <Box width={"100%"}
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                    >
                        <CustomTextField
                        multiple
                        id="checkboxes-tags-demo"
                        options={filterDetailsData.selectedTableColumns}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        value={filterDetailsData.groupByClauseValue[index].columns ? filterDetailsData.groupByClauseValue[index].columns.split(',') : []}
                        // error={errorActive ? filterDetailsData.groupByClauseValue[index].columns === '': false}
                        onChange={(event, value) => {
                          // console.log(filterDetailsData)
                          let change_join_data = [...filterDetailsData.groupByClauseValue]
                          change_join_data[index].columns = value.join(',');
                          setfilterDetailsData({...filterDetailsData, groupByClauseValue : change_join_data});
                        } } 
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Columns" placeholder="Columns"
                          error={errorActive ? filterDetailsData.groupByClauseValue[index].columns === '': false}
                          />
                        )}
                      />

                    </Box>
                    <Box  height={'100%'} pt={1}
                    sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                    >
                        <IconButton 
                        // sx={{position: "relative", top: "-72px"}}
                            onClick={() => handleRemoveGroupByItem(index, 'groupByClauseValue')}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

  
export default AddGroupBy;