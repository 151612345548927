import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import AddchartIcon from '@mui/icons-material/Addchart';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneIcon from '@mui/icons-material/Tune';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import BasicDetailsForm from './basicDetailsForm'
import KpiDetailsForm from './kpiDetailsForm'
import DataMappingForm from './dataMappingForm'
import LimitDetailsForm from './limitDetailsForm'
import FilterDetailsForm from './filterDetailsForm'
import AdvanceQueryForm from './advanceQueryForm'
import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { Parser } from 'node-sql-parser';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";
import PlaceholderModal from './placeholderModal'

function isDeepEmpty(input) {
  // catch null or undefined object
  if (!input && !(typeof input === 'boolean')) return true
  if (typeof input === 'object') {
    for (const entry of Object.values(input)) {
      if (isDeepEmpty(entry)) {
        return true
      }
    }
    return false
  } else if (typeof input === 'string') {
  //   console.log(input)
    if (input === ""){
      return true
    }
  }
  // if input is not an object return false
  return false
}

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

// const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//   color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
//   display: 'flex',
//   height: 22,
//   alignItems: 'center',
//   ...(ownerState.active && {
//     color: '#784af4',
//   }),
//   '& .QontoStepIcon-completedIcon': {
//     color: '#784af4',
//     zIndex: 1,
//     fontSize: 18,
//   },
//   '& .QontoStepIcon-circle': {
//     width: 8,
//     height: 8,
//     borderRadius: '50%',
//     backgroundColor: 'currentColor',
//   },
// }));


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(135deg, rgba(255,126,210,1) 10%, rgba(176,115,254,0.989233193277311) 59%)',
        // 'linear-gradient(146deg, rgba(15,160,255,1) 73%, rgba(43,0,129,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(135deg, rgba(255,126,210,1) 10%, rgba(176,115,254,0.989233193277311) 59%)',
        // 'linear-gradient(146deg, rgba(15,160,255,1) 73%, rgba(43,0,129,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c3c0c0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#aba9a9',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(135deg, rgba(254,128,210,1) 10%, rgba(133,63,224,1) 59%);',
      // 'linear-gradient(146deg, rgba(15,160,255,1) 7%, rgba(43,0,129,1) 85%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(135deg, rgba(254,128,210,1) 10%, rgba(133,63,224,1) 59%);',
      // 'linear-gradient(146deg, rgba(15,160,255,1) 7%, rgba(43,0,129,1) 85%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <AddchartIcon />,
    3: <TuneIcon />,
    4: <QueryStatsIcon />,
    5: <AllInclusiveIcon />,
    6: <FilterAltIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};



export default function  KpiEdit () {
  const parser = new Parser();
  const dispatch = useDispatch();

  const search = useLocation().search
  const kpiQueryEditParams = new URLSearchParams(search)

  const navigate = useNavigate()

  const collectSelectedTableInfo = () => {
    let data = {
      primaryTableValue : kpiDetailsData['primaryTableValue'],
      joinClauseValue : [...kpiDetailsData['joinClauseValue']]
    } 
    if(isDeepEmpty(data)){
      seterrorActive(true)
    } else {
      let selectedCols = []
      if(kpiDetailsData.tableSelectEnable){
        let check = []
        check.push(kpiDetailsData['primaryTableValue'])
        for(let i = 0; i < Object.keys(DataTables[kpiDetailsData['primaryTableValue']]).length; i++){
          selectedCols.push(`${kpiDetailsData['primaryTableValue']}.${Object.keys(DataTables[kpiDetailsData['primaryTableValue']])[i]}`)
        }

        for(let j = 0; j < kpiDetailsData['joinClauseValue'].length; j++) {
          let rtable = kpiDetailsData['joinClauseValue'][j].rightTable
          let ltable = kpiDetailsData['joinClauseValue'][j].leftTable
          
          if(check.indexOf(rtable) === -1){
            check.push(rtable)
            for(let i = 0; i < Object.keys(DataTables[rtable]).length; i++) {
              selectedCols.push(`${rtable}.${Object.keys(DataTables[rtable])[i]}`)
            }
          }
          if(check.indexOf(ltable) === -1){
            check.push(ltable)
            for(let i = 0; i < Object.keys(DataTables[ltable]).length; i++) {
              selectedCols.push(`${ltable}.${Object.keys(DataTables[ltable])[i]}`)
            }
          }
        }
        
        // setkpiDetailsData({...kpiDetailsData, selectedTableColumns: [1,2,3]});
        setkpiDetailsData({...kpiDetailsData, selectedTableColumns: selectedCols});
      }
    }
  }

  const [basicFormData, setbasicFormData]  = React.useState({
    kpiNameValue : '',
    kpiDescriptionValue : '',
    dataSourceValue : '',
    advanceUsage: false,
    graphTypeValue : '',
    databaseValue : ''
  });
  const [kpiDetailsData, setkpiDetailsData]  = React.useState({
    primaryTableValue : '',
    selectedTableColumns : [],
    joinClauseValue : [],
    tableSelectEnable: true,
    whereClauseValue : '(1 = 1)',
    groupByClauseValue : [],
    havingClauseValue : [],
    distinctAdd: false
  });

  const [kpiMappingFormData, setkpiMappingFormData]  = React.useState({
  });
  
  const [kpiLimitationFormData, setkpiLimitationFormData]  = React.useState({
    // limitValue: {},
    // ofsetValue: {},
    orderByValue: [],
  });
  const [advanceQueryFormData, setadvanceQueryFormData]  = React.useState({
    queryData: ""
  });
  const [filterDetailsFormData, setfilterDetailsFormData]  = React.useState([]);

  const [activeStep, setActiveStep] = React.useState(0);
  
  const [errorActive, seterrorActive] = React.useState(false);

  const [DataSource, setDataSource] = React.useState([]);
  const [GraphType, setGraphType] = React.useState([]);
  const [Databases, setDatabases] = React.useState([]);
  const [DataTables, setDataTables] = React.useState(null);

  const steps = [
    {
      'label':'KPI Basic Details',
      'form': <BasicDetailsForm basicFormData={basicFormData} setbasicFormData={setbasicFormData} DataSource={DataSource} GraphType={GraphType} Databases = {Databases} setDatabases = {setDatabases} errorActive={errorActive} advanceQueryFormData={advanceQueryFormData} setadvanceQueryFormData={setadvanceQueryFormData}/>,
      'validator': basicFormData,
      'nextStep': basicFormData.advanceUsage ? 3 : 1
    },
    {
      'label':'Add Data Relations',
      'form': <KpiDetailsForm selecetedDatabase={basicFormData.databaseValue} kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData} DataTables={DataTables} setDataTables={setDataTables} errorActive={errorActive} seterrorActive={seterrorActive}/>,
      'validator': kpiDetailsData,
      'nextStep': 1,
      'backStep': 1
    },
    {
      'label':'Add Limitations',
      'form': <LimitDetailsForm kpiLimitationFormData={kpiLimitationFormData} setkpiLimitationFormData={setkpiLimitationFormData} selectedTableColumns={kpiDetailsData.selectedTableColumns} errorActive={errorActive}/>,
      'validator': kpiLimitationFormData,
      'nextStep': 2,
      'backStep': 1
    },
    {
      'label':'Advance Query',
      'form': <AdvanceQueryForm advanceQueryFormData={advanceQueryFormData} setadvanceQueryFormData={setadvanceQueryFormData} errorActive={errorActive}/>,
      'validator': advanceQueryFormData,
      'nextStep': 1,
      'backStep': basicFormData.advanceUsage ? 3 : 1,
    },
    {
      'label':'Add Data Mapping',
      'form': <DataMappingForm selectedGraphType={basicFormData.graphTypeValue} kpiMappingFormData={kpiMappingFormData} setkpiMappingFormData={setkpiMappingFormData} DataTables={DataTables} selectedTableColumns={kpiDetailsData.selectedTableColumns} errorActive={errorActive}/>,
      'validator': kpiMappingFormData,
      'nextStep': 1,
      'backStep': basicFormData.advanceUsage ? 1 : 2
    },
    {
      'label':'Add Filters',
      'form': <FilterDetailsForm filterDetailsFormData={filterDetailsFormData} setfilterDetailsFormData={setfilterDetailsFormData} selectedTableColumns={kpiDetailsData.selectedTableColumns} errorActive={errorActive}/>,
      'validator': filterDetailsFormData,
      // 'nextStep': basicFormData.advanceUsage ? 4 : 1,
      'backStep': 1
    }
  ];
  
  const stepNext = () => { 
    let flag = true
    // console.log(steps[activeStep].validator)
    // console.log(advanceQueryFormData)
    // Object.keys(steps[activeStep].validator).map((key, index) => {
    //   if(steps[activeStep].validator[key] === ''){
    //     seterrorActive(true)
    //     flag = false;
    //     return false
    //   }
    // })
    // console.log(isDeepEmpty(steps[activeStep].validator))
    if(isDeepEmpty(steps[activeStep].validator)){
      seterrorActive(true)
      flag = false;
      // return false
    }
    if(flag){
      let flagg = true
      if(activeStep === 1){
        collectSelectedTableInfo();
      } else if (activeStep === 3) {
        if(advanceQueryFormData.queryData.includes('*')){
          seterrorActive(true)
          flagg = false
        } else {
          // OG let colms_str = /^SELECT\s+([^*]*?)\s+FROM/i.exec(advanceQueryFormData.queryData)
          let colms_str = /SELECT\s+([^*]*?)\s+FROM\s+[A-z]+\.[A-z]+/i.exec(advanceQueryFormData.queryData)
          console.log("colms_str",colms_str)
          if (colms_str === null) {
            seterrorActive(true)
            flagg = false
          } else {
            let colms_list = colms_str[1].replace(/distinct/mig, '').split(/,(?![^()]*\))/)
            let selectedCols = []
            // console.log("colms_list",colms_list)
            colms_list.map((item, index) => {
              let colms_item = item.trim()
              let colms = colms_item.split(/\s+AS\s+/i)
              // console.log("colms",colms)
              selectedCols.push(
                  colms.length > 1 ? 
                  colms.slice(-1)[0].replace(`"`,"") : 
                  colms[0].replace(`"`,"")
                )
            })
            // console.log("selectedCols",selectedCols)
            setkpiDetailsData({...kpiDetailsData, selectedTableColumns: selectedCols});
          }
          console.log("colms_str",colms_str)
        }
      }
      if(flagg === true && activeStep !== steps.length -1){
        setActiveStep((prevActiveStep) => prevActiveStep + steps[activeStep].nextStep);
        seterrorActive(false)
      }
    }
  }

  const stepBack = () => { 
    if(activeStep !== 0){
      setActiveStep((prevActiveStep) => prevActiveStep - steps[activeStep].backStep);
    }
  }
  
  const submitForm = () => { 
    let flag = true
    
    if(isDeepEmpty(steps[activeStep].validator)){
      seterrorActive(true)
      flag = false;
      // return false
    
    }
    if(flag){
      
      if(activeStep === steps.length - 1){
        let kpi_id = kpiQueryEditParams.get("kpi")
        
        handleApiCallsVisualizationEngine(
          "post",
          `kpi${kpi_id ? `/${kpi_id}/update` : ''}`,
          "",
          {
            "basicFormData": basicFormData,
            "kpiDetailsData": kpiDetailsData,
            "kpiMappingFormData": kpiMappingFormData,
            "kpiLimitationFormData": kpiLimitationFormData,
            "advanceQueryFormData": advanceQueryFormData,
            "filterDetailsFormData": filterDetailsFormData
          }
        ).then((responseData) => {
          navigate('/kpi-manage')
        }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
      }
    }
  }

  React.useEffect(() => {
    if(errorActive){
      dispatch(showGlobalSnackbar('Please Enter valid inputs!', "error"));
      // dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    }
  }, [errorActive]);

  React.useEffect(() => {
    let kpi_id = kpiQueryEditParams.get("kpi")
    if(kpi_id){
      handleApiCallsVisualizationEngine(
          "get",
          `kpi/${kpi_id}/configuration`,
          ""
          ).then((responseData) => {
              // setkpiList([...responseData[1]]);
              let data = responseData[1].queryFormDetails
              setbasicFormData({...data.basicFormData})
              setkpiDetailsData({...data.kpiDetailsData})
              setkpiMappingFormData({...data.kpiMappingFormData})
              setkpiLimitationFormData({...data.kpiLimitationFormData})
              setadvanceQueryFormData({...data.advanceQueryFormData})
              setfilterDetailsFormData(Array.isArray(data.filterDetailsFormData) ?  [...data.filterDetailsFormData] : [])
          }).catch((error) => {
              console.log(error);
              dispatch(showGlobalSnackbar(error.response?.data.error || 'Error !', "error"));
          });
    }
  }, []);
  
  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `data-sources`,
      ""
    ).then((responseData) => {
      setDataSource(responseData[1]);
    }).catch((error) => {
      console.log(error);
      if(error.message === "Network Error"){
        dispatch(showGlobalSnackbar(error.message),"error")
      }
      else{
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      }
    });

    handleApiCallsVisualizationEngine(
      "get",
      `visualization-types`,
      ""
    ).then((responseData) => {
      setGraphType(responseData[1]);
    }).catch((error) => {
      console.log(error);
      if(error.message === "Network Error"){
        dispatch(showGlobalSnackbar(error.message),"error")
      }
      else{
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      }
    });

    // handleApiCallsVisualizationEngine(
    //   "get",
    //   `data-sources`,
    //   ""
    // ).then((responseData) => {
    //   console.log(responseData[1]   )
    //   setDataSource(responseData[1]);
    // }).catch((error) => {
    //   console.log(error);
    // });
  }, []);

  return (
      <div
          style={{
            padding: "0em 2em 2em 2em",
          // overflowX: "auto",
          }}
      >
          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between"}}>
              <h1>Manage KPI</h1>
              {[1,3].includes(activeStep) ? <PlaceholderModal></PlaceholderModal> : <></>}
          </Box>
          <Box>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                <Step key={label.label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label.label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Box>
              {steps[activeStep].form}
            </Box>

            <Box
              style= {{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center',  
                justifyContent: activeStep === 0 ? "flex-end": "space-between", 
                width:"90%",
                margin: "auto",
                paddingBottom:"35px"
              }}
              pt={2}
            >
              {activeStep !== 0 ? 
              <Button  
                variant="contained"
                onClick={() => stepBack()}
                // sx={{marginLeft: "auto" }}
              >
                BACK
              </Button> : <></>}
              {activeStep === steps.length -1 ? 
                <Button  
                  variant="contained"
                  onClick={() => submitForm()}
                  // sx={{marginLeft: "auto" }}
                >
                  SUBMIT
                </Button> 
                :
                <Button 
                  variant="contained"
                  onClick={() => stepNext()}
                >
                  NEXT
                </Button>
              }
            </Box>
          </Box>
      </div>
  )
};



// function for next
