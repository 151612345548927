import { 
    DEVICES_ACC_USER,
    GET_ALL_USERS
 } from "../constants/userAndDevicesConstants";

const initialState ={
    allUsers:null,
    devicesAccUser:null
}



export function userAndDevicesReducer(state=initialState,action){
    switch (action.type) {
        case GET_ALL_USERS:
            return state = {
                ...state,
                allUsers:action.payload
            }
        case DEVICES_ACC_USER:
            return state = {
                ...state,
                devicesAccUser:action.payload
            }
        default:
            return state;
    }
}