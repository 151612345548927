export const SET_TEXT_EDITOR_DATA = "SET_TEXT_EDITOR_DATA"

export const SET_ARTICLE_DATA = "SET_ARTICLE_DATA"

export const ARTICLE_DATA_LOADING = "ARTICLE_DATA_LOADING"

export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA"

export const SET_CATEGORY_DATA_LOADING = "SET_CATEGORY_DATA_LOADING"

export const SET_DOCUMENTATION_DATA="SET_DOCUMENTATION_DATA" 