import React from 'react'
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Switch } from "@mui/material";


// const CustomSwitch = styled(Switch)(
//   ({ theme, switchCircleColor, switchSliderColor }) => ({
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       color: switchCircleColor,
//       "&:hover": {
//         backgroundColor: alpha(
//           switchCircleColor,
//           theme.palette.action.hoverOpacity
//         ),
//       },
//     },
//     "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: switchSliderColor,
//     },
//   })
// );

const SmallCustomSwitch = styled(Switch)(
  ({ theme, switchcirclecolor, switchslidercolor }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  // '&:active': {
  //   '& .MuiSwitch-thumb': {
  //     width: 15,
  //   },
  //   '& .MuiSwitch-switchBase.Mui-checked': {
  //     transform: 'translateX(9px)',
  //   },
  // },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: switchcirclecolor,
      '& + .MuiSwitch-track': {
        // opacity: 1,
        backgroundColor: switchslidercolor, //theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
      // theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default SmallCustomSwitch