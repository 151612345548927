import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
// import store, { persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";
// import "react-multi-carousel/lib/styles.css";
import "./Dashboard.css";
import "./Carousel.css";


const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <App/>
      {/* </PersistGate> */}
    </BrowserRouter>
    
  </Provider>,
  rootElement
);