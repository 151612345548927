import { SET_DAILY_TIPS,ADD_DAILY_TIPS,UPDATE_DAILY_TIPS,DELETE_DAILY_TIPS,SET_ENABLED_DAILY_TIPS } from "../constants/manageDailyTipsConstant"


const initialState={
    completeDailyTips:null,
    enabledDailyTips:null
}
export const manageDailyTipsReducer = (state=initialState,action)=>{
    switch (action.type) {
        case SET_DAILY_TIPS:
            return (
                state={
                    ...state,
                    completeDailyTips:action.payload
                }
            )

        case ADD_DAILY_TIPS:
            return (
                state={
                    ...state,
                    completeDailyTips:action.payload
                }
            )

        case UPDATE_DAILY_TIPS:
            return (
                state={
                    ...state,
                    completeDailyTips:action.payload
                }
            )
        
        case DELETE_DAILY_TIPS : return (
            state={
                ...state,
                completeDailyTips:action.payload
            }
        )

        case SET_ENABLED_DAILY_TIPS : return (
            state={
                ...state,
                enabledDailyTips:action.payload
            }
        )
            
    
        default:
            return state;
    }
}