import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import VideoPlayer from '../../../components/VideoPlayer/videoPlayer';
import DocumentDeatilsHeader from './documentDeatilsHeader';
import DocumentDetailsBody from './documentDetailsBody';
import { useSelector,useDispatch } from 'react-redux';
import { setDocumentationData } from '../../../actions/adminDocumentationAction';
import { useLocation } from 'react-router-dom';
import { useParams , useNavigate } from 'react-router-dom';
import { Button, IconButton, Skeleton } from '@mui/material';
import { SET_DOCUMENTATION_DATA } from '../../../constants/adminDocumentationconstants';
import {Typography} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function DocumentaionDetails() {
  const navigate = useNavigate()
  const history = window.history
  // console.log(history)
  // let location=useLocation()
  // const queryParams = new URLSearchParams(location.search)
  let params=useParams()
  let dispatch=useDispatch()
  const adminDocumentationReducer = useSelector((state)=>state.adminDocumentationReducer)
  const {documentationData,defaultImage}=adminDocumentationReducer

  useEffect(()=>{
    if(params){
      dispatch(setDocumentationData(params.id))
      // console.log(adminDocumentationReducer)
    }

    // for unmounting
    return ()=>{
      dispatch({
        type:SET_DOCUMENTATION_DATA,
        payload:null
      })
    }
  },[params])

  // useEffect(()=>{
  //   console.log('params',params)
  //   console.log('adminDocumentationReducer',adminDocumentationReducer.documentationData)
  // },[adminDocumentationReducer])
  return (
    <div
        style={{
              //    display: "flex",
              padding: "0em 2em 2em 2em",
              // overflowX: "auto",
              // width: "100%",
              //    direction: "rtl",
              //    background: "#ef394e",
              //    boxSizing: "border-box" // <--- this line
              }}
      >
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",paddingTop:"10px"}}
        >     
            <DocumentDeatilsHeader documentationData={documentationData} defaultImage={defaultImage}/>         
            <IconButton onClick={()=>history.back()} sx={{borderRadius:"8px"}}>
              <KeyboardBackspaceIcon/> Back to learn more
            </IconButton>
        </Box>
        <Box
          style={{
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
            justifyContent:"space-around",
            flexGrow: 1 ,
            width:"100%"
          }}
        >
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} width={"100%"}>
            <Grid item xs={2} sm={8} md={12}>
              <DocumentDetailsBody documentationData={documentationData} />
            </Grid>
          </Grid>

        </Box>

      </div>
  )
}

export default DocumentaionDetails