import React, { useEffect } from 'react'
import {Box,IconButton,Tooltip,Typography,useTheme} from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Skeleton from '@mui/material/Skeleton';
import CustomWidthTooltip from'../../../components/General/tooltip'
import { useDispatch,useSelector,useState } from 'react-redux';
import { reactionModify, setKPIUniqueKeysObj } from '../../../actions/reactionActions';
function DashboardCardFooter({graphData, width, children}) {
    // console.log(children)
    // const myDashboardKpisData = useSelector((state)=>{})
    const reactionReducer = useSelector((state)=>state.reactionReducer)
    const userLogin = useSelector((state)=>state.userLogin)
    const {allKpiReactions} = reactionReducer
    const {userInfo} = userLogin
    const myDashboardLayout = useSelector((state)=>state.myDashboardLayout)

    // console.log(myDashboardLayout)

    const dispatch = useDispatch()

    useEffect(()=>{
        if(graphData){
            dispatch(setKPIUniqueKeysObj(allKpiReactions,graphData.kpiId))
        }
    },[graphData])

    // useEffect(()=>{
    //     console.log("allKpiReactions",allKpiReactions)
    // },[allKpiReactions])
  return (
    <Box  sx={{display:'flex',flexDirection: 'row',alignItems:"center",justifyContent:"space-between", background:'00FFFFFF'}}
        style={{borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}
    >
        <Box 
            sx={{width: (width*0.6)}}
            alignItems="Center"
            ml={1}
        >   {
                graphData === undefined ? 
                    <Skeleton width="100%">
                        <Typography>.</Typography>
                    </Skeleton>
                :
                <CustomWidthTooltip title={`${graphData.description}`} arrow>
                    <Typography variant="subtitle2" noWrap={true}>
                        {`Description: ${graphData.description}`}
                    </Typography>
                </CustomWidthTooltip>
            }
            
        </Box>
        <Box className="Dashborad-like-dislike" 
            sx={{width: (width*0.4) , display:'flex', alignItems:"center",flexDirection:'row',justifyContent:"end",}}
        >   
            {
                graphData === undefined ? 
                    <Skeleton width="50%">
                        <Typography>.</Typography>
                    </Skeleton>
                :
                (
                    <>
                    {/* ?? allKpiReactions[`${graphData.kpiId}`]  */}
                    {/* graphData["kpiReaction"] */}
                    <Tooltip title={"Do you find this helpful?"}>
                        <IconButton aria-label="Like" size="small" p={0} 
                        sx={{color:allKpiReactions[`${graphData.kpiId}`] === true ?"blue":"default"}}
                        onClick={
                                ()=>{
                                    // console.log(allKpiReactions[`${graphData.kpiId}`])
                                    if(graphData){
                                        dispatch(reactionModify(graphData.kpiId,userInfo.user_email,true,allKpiReactions))
                                    }
                                }
                            }>
                            {/* {allKpiReactions[`${graphData.kpiId}`] === true ?"green":"default"} */}
                            <ThumbUpAltIcon fontSize={allKpiReactions[`${graphData.kpiId}`] === true ?"medium":"small"} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Do you find this helpful?"}>
                        <IconButton aria-label="dislike" size="small"  p={0}
                        sx={{color:allKpiReactions[`${graphData.kpiId}`] === false ?"red":"default"}}
                            onClick={
                                ()=>{
                                    // console.log(allKpiReactions[`${graphData.kpiId}`])
                                    if(graphData){
                                        dispatch(reactionModify(graphData.kpiId,userInfo.user_email,false,allKpiReactions))
                                    }
                                }
                            }>   
                            {/* {allKpiReactions[`${graphData.kpiId}`] === false ?"green":"default"} */}
                            <ThumbDownAltIcon fontSize={allKpiReactions[`${graphData.kpiId}`] === false ?"medium":"small"} />
                        </IconButton>
                    </Tooltip>
                        {children}
                    </>
                )

            }
        </Box>
    </Box>
  )
}

export default DashboardCardFooter
