import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { useTheme } from '@mui/system';
import {tokens} from '../../../theme'

// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const COLORS = ["#e5eafc","#c5d1fc", "#93aafd", "#2d5bff", "#4a3afe"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PieGraph({ data, width, height }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // console.log(data.data.data)
  return (
      <PieChart width={width} height={height}>
        {/* <Pie
            dataKey={data.data.infoData.yAxis[0].label}
            nameKey={data.data.infoData.xAxis}
            isAnimationActive={true}
            data={data.data.data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill={data.data.infoData.yAxis[0].color}
            label
          />
          <Tooltip /> */}
          <Pie
            data={data.data.data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            nameKey={data.data.infoData.xAxis}
            dataKey={data.data.infoData.yAxis[0].label}
          >
            {data.data.data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip contentStyle={{backgroundColor:colors.kpiCardsTooltipBackground, border:'none'}}/>
      </PieChart>
  );
}

export default PieGraph;
