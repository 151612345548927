import * as React from 'react';
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Menu, IconButton,Toolbar,Avatar, AppBar,Typography, Dialog ,DialogActions ,DialogTitle ,Slide ,ListItemIcon, useTheme, MenuItem, Container, Button, ListItem , ListItemText, Accordion, AccordionSummary, AccordionDetails  } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from "@mui/icons-material/Storage"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
// import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AuthContext from "../../Auth/authProvider"
import AccessControl from '../../AccessControl';
import { getAllNotification, getUnreadNotification } from '../../actions/notificationActions';
import { useDispatch } from 'react-redux';
import state from './../../AccessControl';
import CustomBreadcrumb from './Breadcrumbs';
import { ExpandMore } from '@mui/icons-material'


// const pages = ['Home', 'Device Management', 'Energy Insights'];
const pages = ['Home', 'Energy Insights'];
const pagesIcon = {
    'Home':<HomeOutlinedIcon/>,
    'Device Management':<DevicesOtherOutlinedIcon/>,
    'Energy Insights':<AnalyticsOutlinedIcon/>,
    // 'FAQ\'s':<DescriptionOutlinedIcon fontSize="medium" />,
    // 'Contact-us':<ReportGmailerrorredOutlinedIcon fontSize="medium" />

    }
const pagesRoutes = {
    'Home':'/',
    'Device Management':'/device-manage',
    'Energy Insights':`/analytics?dashboard=${process.env.REACT_APP_ENERGY_INSIGHTS_ID}`,//to be changed
    // 'FAQ\'s': '/documentation',
    //  'Contact-us':'/report-feedback',

    }
    
    const energy_id = process.env.REACT_APP_ENERGY_INSIGHTS_ID

const LogOutDialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Topbar = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, userInfo } = userLogin;
  // console.log("userInfo",userInfo)
  const userReducer2 = useSelector((state)=>state.userReducer2)
  const dispatch = useDispatch();
  
  const notificationReducer = useSelector((state)=>state.notificationReducer)
  const {allNotification,unreadNotification}=notificationReducer

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  //const userSession=sessionStorage.getItem("user")
  let {logoutUser} = useContext(AuthContext)

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [AnchorElHelp, setAnchorElHelp] = React.useState(null);
  const [AnchorElAdmin, seAnchorElAdmin] = React.useState(null);
  const [AnchorElAdminUserRole, seAnchorElAdminUserRole] = React.useState(null);
  const [AnchorElAdminVisualization, seAnchorElAdminVisualization] = React.useState(null);
  const [AnchorElTheme, seAnchorElTheme] = React.useState(null);
  const [AnchorElContentAdmin, seAnchorElContentAdmin] = React.useState(null);
  const [logoutDialogOpen, setlogoutDialogOpen] = React.useState(false);
  
  React.useEffect(()=>{
    if( userLogin.isAuthenticated && "userInfo" in userLogin && "permission" in userLogin.userInfo  ){
      setTimeout(()=>{
        dispatch(getAllNotification())
        dispatch(getUnreadNotification())
      },2000)
      
    }
    
    // userLogin.isAuthenticated && "userInfo" in userLogin && "permission" in userLogin.userInfo
    // || userReducer2.LOCAL_USER_CREDENTIAL
    // dispatch(getAllNotification())
    // console.log("condition for check ",userLogin.isAuthenticated && "userInfo" in userLogin && "permission" in userLogin.userInfo)
    //  console.log("userInfo",userInfo)
    // console.log("userLogin",userLogin)
    // console.log('usersession',userSession)
},[userLogin])

  const UserOpen = Boolean(anchorElUser);
  const HelpOpen = Boolean(AnchorElHelp);
  const AdminOpen = Boolean(AnchorElAdmin);
  const AdminUserRoleOpen = Boolean(AnchorElAdminUserRole);
  const AdminVisualizationOpen = Boolean(AnchorElAdminVisualization);
  const ContentAdminOpen = Boolean(AnchorElContentAdmin);
  const ThemeOpen = Boolean(AnchorElTheme);
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenHelpMenu = (event) => {
    setAnchorElHelp(event.currentTarget);
  };
  const handleOpenAdminMenu = (event) => {
    seAnchorElAdmin(event.currentTarget);
  };
  const handleOpenAdminUserRoleMenu = (event) => {
    seAnchorElAdminUserRole(event.currentTarget);
  };
  const handleOpenAdminVisualizationMenu = (event) => {
    seAnchorElAdminVisualization(event.currentTarget);
  };
  const handleOpenContentAdminMenu = (event) => {
    seAnchorElContentAdmin(event.currentTarget);
  };
  const handleOpenThemeMenu = (event) => {
    seAnchorElTheme(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseHelpMenu = () => {
    setAnchorElHelp(null);
  };
  const handleCloseAdminMenu = () => {
    seAnchorElAdmin(null);
  };
  const handleCloseAdminUserRoleMenu = () => {
    seAnchorElAdminUserRole(null);
  };
  const handleCloseAdminVisualizationMenu = () => {
    seAnchorElAdminVisualization(null);
  };
  const handleCloseContentAdminMenu = () => {
    seAnchorElContentAdmin(null);
  };
  const handleCloseThemeMenu = () => {
    seAnchorElTheme(null);
  };

  const handleThemeChange = (event) => {
    colorMode.toggleColorMode(event.target.value)
  };


  const handleLogoutDialogOpen = () => {
    setlogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setlogoutDialogOpen(false);
  };

  const handleLogOut = () => {
    logoutUser()
    setlogoutDialogOpen(false);
  };
  
  return (

    <>
      { isAuthenticated === false ? <></> 
        :
        <>
          <AppBar position="sticky" style={{ background: colors.navbar }} >
            <Container maxWidth="xxl">
              <Toolbar disableGutters >

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  // color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {/* For mobile navbar */}
                    {pages.map((page) => {
                      return (
                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                          <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            startIcon={pagesIcon[page]}
                            component={Link}
                            to={pagesRoutes[page]}
                          >
                            {page}
                          </Button>
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </Box>

                <Box
                  component="img"
                  sx={{
                    height: 30,
                    width: 150,
                    display: { xs: 'flex', md: 'none' },
                    mr: 2
                  }}
                  alt="BridgeWater Logo"
                  src="../../assets/town_of_bridge.png"
                />
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                 <AccessControl allowedPermissions={["userPrivilege"]} >
                    {/* For Desktop navbar */}
                    <>
                      {pages.map((page) => {
                        return (
                          <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            startIcon={pagesIcon[page]}
                            component={Link}
                            to={pagesRoutes[page]}
                            sx={{ pr: 2, pl: 2 }}
                          >
                            {page}
                          </Button>
                        )
                      })}
                    
                      <Button
                        key={"Help"}
                        onClick={handleOpenHelpMenu}
                        startIcon={<DescriptionOutlinedIcon fontSize="medium" />}
                        sx={{ pr: 2, pl: 2 }}
                         component={Link}
                          to='/documentation'
                      >
                        {"FAQ's"}
                      </Button> 
                      <Button
                        key={"Help"}
                        onClick={handleOpenHelpMenu}
                        startIcon={ <SupportAgentIcon fontSize="medium" />}
                        sx={{ pr: 2, pl: 2 }}
                         component={Link}
                          to='/report-feedback'
                      >
                        {"Contact us"}
                      </Button> 
                    </>

                  </AccessControl>
                 
                 <AccessControl allowedPermissions={["adminPrivilege"]} >
                  {/* <Button
                        onClick={handleOpenHelpMenu}
                        startIcon={ <HomeOutlinedIcon/>}
                        sx={{ pr: 2, pl: 2 }}
                        component={Link}
                         to='/'
                      >
                        {"Home"}
                      </Button> */}
                      {/* device management page moved to account management tab */}
                      {/* <Button
                        onClick={handleOpenHelpMenu}
                        startIcon={ <AnalyticsOutlinedIcon/>}
                        sx={{ pr: 2, pl: 2 }}
                        component={Link}
                        to={`/analytics?dashboard=${energy_id}`}
                      >
                        {"Energy Insights"}
                      </Button> */}
                    <Button
                      key={"Admin"}
                      onClick={handleOpenAdminMenu}
                      startIcon={<AdminPanelSettingsOutlinedIcon />}
                      sx={{ pr: 2, pl: 2 }}
                    >
                      {"Admin"}
                    </Button>
               
                    <Menu
                      anchorEl={AnchorElAdmin}
                      id="admin"
                      open={AdminOpen}
                      onClose={handleCloseAdminMenu}
                      // onClick={handleCloseAdminMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={(event) => { handleOpenAdminUserRoleMenu(event) }}>
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        User & Role
                      </MenuItem>
                      <MenuItem onClick={(event) => { handleOpenAdminVisualizationMenu(event) }}>
                        <ListItemIcon>
                          <AutoGraphOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Visualization
                      </MenuItem>
                      <MenuItem onClick={(event) => { handleOpenContentAdminMenu(event) }}>
                        <ListItemIcon>
                          <FeaturedPlayListOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Manage Content
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElAdminUserRole}
                      id="adminUserRole"
                      open={AdminUserRoleOpen}
                      onClose={handleCloseAdminUserRoleMenu}
                      onClick={() => { handleCloseAdminUserRoleMenu(); handleCloseAdminMenu() }}
                      // PaperProps={{
                      //   elevation: 0,
                      //   sx: {
                      //     overflow: "visible",
                      //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      //     mt: 1.5,
                      //     "& .MuiAvatar-root": {
                      //       width: 32,
                      //       height: 32,
                      //       ml: -0.5,
                      //       mr: 1,
                      //     },
                      //     // "&:before": {
                      //     //   content: '""',
                      //     //   display: "block",
                      //     //   position: "absolute",
                      //     //   top: 0,
                      //     //   right: 14,
                      //     //   width: 10,
                      //     //   height: 10,
                      //     //   bgcolor: "background.paper",
                      //     //   transform: "translateY(-50%) rotate(45deg)",
                      //     //   zIndex: 0,
                      //     // },
                      //   },
                      // }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          ml: 0.9,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 20,
                            right: 132,
                            // right:118,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      // transformOrigin={{ horizontal: "left", vertical: "buttom" }}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}

                    >
                      <MenuItem
                        component={Link}
                        to='/admin-manage'
                      >
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Admin Management
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/users-manage'
                      >
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Users Management
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/users-and-devices'
                      >
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Users & Devices
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/role-permission'
                      >
                        <ListItemIcon>
                          <SupervisedUserCircleOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Roles
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/audit-log'
                      >
                        <ListItemIcon>
                          <TroubleshootOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Audit Log
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElAdminVisualization}
                      id="adminVisualization"
                      open={AdminVisualizationOpen}
                      onClose={handleCloseAdminVisualizationMenu}
                      onClick={() => { handleCloseAdminVisualizationMenu(); handleCloseAdminMenu() }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          ml: 0.8,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 20,
                            right: 132,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      // transformOrigin={{ horizontal: "left", vertical: "buttom" }}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <MenuItem component={Link} to='/filter-manage'>
                        <ListItemIcon>
                          <AddchartOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        KPI Filters
                      </MenuItem>
                      <MenuItem component={Link} to='/kpi-manage'>
                        <ListItemIcon>
                          <AddchartOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        KPIs
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/manage-category'
                      >
                        <ListItemIcon>
                          <CategoryOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Categories 
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/manage-dashboard'
                      >
                        <ListItemIcon>
                          <SpaceDashboardOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Dashboards
                      </MenuItem>
                      <MenuItem component={Link} to='/db-manage' >
                        <ListItemIcon>
                          <StorageIcon fontSize="medium" />
                        </ListItemIcon>
                        Manage Database
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElContentAdmin}
                      id="adminContent"
                      open={ContentAdminOpen}
                      onClose={handleCloseContentAdminMenu}
                      onClick={() => { handleCloseContentAdminMenu(); handleCloseAdminMenu() }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          ml: 0.8,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 20,
                            left: -5,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      // transformOrigin={{ horizontal: "left", vertical: "buttom" }}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <MenuItem
                        component={Link}
                        to="/manage-documentation"
                      >
                        <ListItemIcon>
                          <DescriptionOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        {/* Documentaion */}
                        FAQ's
                      </MenuItem>
                      {/* <MenuItem
                        component={Link}
                        to='/faq-create'
                      >
                        <ListItemIcon>
                          <ContactSupportOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        FAQ
                      </MenuItem> */}
                      <MenuItem
                        component={Link}
                        to='/manage-daily-tips'
                      >
                        <ListItemIcon>
                          <TipsAndUpdatesOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        Manage Daily Tips
                      </MenuItem>
                    </Menu>
                  </AccessControl>
                </Box>

                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === "dark" ? (
                    <LightModeOutlinedIcon />
                  ) : (
                    <DarkModeOutlinedIcon />
                  )}
                </IconButton>
                {/* <IconButton to="/settings" component={Link}>
                <SettingsSuggestOutlinedIcon/>
              </IconButton> */}
                <IconButton to="/notifications" component={Link}>
                  <Badge badgeContent={unreadNotification ? unreadNotification : null} color="primary">
                    {/* <MailIcon color="action" /> */}
                    <NotificationsNoneOutlinedIcon fontSize='medium' />
                  </Badge>
                </IconButton >

                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    key={'Account Management'}
                    onClick={handleOpenUserMenu}
                    startIcon={<PersonOutlinedIcon />}
                    sx={{ pr: 2, pl: 2 }}
                    aria-controls={UserOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={UserOpen ? "true" : undefined}
                  >
                    Account Management
                  </Button>
                  <Menu
                    anchorEl={anchorElUser}
                    id="account-menu"
                    open={UserOpen}
                    onClose={handleCloseUserMenu}
                    onClick={handleCloseUserMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <ListItem
                      sx={{ pb: 0, pt: 0 }}
                    >
                      <Avatar />
                      <ListItemText
                        primary={`${userInfo.user_email}`}
                        secondary={userInfo.role}
                      />
                    </ListItem>

                    <MenuItem
                      component={Link}
                      to='/user-account'
                    >
                      <ListItemIcon>
                        <ManageAccountsOutlinedIcon fontSize="medium" />
                      </ListItemIcon>
                      Manage Account
                    
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to='/device-manage'
                    >
                      <ListItemIcon>
                      <DevicesOtherOutlinedIcon/>
                      </ListItemIcon>
                      Device Management
                    </MenuItem>

                    <MenuItem
                      onClick={handleLogoutDialogOpen}
                    >
                      <ListItemIcon>
                        <LogoutOutlinedIcon fontSize="medium" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                  <Dialog
                    open={logoutDialogOpen}
                    TransitionComponent={LogOutDialogTransition}
                    keepMounted
                    onClose={handleLogoutDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}
                      >
                        <LogoutOutlinedIcon fontSize="small" />
                        <Typography>
                          Do you want to Logout?
                        </Typography>
                      </Box>
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleLogoutDialogClose}>Cancel</Button>
                      <Button onClick={handleLogOut}>OK</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <CustomBreadcrumb/>
        </>         
      }
    </>

  );
};

export default Topbar;