import * as React from 'react';
import { Stack,Typography,TextField,Box,Paper,Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import ResetPasswordComponent from './resetPasswordComponent';
const UserAccount= () => {

const userLogin = useSelector(state => state.userLogin)
const {userInfo} = userLogin

// console.log(userInfo)

  return (
    <div
      style={{
        padding: "0em 2em 2em 2em",
        overflowX: "auto",
      }}
    >
        <h1>Account Management</h1>
        <Box sx={{ flexGrow: 1 }} >
            <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid xs={2} sm={4} md={6} >
                    <Paper sx={{p:3, borderRadius:3,height:"100%"}}>
                        {/* <Typography variant='h5' fontWeight="bold">
                            User Details
                        </Typography> */}
                        <Box sx={{pt:2}}>
                            <TextField
                                disabled
                                fullWidth
                                id="outlined-disabled"
                                label="Email"
                                defaultValue={userInfo.user_email}
                            />
                        </Box>
                        <Box sx={{pt:2}}>
                            <TextField
                                disabled
                                fullWidth
                                id="outlined-disabled"
                                label="Name"
                                defaultValue={userInfo.role}
                            />
                        </Box>
                        <Box sx={{pt:2}}>
                            <TextField
                                disabled
                                fullWidth
                                id="outlined-disabled"
                                label="Role"
                                defaultValue={userInfo.role}
                            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid xs={2} sm={4} md={6}>
                    <ResetPasswordComponent email={userInfo.user_email}/>
                </Grid>
                
                {/* <Grid xs={2} sm={4} md={6}>
                    <Paper sx={{p:3, borderRadius:3}}>
                        <Typography variant='h5' fontWeight="bold">
                            Password Change
                        </Typography>
                        <Stack direction={{ xs: 'column',}}>
                            <Box sx={{pb:1, pt:2}}>
                                <TextField id="old-outlined-basic" fullWidth  label="Old Password" variant="outlined" type='Password' />
                            </Box>
                            <Box sx={{pb:1}}>
                                <TextField id="new-outlined-basic" fullWidth  label="New pasword" variant="outlined" type='Password' />
                            </Box>
                            <Box sx={{pb:2}}>
                                <TextField id="cnf-new-outlined-basic" fullWidth  label="Confirm New Password" variant="outlined" type='Password'/>
                            </Box>
                            <Box>
                                <Button variant="contained" size="medium">Change Password</Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Grid> */}
            </Grid>
        </Box>
    </div>
  );
}

export default UserAccount;
