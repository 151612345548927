import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetAdminDashboardPreviewEachKpiData} from '../../../../../../actions/dashboardPreviewActions';
import KpiCard from "./KpiCard";

const KpiCardContainer = React.forwardRef(
  function KpiCardContainer({kpiData, item, ...props }, ref) {

  const dispatch = useDispatch()

  const admindashboardPreviewKpisData = useSelector(state => state.admindashboardPreviewKpisData)
  const {error: dashboardKpisDataError, loading: dashboardKpisDataLoading, dashboardPreviewKpisData} = admindashboardPreviewKpisData

  useEffect(()=>{
    dispatch(GetAdminDashboardPreviewEachKpiData(item));
  }, [dispatch, item])

  if (dashboardKpisDataLoading) return <div>Loading...</div>;
  return (
    <KpiCard kpiData={dashboardPreviewKpisData[item]} kpisdataerror={dashboardKpisDataError[item]} {...props} ref={ref} />
  );
})

export default (KpiCardContainer);
