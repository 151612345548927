import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { UserlastLoginCheck } from '../../actions/userActions';
import { Button, Grid, Typography, Container, TextField, Box, CssBaseline, Paper, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
// let QRCode = require('qrcode')
import QRCode from "qrcode"
import { useSelector } from 'react-redux';
import { OtpInputWithValidation } from '../OTPPage/OTPPage';
import { useNavigate } from 'react-router-dom';




// import PasswordComponent from './passwordComponent';



export default function QRCodeComponent() {
    let canvasRef = useRef(null)

    const navigate=useNavigate()
    const dispatch = useDispatch();
    const userReducer2 = useSelector((state) => state.userReducer2)

    const { SET_USER_PASSWORD_RESP } = userReducer2

    const [OTPVal, setOTPVal] = useState("");

    async function QRGenerator(qrText) {
        try {
            let qrGen = await QRCode.toCanvas(canvasRef.current, qrText)
            console.log("qrGen", qrGen)
        } catch (error) {
            console.error("failed to generate QRCode")
        }
    }

    // useEffect(()=>{
    //     if(SET_USER_PASSWORD_RESP.result.mfa_enabled[2]){
    //         setQRText(QRGenerator(QRText))
    //     }

    // },[])

    useEffect(() => {
        if (SET_USER_PASSWORD_RESP) {
            QRGenerator(SET_USER_PASSWORD_RESP.result.mfa_enabled[2])
        }

    }, [SET_USER_PASSWORD_RESP])

    useEffect(() => {
        if (OTPVal.length > 5) {
            console.log("MFA OTP Val", OTPVal)
        }

    }, [OTPVal])



    return (
        <Container component="main" maxWidth="lg">
            <Box
                sx={{
                    marginTop: 16,
                }}
            >
                <Grid container>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: `url(../../../assets/QRCode.svg)`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) =>
                                t.palette.mode === "light"
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                        pt={1}
                        pb={4}
                        pl={4}
                        pr={4}
                        minHeight={400}
                    >
                        <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                            <Box component="img"
                                sx={{
                                    height: 30,
                                    // display: { xs: 'flex', md: 'none' },
                                    mt: 2
                                }}
                                alt="BridgeWater Logo"
                                src="../../assets/town_of_bridge.png"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant='h3'>
                                Energy Management Information System
                            </Typography>
                            <br/>
                            <Typography variant='h3'>
                                Scan the QR-Code to get the OTP 
                            </Typography>

                            {
                                !SET_USER_PASSWORD_RESP ?
                                    <Skeleton height={"300px"} width={"200px"} /> :
                                    <canvas ref={canvasRef} width={"200px"} height={"200px"} />
                            }
                            
                            {/* <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
                                <Typography variant='h3'>Enter your OTP here</Typography>
                                <OtpInputWithValidation numberOfDigits={6} passOTPToParent={setOTPVal} />

                            </Box> */}
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}

                                onClick={() => {
                                    navigate("/OTP-Authentication")
                                }}
                            >
                                Next
                            </Button>

                            {/* </canvas> */}
                            {/* <Box width="100%" pt={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="QRText"
                                    label="Temporary Password"
                                    name="Temporary Password"
                                    autoComplete="Temporary Password"
                                    value={QRText}
                                    onChange={(e) => setQRCode(e.target.value)}
                                    autoFocus
                                />
                            </Box> */}
                        </Box>
                    </Grid>
                    <Grid container>
                        <Grid item xs >
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}