import React from 'react'
import { IconButton, Paper, Typography, Tooltip , Box} from "@mui/material";
import {Button} from '@mui/material';
import FAQPopUp from './FAQPopUp';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import FAQDelete from './FAQDelete';
import { deleteAdminFAQ } from '../../actions/adminFAQActions';
import { useDispatch } from 'react-redux';
import ExpandableText from '../General/expandableText';

function FaqCardComponent({faqDetails}) {
    let dispatch=useDispatch()
  return (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} >
          <div
              style={{
                  display: 'flex',
                  justifyContent: "space-between",
                  // paddingBottom:"5px"
                //   position:"relative",
                //   top:"3px",                
              }}
          >
              <div></div>
              <div style={{
                display:"flex",
                justifyContent:"space-around",
                flexDirection:"column",
                position:"relative",
                top:"-7px"
                // marginTop: "-10px"
              }}>
                <IconButton>
                      <FAQPopUp
                          specificFAQData={faqDetails}
                          action="edit"
                      />
                </IconButton>        
                  {/* <Tooltip title="Delete FAQ"> */}
                  <FAQDelete
                    specificFAQData={faqDetails}
                  />                
              </div>
          </div>
          <Typography variant="h6" sx={{ 
            fontWeight: 'bold',
            display:"flex",
            marginTop:"-85px",
            wordBreak: "keep-all",
            width:"80%",
            }}>
              {/* <>{`Que`}</> &nbsp; */}
              &nbsp;
              {/* {faqDetails.faq_question} */}
              <ExpandableText value={faqDetails.faq_question} length={30} type="Question"/>
          </Typography>
          
          <Typography
          sx={{
            width:"80%",
            display:"flex",
            alignItems:"flex-start"
          }}
          variant="body1">
            <Typography variant='h6' sx={{fontWeight:"bold",wordBreak:"keep-all"}}>
              {/* { `Ans` }&nbsp; */}
              &nbsp;
            </Typography>
            <Box pt={0.2} variant="h6" sx={{wordBreak:"keep-all"}}>
              <ExpandableText value={faqDetails.faq_answer} length={37} type="Answer"/>
            </Box>
            {/* <Typography pt={0.3} pl={0.5} sx={{wordBreak: "break-word"}}>{faqDetails.faq_answer}</Typography> */}
          </Typography>
          <Typography 
          variant='h6' 
          sx={{
            fontWeight:"bold",
            // wordBreak: "break-word",
            width:"80%",
            display:"flex",
            alignItems:"flex-start",
            paddingLeft:"8px"
          }}
          >
            &nbsp;
            {`Tags`}  
            <ExpandableText value={faqDetails.faq_tags} length={20} type="Tags"/>
              {/* <Typography pt={0.2} pl={0.5} sx={{wordBreak: "break-word"}}>{faqDetails.faq_tags}</Typography> */}
          </Typography>
      </Paper>
  )
}

export default FaqCardComponent