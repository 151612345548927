import * as React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { useTheme } from '@mui/system';

import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";

// TODO: get layout templates from backend
const layoutTemplates = [
  { value: 1, label: 'Template 1' },
  { value: 2, label: 'Template 2' },
  { value: 3, label: 'Template 3' },
  { value: 4, label: 'Template 4' },
  { value: 5, label: 'Template 5' },
  { value: 6, label: 'Template 6' },
  { value: 7, label: 'Template 7' },
  { value: 8, label: 'Template 8' },
  { value: 9, label: 'Template 9' },
  { value: 10, label: 'Template 10' },
  { value: 11, label: 'Template 11' }
];

export default function NewCategoryModal({ open, setOpen, setCategoryList, addCategory }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: "20px",
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark' ? '#c7c5c55c' : '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [form, setForm] = React.useState({
    name: '',
    description: '',
    footer: '',
    layoutTemplate: '',
    categoryStatement: ''
  });
  const [errorActive, seterrorActive] = React.useState(false);

  return (
    open === true ?
      <Box>
        <Modal
          open={open}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '70%' }}>
            <Typography sx={{ fontSize: "30px" }} fontWeight={500} pb={3}>Create a New Category</Typography>
            <Box
              pb={2}
            >
              <TextField
                fullWidth id="category_name"
                label="Category Name"
                variant="outlined"
                value={form.name}
                error={errorActive ? form.name === '' : false}
                onChange={e => { setForm({ ...form, name: e.target.value }) }}
              />
            </Box>
            <Box
              pb={2}
            >
              <TextField
                fullWidth
                id="category_description"
                label="Category Descrition"
                variant="outlined"
                multiline
                rows={1}
                value={form.description}
                error={errorActive ? form.description === '' : false}
                onChange={e => { setForm({ ...form, description: e.target.value }) }}
              />
            </Box>
            <Box
              pb={2}
            >
              <TextField
                fullWidth id="category_footer"
                label="Category Footer"
                variant="outlined"
                value={form.footer}
                error={errorActive ? form.name === '' : false}
                onChange={e => { setForm({ ...form, footer: e.target.value }) }}
              />
            </Box>
            <Box pb={2}>
              <FormControl fullWidth>
                <InputLabel id="category-layout-label">Category Layout</InputLabel>
                <Select
                  labelId="category-layout-label"
                  value={form.layoutTemplate}
                  onChange={e => { setForm({ ...form, layoutTemplate: e.target.value }) }}
                  label="Category Layout"
                  sx={{ width: '100%' }}
                  MenuProps={{
                    sx: {
                      zIndex: 10000,
                    },
                  }}
                >
                  {layoutTemplates.map(template => (
                    <MenuItem key={template.value} value={template.value}>
                      {template.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              pb={2}
            >
              <TextField
                fullWidth id="category_statement"
                label="Category Statement"
                variant="outlined"
                value={form.categoryStatement}
                onChange={e => { setForm({ ...form, categoryStatement: e.target.value }) }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end", marginLeft: "auto", }} gap={1}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  setForm(
                    {
                      name: '',
                      description: '',
                      footer: '',
                      layoutTemplate: '',
                      categoryStatement: ''
                    });
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  if (form.name === '' || form.description === '' || form.footer === '') {
                    seterrorActive(true)
                  } else {
                    handleApiCallsVisualizationEngine(
                      "post",
                      `categories`,
                      "",
                      { ...form }
                    ).then(() => {
                      handleApiCallsVisualizationEngine(
                        "get",
                        `categories`,
                        ""
                      ).then((responseData) => {
                        setCategoryList([...responseData[1]]);
                        setForm({
                          name: '',
                          description: '',
                          footer: '',
                          layoutTemplate: '',
                          categoryStatement: ''
                        });
                      }).catch((error) => {
                        console.log(error);
                        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                      });
                      setOpen(false)
                    }).catch((error) => {
                      console.log(error);
                      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                    }
                    );
                  }
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      : <></>
  );
}
