import { SET_APPLIED_LOCAL_FILTER } from "../constants/appliedLocalFilterConstant"

const initialState={localFilter:{}}

export function appliedLocalFilter(state=initialState,action){
    switch (action.type){
        case SET_APPLIED_LOCAL_FILTER:
            return {
                ...state,
                localFilter:action.payload
            }
        default : return state
    }
}