import { DEVICES_ACC_USER, GET_ALL_USERS } from "../constants/userAndDevicesConstants";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";

export function getAllUsers(setallUsersError){
    return async (dispatch,getState)=>{
        try {
            const allUsersResp = await handleApiCalls(
                "get",
                "admin/fetch-user?role_name=Consumer",
                "fetchConsumer",
                // "fetchAdmin",
            )

            if(allUsersResp[2] !== ""){
                throw new Error(allUsersResp[2])
            }

            
            await dispatch({
                type:GET_ALL_USERS,
                payload:allUsersResp[1]
            })
            // console.log("userAndDevicesReducer",getState().userAndDevicesReducer)
        } catch (error) {
            // console.log(error.message)
            await dispatch(showGlobalSnackbar(error.message,"error"))
            await setallUsersError(true)
        }
    }
}

// export async function getUserDevices (user_id,setdevicesAccUser){
//     console.log("user_id",user_id)
//     try {
//         const getUserDevicesResp = await handleApiCalls(
//             "post",
//             "device/fetch-all-user-devices",
//             "deviceAdmin",
//             {
//                 user_id:user_id
//             }
//         )
        
//         if(getUserDevicesResp[2] !== ""){
//             throw new Error(getUserDevicesResp[2])
//         }
//         console.log("getUserDevicesResp",getUserDevicesResp)

//         await setdevicesAccUser(getUserDevicesResp[1])
//         // await dispatch({
//         //     type:DEVICES_ACC_USER,
//         //     payload:getUserDevicesResp[1]
//         // })

//     } catch (error) {
//         await dispatch(showGlobalSnackbar(error.message,"error"))
//     }

// }

export function getUserDevices (user_id,setdevicesAccUser){
    return async (dispatch,getState)=>{
        // console.log("user_id",user_id)
        try {
            const getUserDevicesResp = await handleApiCalls(
                "post",
                "device/fetch-all-user-devices",
                "deviceAdmin",
                {
                    user_id:user_id
                }
            )
            
            if(getUserDevicesResp[2] !== ""){
                throw new Error(getUserDevicesResp[2])
            }
            // console.log("getUserDevicesResp",getUserDevicesResp)

            await setdevicesAccUser(getUserDevicesResp[1])

        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message,"error"))
        }
    }
}

export function updateThreshold (inpThreshold,userId,deviceId,setUpdateEnabled,navigate){
    return async (dispatch,getState)=>{
        await setUpdateEnabled(false)
        // console.log({
        //     "user_id": userId,
        //     "device_id": deviceId,
        //     "device_threshold": {
        //         ...inpThreshold
        //     }
        // })
        try {
            const updateThresholdResp = await handleApiCalls(
                "post",
                "device/modify-device-threshold",
                "deviceAdmin",
                {
                    "user_id": userId,
                    "device_id": deviceId,
                    "device_threshold": {
                        ...inpThreshold
                    }
                }
            )

            if(updateThresholdResp[2] !== ""){
                throw new Error(updateThresholdResp[2])
            }
            console.log(updateThresholdResp)
            await dispatch(showGlobalSnackbar(updateThresholdResp[1],"success"))
            
            // await navigate(0)
            //for resetting dashboard
            // await (
            //     async ()=>{
            //         try {
            //             const allUsersResp = await handleApiCalls(
            //                 "get",
            //                 "admin/fetch-user?role_name=Consumer",
            //                 "fetchConsumer",
            //                 // "fetchAdmin",
            //             )
            
            //             if(allUsersResp[2] !== ""){
            //                 throw new Error(allUsersResp[2])
            //             }
            
                        
            //             await dispatch({
            //                 type:GET_ALL_USERS,
            //                 payload:allUsersResp[1]
            //             })
            //             // console.log("userAndDevicesReducer",getState().userAndDevicesReducer)
            //         } catch (error) {
            //             // console.log(error.message)
            //             await dispatch(showGlobalSnackbar(error.message,"error"))
                        
            //         }
            //     }
            // )()

        } catch (error) {
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.response.data.result,"error"))
            }
            else{
                await dispatch(showGlobalSnackbar(error.message,"error"))
            }
        }
    }
}