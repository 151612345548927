// import type { RuleType } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
const { Field, RuleType } = require('react-querybuilder');

// export const validator = (r) => !!r.value;
export const validator = (rule) => {
  // console.log(rule.value !== undefined && rule.value !== null && rule.value !== '' && rule.value !== '%')
  if(Object.keys(rule).includes('rules')){
    return rule.rules.every(validator)
  }
  else{
    return rule.value !== undefined && rule.value !== null && rule.value !== '%';
  }
};

// export const fields = [
//   {
//     name: 'firstName',
//     label: 'First Name',
//     placeholder: 'Enter Value',
//     validator,
//   },
//   {
//     name: 'lastName',
//     label: 'Last Name',
//     placeholder: 'Enter last name',
//     defaultOperator: 'beginsWith',
//     validator,
//   },
//   { name: 'age', label: 'Age', inputType: 'number', validator },
//   {
//     name: 'isMusician',
//     label: 'Is a musician',
//     valueEditorType: 'checkbox',
//     operators: defaultOperators.filter((op) => op.name === '='),
//     defaultValue: false,
//   },
//   {
//     name: 'instrument',
//     label: 'Primary instrument',
//     valueEditorType: 'select',
//     values: musicalInstruments,
//     defaultValue: 'Cowbell',
//     operators: defaultOperators.filter((op) => op.name === '='),
//   },
//   {
//     name: 'alsoPlays',
//     label: 'Also plays',
//     valueEditorType: 'multiselect',
//     values: musicalInstruments,
//     defaultValue: 'More cowbell',
//     operators: defaultOperators.filter((op) => op.name === 'in'),
//   },
//   {
//     name: 'gender',
//     label: 'Gender',
//     operators: defaultOperators.filter((op) => op.name === '='),
//     valueEditorType: 'radio',
//     values: [
//       { name: 'M', label: 'Male' },
//       { name: 'F', label: 'Female' },
//       { name: 'O', label: 'Other' },
//     ],
//   },
//   { name: 'height', label: 'Height', validator },
//   { name: 'job', label: 'Job', validator },
//   { name: 'description', label: 'Description', valueEditorType: 'textarea' },
//   { name: 'birthdate', label: 'Birth Date', inputType: 'date' },
//   { name: 'datetime', label: 'Show Time', inputType: 'datetime-local' },
//   { name: 'alarm', label: 'Daily Alarm', inputType: 'time' },
//   {
//     name: 'groupedField1',
//     label: 'Grouped Field 1',
//     comparator: 'groupNumber',
//     groupNumber: 'group1',
//     valueSources: ['field', 'value'],
//   },
//   {
//     name: 'groupedField2',
//     label: 'Grouped Field 2',
//     comparator: 'groupNumber',
//     groupNumber: 'group1',
//     valueSources: ['field', 'value'],
//   },
//   {
//     name: 'groupedField3',
//     label: 'Grouped Field 3',
//     comparator: 'groupNumber',
//     groupNumber: 'group1',
//     valueSources: ['field', 'value'],
//   },
//   {
//     name: 'groupedField4',
//     label: 'Grouped Field 4',
//     comparator: 'groupNumber',
//     groupNumber: 'group1',
//     valueSources: ['field', 'value'],
//   },
// ];

export const fields = [
  { name: 'ammount', label: 'Ammont', inputType: 'number' , placeholder: 'Enter Value', validator},
  {
    name: 'isAdmin',
    label: 'Is a Admin',
    valueEditorType: 'checkbox',
    operators: defaultOperators.filter((op) => op.name === '='),
    defaultValue: false,
    placeholder: 'Enter Value'
  },
  { name: 'branch', label: 'Branch', placeholder: 'Enter Value', validator, },
  { name: 'location', label: 'Location', validator ,placeholder: 'Enter Value'},
  { name: 'description', label: 'Description', valueEditorType: 'textarea' , placeholder: 'Enter Value'},
  { name: 'accountDate', label: 'Account Create Date', inputType: 'date' , placeholder: 'Enter Value'},
  { name: 'datetime', label: 'Show Time', inputType: 'datetime-local' , placeholder: 'Enter Value'},
  {
    name: 'currency',
    label: 'Currency',
    operators: defaultOperators.filter((op) => op.name === '='),
    valueEditorType: 'radio',
    values: [
      { name: 'USD', label: 'USD' },
      { name: 'CAD', label: 'CAD' },
      { name: 'INR', label: 'INR' },
    ],
    placeholder: 'Select Value'
  }
];