import { handleApiCalls } from "./actionHelper";
import {
  GET_ALL_ROLE_REQUEST,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAIL,
  GET_ONE_ROLE_PERMISSION_REQUEST,
  GET_ONE_ROLE_PERMISSION_SUCCESS,
  GET_ONE_ROLE_PERMISSION_FAIL,
  MODIFY_ONE_ROLE_PERMISSION_REQUEST,
  MODIFY_ONE_ROLE_PERMISSION_SUCCESS,
  MODIFY_ONE_ROLE_PERMISSION_FAIL,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  CREATE_NEW_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ONE_ROLE_DATA_SUCCESS,
} from "../constants/roleConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { enqueueSnackbar } from "notistack";

export const GetAllRolesData = () => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: GET_ALL_ROLE_REQUEST });

    responseData = await handleApiCalls("get", "admin/role-list", "viewRoles");

    if (responseData[2] === "") {
      dispatch({
        type: GET_ALL_ROLE_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: GET_ALL_ROLE_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }
  } catch (error) {
    dispatch(showGlobalSnackbar(error, "error"));
  }
};

export const GetPermisssionListSingleRole = (roleId) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: GET_ONE_ROLE_PERMISSION_REQUEST });

    responseData = await handleApiCalls(
      "get",
      `admin/role-permission?role=${roleId}`,
      "viewRolesPermission"
    );

    if (responseData[2] === "") {
      dispatch({
        type: GET_ONE_ROLE_PERMISSION_SUCCESS,
        identity: roleId,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: GET_ONE_ROLE_PERMISSION_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }
  } catch (error) {
    dispatch(showGlobalSnackbar(error, "error"));
  }
};

export const ModifyPermisssionListToSingleRole = (modifyData, role_id, permissionUpdate) => async (dispatch) => {
  let responseData = [];

  try {
      dispatch({ type: MODIFY_ONE_ROLE_PERMISSION_REQUEST });

      responseData = await handleApiCalls("post",`admin/modify-role`, "modifyRole", modifyData );

      if (responseData[2] === "") {
        dispatch({
          type: MODIFY_ONE_ROLE_PERMISSION_SUCCESS,
        });
        if (permissionUpdate) {
          dispatch(GetPermisssionListSingleRole(role_id));
        } else {
          dispatch({
            type: UPDATE_ONE_ROLE_DATA_SUCCESS,
            payload: role_id,
            updateValue: modifyData["role_description"],
          });
        }
      }
      else{
        dispatch({
          type: MODIFY_ONE_ROLE_PERMISSION_FAIL,
          payload: responseData[2],
        });
        dispatch(showGlobalSnackbar(responseData[2], "error"));
      }
      
    } catch (error) {
      dispatch({
        type: MODIFY_ONE_ROLE_PERMISSION_FAIL,
        payload: error,
      });
      dispatch(
        showGlobalSnackbar(error, "error")
      );
    }
  };

export const CreateNewRole = (roleData) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: CREATE_NEW_ROLE_REQUEST });

    responseData = await handleApiCalls("post", `admin/create-role`,"createRole", roleData);

    if (responseData[2] === "") {
      dispatch({
        type: CREATE_NEW_ROLE_SUCCESS,
        payload: responseData[1],
      });
    }else{
      dispatch({
        type: CREATE_NEW_ROLE_FAIL,
        payload: responseData[2],
      });
      dispatch(
        showGlobalSnackbar(responseData[2], "error")
      );
    }

    
  } catch (error) {
    dispatch({
      type: CREATE_NEW_ROLE_FAIL,
      payload: error,
    });
    dispatch(showGlobalSnackbar(error, "error"));
  }
};

export const DeleteRole = (roleData) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: DELETE_ROLE_REQUEST });

    responseData = await handleApiCalls("post", `admin/delete-role`,"deleteRole", roleData);

    if (responseData[2] === "") {
      dispatch({
        type: DELETE_ROLE_SUCCESS,
        payload: parseInt(roleData["role_id"]),
      });
    } else {
      dispatch({
        type: DELETE_ROLE_FAIL,
        payload: responseData[2],
      });
      dispatch(
        showGlobalSnackbar(responseData[2], "error")
      );
    }

    
  } catch (error) {
    dispatch({
      type: DELETE_ROLE_FAIL,
      payload: error,
    });
    dispatch(showGlobalSnackbar(error, "error"));
  }
};
