import { GET_DEVICE_DATA } from "../constants/deviceManagementContants"



const initialState={
    DeviceData:null
}


export function deviceManagementReducer(state=initialState,action){
    switch (action.type) {
        case GET_DEVICE_DATA:
            return (
                state={
                    ...state,
                    DeviceData:action.payload
                }
            )
        default:
            return state
    }
}