import React, { useEffect } from 'react'
import {Box,Button,TextField,Tooltip,IconButton, Typography, useMediaQuery} from '@mui/material';
import {Skeleton} from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import VideoPlayer from '../../../components/VideoPlayer/videoPlayer';


function DocumentDetailsBody({documentationData}) {
  const _540px = useMediaQuery("(max-width:540px)")
  const _768px = useMediaQuery("(max-width:768px)")

  // const _
  // useEffect(()=>{
  //   console.log("documentationData",documentationData)
  // },[documentationData])
  return (
    <Box width={"100%"}>
      <Typography variant='body'  padding={1}
      sx={{
        display:"flex",
        justifyContent:"space-between",
        flexWrap:"wrap",
        width: !_540px ? "100%" : "300%",
        paddingBottom:"20px"
      }}
      >
        <Box sx={{
          overflow:"auto",
          width:!_768px ? "80%": "100%"
          }}>
          {
            documentationData ?
              <div
                dangerouslySetInnerHTML={{ __html: documentationData.documentation_details() }}
              /> :
              <Skeleton variant="rounded" animation="wave" height={'60vh'} width={'90vw'} />
          }
        </Box>
        <Grid 
          item xs={2}  
          marginBottom={"20px"} 
          paddingTop={!_768px?"0px":"25px"}
          // sx={{
          //   width:!_768px?"30%":"100%",
          //   maxWidth:!_768px?"30%":"100%",
          //   flexBasis:!_768px?"30%":"100%",
          // }}
          >
          <Stack 
            direction= {_768px && !_540px? " row":"column"}
            spacing={2}  
          >
            
            {
              documentationData == null ?
              []:
              documentationData.embeded_links.map((link) => {
                if(link === ""){
                  return null
                }
                else{
                  return (
                    <VideoPlayer link={link}  width={"100%"}/>
                  )
                }
              })
            }
          </Stack>
        </Grid>
      </Typography>
    </Box>
  )
}

export default DocumentDetailsBody