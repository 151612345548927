import * as React from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Skeleton, Typography, Card,CardActionArea, CardMedia, CardContent} from '@mui/material';
import AccessControl from "../../../../AccessControl";
import { Link } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shadows, useTheme } from '@mui/system';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { useNavigate } from 'react-router-dom';
import KpiCardBody from "./kpiCardBody";
import { tokens } from "../../../../theme";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";


export default function  KpiManagement () {
    const navigate = useNavigate()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [kpiList, setkpiList] = React.useState([]);
    const [kpiDataList, setkpiDataList] = React.useState({});
    const colors = tokens(theme.palette.mode);

    const getAllKpiList = () => {
        handleApiCallsVisualizationEngine(
            "get",
            `kpis`,
            ""
            ).then((responseData) => {
                setkpiList([...responseData[1]]);
                // getDataForEachKpi([kpiList])
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
            });
    }

    const deleteKpi = (item)=> {
        handleApiCallsVisualizationEngine(
            "post",
            `kpi/${item}/delete`,
            "",
            {}
            ).then((responseData) => {
                getAllKpiList()
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    React.useEffect(() => {
        let kpi_list = [...kpiList]
        let newData = {...kpiDataList}
        kpi_list.map((item, index) => {
            let id = item['value']
            // `kpi/${id}/data`,
            // `visualizationEngine/dashboard/kpi-graph-info?graph=1`,
            handleApiCallsVisualizationEngine(
                "get",
                `kpi/${id}/data`,
                ""
                ).then((responseData) => {
                    // console.log({...responseData[1]})
                    newData[id] = {...responseData[1]}
                    setkpiDataList({...newData});
                }).catch((error) => {
                    console.log(error);
                    dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                });
            })
            // kpi_list.promiseAllSetteled
    }, [kpiList]);

    React.useEffect(() => {
        getAllKpiList()
    }, []);

    return (
        <Box
            style={{
            //    display: "flex",
            padding: "0em 2em 7em 2em",
            overflowX: "auto",
            //    width: "100%",
            //    direction: "rtl",
            //    background: "#ef394e",
            //    boxSizing: "border-box" // <--- this line
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>KPI Management</h1>
                <AccessControl allowedPermissions={[]} >
                    <Link to="/kpi-manage/edit" className="btn btn-primary">
                        <Button  
                            variant="contained"
                            // size="small"
                            // onClick={}
                        >
                            Create KPI
                        </Button>

                    </Link>
                </AccessControl>
            </Box>
            <Box
                gridTemplateColumns = {useMediaQuery(theme.breakpoints.up('sm')) ? 'repeat(auto-fit, minmax(320px, .3fr))' : 'repeat(auto-fit, minmax(320px, .3fr))'}
                sx={{
                display: 'grid',
                columnGap: '5px',
                rowGap: 2,
                // rowGap: 'auto',
                }}
            >   
                 {kpiList.length > 0 ?
                    kpiList.map((item, index)=> (
                        <Box key={index}>
                            <Card
                                sx={{m:0.5, boxShadow: 1, height:310, width:`100%`,
                                background:colors.KpiCardsBackground, borderRadius:"10px", border:`1px solid ${colors.KpiCardsBorder}`
                            }}
                            >
                                {/* <CardActionArea component={Link} to={`/kpi-manage/edit`}> */}
                                {/* <CardMedia
                                    // sx={{borderRadius:3}}
                                    style={{borderTopLeftRadius:3, borderTopRightRadius:3}}
                                    // component="img"
                                    // height="170"
                                    // image={"https://spin.atomicobject.com/wp-content/uploads/stacked-bar-charts.jpg"}
                                    // alt="green iguana"
                                /> */}
                                <Box height="262px" pt={2.5}>
                                   {kpiDataList[item.value] !== undefined ? 
                                   <KpiCardBody data={kpiDataList[item.value]} width={380} height={252} /> 
                                   : <Skeleton variant="rectangular" height={'100%'}/>}
                                </Box>
                                <CardContent sx={{p:0.5, paddingLeft:1}}>
                                    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}>
                                        <Typography gutterBottom variant='subtitle1'component="div" fontWeight={600} noWrap={true}>
                                        {`Title : ${item.kpiName}`}
                                        </Typography>
                                        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}>
                                            <Box>
                                                <IconButton 
                                                    onClick={()=>{
                                                        navigate(`/kpi-manage/edit?kpi=${item.value}`)
                                                    }}
                                                >
                                                    <EditNoteIcon/>
                                                </IconButton>
                                            </Box>
                                            <DeleteButtonWithConfirmationPopUp mainData={kpiList} deleteitem={item.value} deleteFunc={deleteKpi}> </DeleteButtonWithConfirmationPopUp>
                                        </Box>
                                    </Box>
                                </CardContent>
                                {/* </CardActionArea>  */}
                            </Card> 
                        </Box>
                    ))
                : <></>}
            </Box>
        </Box>
    )
};

