import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';

import { UserlastLoginCheck } from '../../actions/userActions';
import { Button,Grid,Typography,Container,TextField,Box,CssBaseline,Paper } from "@mui/material";
import { Link } from "react-router-dom";
// import PasswordComponent from './passwordComponent';



export default function TempPasswordd() {

  const dispatch = useDispatch();

  const [TemporaryPasswordSectionVisible, setTemporaryPasswordSectionVisible] = useState(true);
  const [TemporaryPassword, setTemporaryPassword] = useState('');

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('rememberedEmail');
//     const storedRememberMe = localStorage.getItem('rememberMe');
//     if (storedRememberMe === 'true') {
//       setTemporaryPassword(storedEmail || '');
//     }
//   }, []);

  const submitPassword = (event) => {
    if(TemporaryPassword !== ''){
        // submit Temp password dispatch
    }
    setTemporaryPasswordSectionVisible(false)
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 16,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(../../../assets/TempPasswordd.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            pt={1}
            pb={4}
            pl={4}
            pr={4}
            minHeight={400}
          >
            <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                <Box component="img"
                    sx={{
                    height: 30,
                    // display: { xs: 'flex', md: 'none' },
                    mt:2
                    }}
                    alt="BridgeWater Logo"
                    src="../../assets/town_of_bridge.png"
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
                <Typography variant='h3'>
                    Energy Management Information System
                </Typography>
                
                <Typography variant='h3'>
                    Temporary Password
                </Typography>
                <br/>
                <Typography variant='h5'>
                    Please provide your temperory password send to your email
                </Typography>
                { 
                // TemporaryPasswordSectionVisible ?
                  <Box width="100%" pt={3}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="TemporaryPassword"
                        label="Temporary Password"
                        name="Temporary Password"
                        autoComplete="Temporary Password"
                        value={TemporaryPassword}
                        onChange={(e)=>setTemporaryPassword(e.target.value)}
                        autoFocus
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled = {true ? TemporaryPassword === '' : false}
                      onClick={submitPassword}
                      >
                      Next
                    </Button>
                  </Box>
                  
                
                }
            </Box>
          </Grid>
          <Grid container>
              <Grid item xs >
          </Grid>
        </Grid>
        </Grid>
      </Box>
    </Container>
  );
}