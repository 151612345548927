import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function InfoCard({tableData,type,height,width}) {
    // console.log("inside infoCard",tableData,type)
    // React.useEffect(()=>{
    //   console.log(height,width)
    // },[height,width])
  return (
    !tableData ?
    <Skeleton height={height} width={width}/>:
    <Box paddingLeft={"0.5em"} height={height} width={width}>
        
        <Box display={"flex"} justifyContent={"space-between"} paddingX={"1em"} gap={"20px"} flexWrap={"wrap"}>
          <Box >
            <Typography variant='h3' fontSize={Math.sqrt(height*width / 50)}>{tableData.data.infoData.xAxis}</Typography>
            <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"}>
              {
                tableData.data.data.map((xAxisVal,i) => {
                  return (
                    <Typography 
                    fontWeight={700} 
                    fontSize={Math.sqrt(height*width / (30*tableData.data.data.length))} 
                    key={i}
                    whiteSpace={"pre-wrap"}>
                      {/* (height*width)/(2000*tableData.data.data.length) */}
                      {
                        JSON.stringify(
                          xAxisVal[tableData.data.infoData.xAxis]
                        )
                      }
                    </Typography>
                  )
                })
              }

            </Box>
          </Box>
          {/* <Box display={"flex"} justifyContent={"center"}>
            {
              tableData.data.infoData.yAxis.map((yAxisHeading,i)=>{
                return (
                  <Box>
                    <Typography variant='h3' fontSize={Math.sqrt(height*width / 200)}>{yAxisHeading.label}</Typography>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"}>
                      {
                        tableData.data.infoData.yAxis.map((yAxisHeading, i) => {
                          // console.log(yAxisHeading)
                          return tableData.data.data.map((yAxisVal,k) => {
                            return <Typography 
                                    fontWeight={700} 
                                    fontSize={Math.sqrt(height*width / (60*tableData.data.data.length))} 
                                    key={k}
                                    whiteSpace={"pre-wrap"}
                                    >{yAxisVal[yAxisHeading.label]}</Typography>
                          })
                        })
                      }
                    </Box>
                  </Box>
                )
              })
            }
          </Box> */}
        </Box>
    </Box>
    // <Card sx={{ minWidth: 375, width:"100%" }}>
    //   <CardContent>
    //     <Typography variant='h2'>{tableData.title}</Typography>
    //     <Typography variant='h4'>{tableData.description}</Typography>
    //   </CardContent>
    //   <CardActions>
    //     <Button size="small">Learn More</Button>
    //   </CardActions>
    // </Card> 
  );
}

