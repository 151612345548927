import * as React from 'react';
import {Box,Button,TextField,Stack, Tooltip, IconButton, InputAdornment, Skeleton, Typography, MenuItem, Card,CardActionArea, CardMedia, CardContent} from '@mui/material';
import AccessControl from "../../../../../AccessControl";
import { Link } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shadows, useTheme } from '@mui/system';
import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { useNavigate, useLocation } from 'react-router-dom';
// import { TextField, } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomSwitch from "../../kpiManagement/kpiEdit/customSwitchIcon";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";

function isDeepEmpty(input) {
    if (!input && !(typeof input === 'boolean')) return true
    if (typeof input === 'object') {
      for (const entry of Object.values(input)) {
        if (isDeepEmpty(entry)) {
          return true
        }
      }
      return false
    } else if (typeof input === 'string') {
      if (input === ""){
        return true
      }
    }
    return false
  }


const AddInputField = ({item, config, databaseAddForm, setdatabaseAddForm, notRequiredConfigOptions, setnotRequiredConfigOptions, errorActive}) => {
    const [showPassword, setShowPassword] =  React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    React.useEffect(() => {
        if(!notRequiredConfigOptions.includes(item)){
            setnotRequiredConfigOptions([...notRequiredConfigOptions, item]) 
        }
        if(config.data_type === 'boolean'){
          let change_data = {...databaseAddForm.databaseAdapterConfiguration}
          change_data[`${item}`] = !change_data[`${item}`] ? false : true;
          setdatabaseAddForm({...databaseAddForm, databaseAdapterConfiguration : change_data});
        }
    }, []);
    // const handleMouseDownPassword = () => setShowPassword(!showPassword);
    // const AddInputField = () => {
      switch (config.data_type) {
        case 'integer':
          return (
            <TextField
              fullWidth
              id={`add_${item}`}
              label={`Enter ${config.prop_name}`}
              variant="outlined"
              name = {`${item}`}
              type='number'
            //   inputProps={{ min: 0, max: 65353 }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
                e.preventDefault()
                }
              }}
              value={databaseAddForm.databaseAdapterConfiguration[item] || ''}
              error={config.required ? errorActive ? databaseAddForm.databaseAdapterConfiguration[item] === '' || !databaseAddForm.databaseAdapterConfiguration[item] : false : false}
              onChange={e => {
                  let {name, value} = e.target
                  if(value <= 65353 && value >= 0 ) {
                    let change_join_data = {...databaseAddForm.databaseAdapterConfiguration}
                    change_join_data[name] = value;
                    setdatabaseAddForm({...databaseAddForm, databaseAdapterConfiguration : change_join_data});
                  }
              }}
            >
            </TextField>);
        case 'string':
          return (
            <TextField
              fullWidth
              id={`add_${item}`}
              label={`Enter ${config.prop_name}`}
              variant="outlined"
              name = {`${item}`}
              value={databaseAddForm.databaseAdapterConfiguration[item] || ''}
              error={config.required ? errorActive ? databaseAddForm.databaseAdapterConfiguration[item] === '' || !databaseAddForm.databaseAdapterConfiguration[item] : false : false}
              onChange={e => {
                let change_join_data = {...databaseAddForm.databaseAdapterConfiguration}
                let {name, value} = e.target
                change_join_data[name] = value;
                setdatabaseAddForm({...databaseAddForm, databaseAdapterConfiguration : change_join_data});
              }}
            >
            </TextField>
          )
        case 'password':
          return (
            <TextField
            fullWidth
            id={`add_${item}`}
            label={`Enter ${config.prop_name}`}
            name = {`${item}`}
            variant="outlined"
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            error={config.required ? errorActive ? databaseAddForm.databaseAdapterConfiguration[item] === '' || !databaseAddForm.databaseAdapterConfiguration[item] : false : false}
            onChange={e => {
                let change_join_data = {...databaseAddForm.databaseAdapterConfiguration}
                let {name, value} = e.target
                change_join_data[name] = value;
                setdatabaseAddForm({...databaseAddForm, databaseAdapterConfiguration : change_join_data});
              }}
            InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
                )
            }}
            />
          )
        case 'boolean':
          return (
            <Box
              pr={1}
              pl={2}
              sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", boxShadow: 2,alignItems: "center", border:"1px solid #b6b6b6", borderRadius: "4px"}}
              height={'50px'} 
              key={`add_${item}`}
              variant="outlined"
              name = {`${item}`}
              onClick={(e) => {
                // let {name, value} = e.target
                let change_join_data = {...databaseAddForm.databaseAdapterConfiguration}
                change_join_data[`${item}`] = !change_join_data[`${item}`];
                setdatabaseAddForm({...databaseAddForm, databaseAdapterConfiguration : change_join_data});
              }}
            >
              <Typography>{`Enter ${config.prop_name}`}</Typography>
              <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked= {databaseAddForm.databaseAdapterConfiguration[item] || false}
                // onChange={() => {
                //   if(basicFormData.advanceUsage === true){
                //     setadvanceQueryFormData({
                //       queryData: ""
                //     })
                //   }
                //   setbasicFormData({...basicFormData, advanceUsage : !basicFormData.advanceUsage})
                // }}
              ></CustomSwitch>
            
            </Box>
          );
        default:
          return <></>
      }
    }
    


export default function  KpiManagement () {
    const dispatch = useDispatch();
    const search = useLocation().search
    const dbQueryEditParams = new URLSearchParams(search)
    const navigate = useNavigate()

    const [dbAdapterList, setdbAdapterList] = React.useState([]);
    const [notRequiredConfigOptions, setnotRequiredConfigOptions] = React.useState([]);
    // const [dbAdapter, setdbAdapter] = React.useState('');
    const [databaseAddForm, setdatabaseAddForm] = React.useState({
        databaseAdapterId: '',
        databaseConnectionName: '',
        databaseAdapterConfiguration: {
        }
    })
    const [databaseAddConfig, setdatabaseAddConfig] = React.useState({})
    const [errorActive, seterrorActive] = React.useState(false);
    const [confirmDbAdapter, setconfirmDbAdapter] = React.useState(false);
    const [editMode, seteditMode] = React.useState(false);

    React.useEffect(() => {
        let db_id = dbQueryEditParams.get("db")
        if(db_id){
          seteditMode(true)
          handleApiCallsVisualizationEngine(
              "get",
              `database-connection/${db_id}`,
              ""
              ).then((responseData) => {
                  // setkpiList([...responseData[1]]);
                  let data = responseData[1]
                //   console.log({...data})
                  setdatabaseAddForm({...databaseAddForm, ...data})
                  handleApiCallsVisualizationEngine(
                    "get",
                    `database-adapter/${data.databaseAdapterId}/configuration`,
                    ""
                    ).then((responseData) => {
                        setdatabaseAddConfig({...responseData[1].databaseAdapterConfiguration});
                        seterrorActive(false)
                    }).catch((error) => {
                        // setconfirmDbAdapter(false)
                        console.log(error);
                        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                    });
                    setconfirmDbAdapter(true)
              }).catch((error) => {
                  console.log(error);
                  dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
              });
        }
      }, []);

    function getDbConfig(){
        handleApiCallsVisualizationEngine(
            "get",
            `database-adapter/${databaseAddForm.databaseAdapterId}/configuration`,
            ""
            ).then((responseData) => {
                seterrorActive(false)
                setdatabaseAddConfig({...responseData[1].databaseAdapterConfiguration});
            }).catch((error) => {
                setconfirmDbAdapter(false)
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    React.useEffect(() => {
        handleApiCallsVisualizationEngine(
            "get",
            `database-adapters`,
            ""
            ).then((responseData) => {
                setdbAdapterList([...responseData[1]]);
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
            });
    }, []);

    
    return (
    <Box
          style={{
            padding: "0em 2em 2em 2em",
          // overflowX: "auto",
          }}
      >
        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between"}}>
            <h1>Manage Database</h1>
        </Box>
        <Box style= {{display: 'flex', flexDirection: 'column', alignItems: 'center',  justifyContent: "space-between", marginLeft: "auto",}}>
            <Box
              style= {{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: "space-between", marginLeft: "auto",}}
              pt={2} width={'100%'}
            >
                {/* <Box width={'100%'} height={'100px'}> */}
                    {dbAdapterList.length > 0 ? 
                        <Box
                            gridTemplateColumns = {'repeat(2, 1fr)'}
                            sx={{
                            display: 'grid',
                            columnGap: 3,
                            rowGap: 1,
                            }}
                            width={"100%"}
                        >
                            <Box width={'100%'}  pt={2}>
                                <TextField 
                                fullWidth id="Db_name" 
                                disabled={confirmDbAdapter}
                                label="Database Name" 
                                variant="outlined" 
                                value={databaseAddForm.databaseConnectionName}
                                error={errorActive ? databaseAddForm.databaseConnectionName === '': false}
                                onChange={e => {setdatabaseAddForm({...databaseAddForm, databaseConnectionName : e.target.value})} }
                                />
                            </Box>
                            <Box width={'100%'}  pt={2}>
                                <TextField
                                fullWidth
                                id="dataBaseAdapter"
                                disabled={confirmDbAdapter}
                                select
                                label="Select Database Adapter"
                                variant="outlined"
                                value={databaseAddForm.databaseAdapterId}
                                error={errorActive ? databaseAddForm.databaseAdapterId === '': false}
                                onChange={e => {setdatabaseAddForm({...databaseAddForm, databaseAdapterId : e.target.value})}}
                                >
                                    <MenuItem key={`please_select_Database_Adapter`} value='' disabled>
                                        Please Select A Database Adapter
                                    </MenuItem>
                                    {dbAdapterList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.databaseAdapter}
                                    </MenuItem>
                                    ))}
                                        
                                </TextField>
                            </Box>
                            {confirmDbAdapter ? 
                                Object.keys(databaseAddConfig).length ? 
                                        Object.keys(databaseAddConfig).map((item, index) => (
                                        <Box  width={'100%'}  pt={2}  key={`database_manage_box_${index}`}>
                                            <AddInputField item={item} config={databaseAddConfig[item]} databaseAddForm={databaseAddForm}  setdatabaseAddForm={setdatabaseAddForm}  notRequiredConfigOptions={notRequiredConfigOptions}  setnotRequiredConfigOptions={setnotRequiredConfigOptions}  errorActive={errorActive} />
                                        </Box>
                                        ))
                                    : <Box width={'203%'} height={'300px'}> <Skeleton variant="rectangular" height={'300px'}/> </Box>
                                : <></>
                            }
                        </Box>
                    :
                    <Box width={'100%'} height={'50px'}>
                        <Skeleton variant="rectangular" height={'50px'}/>
                    </Box>
                    }
                {/* </Box>  */}
                
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: "space-between", marginLeft: "auto",}}>
                <Box width={'100%'} pt={3} mr={1}
                    style= {{display: 'flex', alignItems: 'center',  justifyContent: "flex-end"}}
                >
                    <Link to="/db-manage" className="btn btn-primary">
                        <Button  
                            variant="contained"
                            // sx={{backgroundColor:"#656565"}}
                        >
                            Cancel
                        </Button>

                    </Link>
                </Box>
                
                {confirmDbAdapter ? 
                    editMode ?
                    <Box width={'100%'} pt={3}
                    style= {{display: 'flex', alignItems: 'center',  justifyContent: "flex-end"}}
                    >
                        <Button 
                        variant="contained"
                        disabled={!confirmDbAdapter}

                        onClick={() => {
                            let db_id = dbQueryEditParams.get("db")

                            let input = {...databaseAddForm.databaseAdapterConfiguration}
                            notRequiredConfigOptions.map((item, index) => {
                                delete input[item]
                            })
                            // console.log(notRequiredConfigOptions, '\nsdvbdsugc\n', databaseAddForm)
                            if(!isDeepEmpty(input) && Object.keys(input).length > 0){
                                // setconfirmDbAdapter(true)
                                // console.log({...databaseAddForm})
                                // database-adapter/${dbAdapter}/configuration
                                handleApiCallsVisualizationEngine(
                                    "post",
                                    `database-connection/${db_id}/update`,
                                    "",
                                    {...databaseAddForm}
                                    ).then((responseData) => {
                                        dispatch(showGlobalSnackbar('Database Connection Updated Successfully.', "success"));
                                        navigate('/db-manage');
                                    }).catch((error) => {
                                        // setconfirmDbAdapter(false)
                                        console.log(error);
                                        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                                    }
                                );
                            } else {
                                seterrorActive(true)
                            }
                        }}
                        >
                        UPDATE
                        </Button>
                    </Box>
                    :
                    <Box width={'100%'} pt={3}
                    style= {{display: 'flex', alignItems: 'center',  justifyContent: "flex-end"}}
                    >
                        <Button 
                        variant="contained"
                        disabled={!confirmDbAdapter}

                        onClick={() => {
                            let input = {...databaseAddForm.databaseAdapterConfiguration}
                            notRequiredConfigOptions.map((item, index) => {
                                delete input[item]
                            })
                            // console.log(notRequiredConfigOptions, '\nsdvbdsugc\n', databaseAddForm)
                            if(!isDeepEmpty(input) && Object.keys(input).length > 0){
                                // setconfirmDbAdapter(true)
                                // console.log({...databaseAddForm})
                                // database-adapter/${dbAdapter}/configuration
                                handleApiCallsVisualizationEngine(
                                    "post",
                                    `database-connection`,
                                    "",
                                    {...databaseAddForm}
                                    ).then((responseData) => {
                                        dispatch(showGlobalSnackbar('Database Connection Created Successfully.', "success"));
                                        navigate('/db-manage');
                                    }).catch((error) => {
                                        // setconfirmDbAdapter(false)
                                        console.log(error);
                                        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                                    }
                                );
                            } else {
                                seterrorActive(true)
                            }
                        }}
                        >
                        SUBMIT
                        </Button>
                    </Box>
                    : 
                    <Box width={'100%'} pt={3}
                    style= {{display: 'flex', alignItems: 'center',  justifyContent: "flex-end"}}
                    >
                        <Button 
                        variant="contained"
                        disabled={confirmDbAdapter}
                        onClick={() => {
                            if(databaseAddForm.databaseAdapterId && databaseAddForm.databaseConnectionName){
                                setconfirmDbAdapter(true)
                                // database-adapter/${dbAdapter}/configuration
                                getDbConfig()
                            } else {
                                seterrorActive(true)
                            }
                        }}
                        >
                        NEXT
                        </Button>
                    </Box>
                    // <Skeleton variant="rounded"    height={'300px'}/>         
                }
            </Box>
        </Box>
    </Box>
    )
};

