import React from "react";
import ApexCharts from "react-apexcharts";
 
const HeatmapChart = ({data,height,width}) => {
   

    console.log(data)
      function rgbToHex(r, g, b) {
        // Convert individual RGB values to hex and concatenate them
        return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
      }
      const checkrgb=(color)=>{
        const rgbRegex = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/;
        let hexcode
        const match = color.match(rgbRegex);
        if (match) {
            const red = parseInt(match[1]);
            const green = parseInt(match[2]);
            const blue = parseInt(match[3]);
            return rgbToHex(red,green,blue)
        } else {
            return color
        }
    }

    let legenddata=[]
    function generatecolorseries(){
        let colorseries=[]
        console.log(data.data.infoData.yAxis)
        data.data.infoData.yAxis.forEach((e)=>{
          let hexcolor=checkrgb(e.color)
            colorseries.push(hexcolor)
            legenddata.push({
              color:hexcolor,
              name:e.label
            })
        })
        return colorseries
    }
 
    
      function generateseries2() {
        let finalseries=[]
        data.data.infoData.yAxis.forEach((e)=>{
            let dataa=[]
            // console.log(e)
            data.data.data.forEach((entry,index)=>{
                //   console.log(entry)
                //   console.log({
                //     x:index+e.label,
                //     y:entry[`${e.label}`] 
                    
                // })
                dataa.push({
                    x:index+e.label,
                    y:entry[`${e.label}`] 
                    
                })
            })
            // console.log(dataa);
            finalseries.push({
                name:e.label,
                data:[...dataa]
            })
         
           
        })
        return finalseries
      }
 
let series =generateseries2()
console.log(series)
let colorseries=generatecolorseries()
   
     let chartOptions={
       
        xaxis:{
            type:"category",
            categories:data.data.data.map((e)=>(e[`${data.data.infoData.xAxis}`])),
            title:{
                text:data.data.infoData.xAxis
            },
        },
        chart: {
            toolbar: { show:false },
            type: "heatmap"
          },
          colors: colorseries,
          plotOptions: {
              heatmap: {
               
               colorScale:{
                ranges:legenddata
              }
              }
            },
            dataLabels: {
              enabled: false,
              style:{
                colors:["#000"]
              }
            },
            stroke: {
              width: 1
            },  
            legend:{
                show:true
            }  
    }
   
    console.log(series);
   
 
  return (
    <ApexCharts options={chartOptions} series={series ?? []  }  type="heatmap" height={height-14} width={width} />
  );
};
 
export default HeatmapChart;