import React from 'react'
import {Box,IconButton,Typography,useTheme, Tooltip} from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 800,
      fontSize: '13px'
    },
  });


function DashboardCardFooter({graphData, width, children}) {
  return (
    <Box  sx={{display:'flex',flexDirection: 'row',alignItems:"center",justifyContent:"space-between", background:'00FFFFFF'}}
        style={{borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}} 
    >
        <Box 
            sx={{width: (width*0.6)}}
            alignItems="Center"
            ml={1}
        >   {
                graphData === undefined ? 
                    <Skeleton width="100%">
                        <Typography>.</Typography>
                    </Skeleton>
                :
                <CustomWidthTooltip title={`${graphData.description}`} arrow>
                    <Typography variant="subtitle2" noWrap={true}>
                        {`Description: ${graphData.description}`}
                    </Typography>
                </CustomWidthTooltip>
            }
            
        </Box>
        <Box className="Dashborad-like-dislike"
            sx={{width: (width*0.4) , display:'flex', alignItems:"center",flexDirection:'row',justifyContent:"end",}}
        >   
            {
                graphData === undefined ? 
                    <Skeleton width="50%">
                        <Typography>.</Typography>
                    </Skeleton>
                :
                (
                    <>
                        {/* <IconButton aria-label="Like" size="small" p={0}>
                            <ThumbUpOffAltIcon fontSize="small"/>
                        </IconButton>
                        <IconButton aria-label="dislike" size="small"  p={0}>
                            <ThumbDownOffAltIcon fontSize="small"/>
                        </IconButton> */}
                        {children}
                    </>
                )

            }
        </Box>
    </Box>
  )
}

export default DashboardCardFooter
