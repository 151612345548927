import * as React from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Skeleton, Typography, Card,CardActionArea, CardMedia, CardContent} from '@mui/material';
import AccessControl from "../../../../AccessControl";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shadows, useTheme } from '@mui/system';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { tooltipClasses } from '@mui/material/Tooltip';
import DashboardModal from './dashboardModal' 
import KpiAddDeleteModal from './kpiAddDeleteModal' 
import DashboardAssignModal from './dashboardAssignModal' 
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 800,
      fontSize: '13px'
    },
  });

export default function  DashboardManagement1 () {
    const navigate = useNavigate()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [dashboardList, setdashboardList] = React.useState([]);
    const [openModal, setopenModal] = React.useState(false);
    const [openKpiModal, setopenKpiModal] = React.useState(false);
    const [openDashboardAsignModal, setopenDashboardAsignModal] = React.useState(false);
    const [dashboardId, setdashboardId] = React.useState(null);

    function getAllDashboardList(){
        handleApiCallsVisualizationEngine(
            "get",
            `dashboards`,
            ""
            ).then((responseData) => {
                console.log(responseData[1])
                setdashboardList([...responseData[1]]);
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
            });
    }

    const deleteDashboard = (id) => { 
        handleApiCallsVisualizationEngine(
            "post",
            `dashboard/${id}/delete`,
            "",
            {}
            ).then((responseData) => {
                dispatch(showGlobalSnackbar('Dashboard Deleted Successfully.', "success"));
                getAllDashboardList();
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    React.useEffect(() => {
        getAllDashboardList()
    }, []);


    return (
        <Box
            style={{
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>Dashboard Management</h1>
                <AccessControl allowedPermissions={[]} >
                    <Button  
                        variant="contained"
                        onClick={() => {
                            setopenModal(true)
                        }}
                    >
                        Add Dashboard
                    </Button>

                </AccessControl>
            </Box>
            {/* <DeletePopUp open={openDeleteModal} setOpen={setopenDeleteModal} deleteStatus={deleteStatus} setdeleteStatus={setdeleteStatus} deleteFunc={deleteDashboard}/> */}
            <DashboardModal open={openModal} setOpen={setopenModal} setdashboardList={setdashboardList}/>
            <KpiAddDeleteModal open={openKpiModal} setOpen={setopenKpiModal} dashboardId={dashboardId} setdashboardId={setdashboardId}/>
            <DashboardAssignModal open={openDashboardAsignModal} setOpen={setopenDashboardAsignModal} dashboardId={dashboardId} setdashboardId={setdashboardId}/>
            <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between", minWidth:"300px"}}
                pb={2}
            >   
                 {dashboardList.length > 0 ?
                    dashboardList.map((item, index)=> {
                        if(item.dashboardName === "Energy Insights"){
                            return (
                                <Box key={index} sx={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", border: `2px solid ${theme.palette.divider}`,
                                    background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
                                    borderRadius: '10px',
                                    height: { md: '50px' }
                                }}
                                    width={'100%'} mb={2} pl={3} pr={3}

                                >
                                    <CustomWidthTooltip title={`${item.dashboardName}`} arrow>
                                        <Typography noWrap variant='subtitle1' component="div" fontWeight={600} sx={{ fontSize: "18px" }} width={'29%'}>
                                            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', marginRight: '10px' }} />{`${item.dashboardName}`}
                                        </Typography>
                                    </CustomWidthTooltip>
                                    <CustomWidthTooltip title={`${item.dashboardDescription}`} arrow>
                                        <Typography noWrap variant="body1" fontWeight={300} width={'50%'} >
                                            {`${item.dashboardDescription}`}
                                        </Typography>
                                    </CustomWidthTooltip>
                                    <Box width={'15%'} sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column', sm: 'column', md: 'row' }, alignItems: 'center', justifyContent: "flex-end", }}>
                                        <IconButton
                                            onClick={() => {
                                                navigate(`/manage-dashboard/preview?dashboard=${item.value}`)
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                // navigate(`/db-manage/edit?db=${item.value}`)
                                                setdashboardId([item.value, item.dashboardName]);
                                                setopenKpiModal(true);
                                            }}
                                        >
                                            <EditNoteIcon />
                                        </IconButton>                                        
                                    </Box>
                                </Box>
                            )
                        }
                        else{
                            return (
                                <Box key={index} sx={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", border: `2px solid ${theme.palette.divider}`,
                                    background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
                                    borderRadius: '10px',
                                    height: { md: '50px' }
                                }}
                                    width={'100%'} mb={2} pl={3} pr={3}
    
                                >
                                    <CustomWidthTooltip title={`${item.dashboardName}`} arrow>
                                        <Typography noWrap variant='subtitle1' component="div" fontWeight={600} sx={{ fontSize: "18px" }} width={'29%'}>
                                            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', marginRight: '10px' }} />{`${item.dashboardName}`}
                                        </Typography>
                                    </CustomWidthTooltip>
                                    <CustomWidthTooltip title={`${item.dashboardDescription}`} arrow>
                                        <Typography noWrap variant="body1" fontWeight={300} width={'50%'} >
                                            {`${item.dashboardDescription}`}
                                        </Typography>
                                    </CustomWidthTooltip>
                                    <Box width={'15%'} sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column', sm: 'column', md: 'row' }, alignItems: 'center', justifyContent: "flex-end", }}>
                                        <IconButton
                                            onClick={() => {
                                                navigate(`/manage-dashboard/preview?dashboard=${item.value}`)
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                // navigate(`/db-manage/edit?db=${item.value}`)
                                                setdashboardId([item.value, item.dashboardName]);
                                                setopenDashboardAsignModal(true);
                                            }}
                                        >
                                            <GroupAddOutlinedIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                // navigate(`/db-manage/edit?db=${item.value}`)
                                                setdashboardId([item.value, item.dashboardName]);
                                                setopenKpiModal(true);
                                            }}
                                        >
                                            <EditNoteIcon />
                                        </IconButton>
                                        {/* <IconButton 
                                        onClick={()=>{ 
                                            setdeleteStatus(deleteStatus => item.value)
                                            // if (alert()){
                                            //     deleteDashboard(item.value)
                                            // }
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton> */}
                                        <DeleteButtonWithConfirmationPopUp mainData={dashboardList} deleteitem={item.value} deleteFunc={deleteDashboard}> </DeleteButtonWithConfirmationPopUp>
                                    </Box>
                                </Box>
                            )
                        }
                    })
                : <></>}
            </Box>
        </Box>
    )
};

