import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Box,Typography,Skeleton} from "@mui/material";
import DragAndDropLayout from './dragAndDropLayout';
import { useDispatch, useSelector } from 'react-redux';
import { GetAdminDashboardPreviewLayoutData } from '../../../../../actions/dashboardPreviewActions';

export default function DashboardPreview() {
  const search = useLocation().search
  const dashboardQueryEditParams = new URLSearchParams(search)
  const dashboard_id = dashboardQueryEditParams.get("dashboard")

  const dispatch = useDispatch()

  const adminPreviewDashboardLayout = useSelector(state => state.adminPreviewDashboardLayout)
  const {error: layoutError, loading: layoutLoading, dashboardPreviewLayout, dashboardPreviewCategoryList} = adminPreviewDashboardLayout

  const hasLayoutData = Object.keys(dashboardPreviewLayout).length > 0 && Object.values(dashboardPreviewLayout).some(layout => layout.length > 0);

  useEffect(()=>{
    if(dashboard_id){
      dispatch(GetAdminDashboardPreviewLayoutData(dashboard_id));
    }
  }, [dispatch, dashboard_id])

    return (
        <Box
            style={{
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            }}
        >
        <Box
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
        >
          <Box sx={{ display: 'flex',lineheight:0, flexDirection: 'column',margin: "1em 0em 1em 0em"}}>
              <Typography component="div" variant="h3">
                Welcome to Dashboard
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                  
              </Typography>
          </Box>
        </Box>
          {
            layoutLoading  ? <Skeleton variant="rounded" animation="wave" sx={{borderRadius:'10px'}} width={'100%'} height={'80vh'} />
            : layoutError ? 
                <Box
                  style={{
                  height:'60vh',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <Typography>Unable to Collect Layout Data</Typography>
                </Box>
            : (
              hasLayoutData ? (
                  <Box sx={{ borderRadius:'10px'}}>
                    <DragAndDropLayout dashboard_id={dashboard_id} userDefineLayout={dashboardPreviewLayout} categoryList={dashboardPreviewCategoryList}/>
                  </Box>
                ) : (
                  <Typography>No Layout Data Available</Typography>
                )
            )
          }
        </Box>
    )
}
