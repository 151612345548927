import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
// import { lightThemeColor, darkThemeColor } from "./color.js";
// import {lightThemeColor, darkThemeColor} from "./newColor.js";
import {lightThemeColor, darkThemeColor} from "./themeColors.js";

// color design tokens exportthemeSettingsuse
export const tokens = (mode) => ({
  ...(mode === "dark" ? darkThemeColor : lightThemeColor),
  // ...(mode === "dark" ? darkThemeColor : mode === "light" ? lightThemeColor : customThemeColor),
});


// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  if(mode === 'light'){
    mode = "light";
  }
  else{
    mode = "dark"
  }
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              // main: colors.primary[100],
              main: colors.primary,
            },
            // secondary: {
            //   main: colors.greenAccent[500],
            // },
            // neutral: {
            //   dark: colors.grey[700],
            //   main: colors.grey[500],
            //   light: colors.grey[100],
            // },
            background: {
              // default: colors.primary[900],
              default: colors.background,
            },
          }
        : {
            // palette values for light mode
            primary: {
              // main: colors.primary[100],
              main: colors.primary,
            },
            // secondary: {
            //   main: colors.greenAccent[500],
            // },
            // neutral: {
            //   dark: colors.grey[700],
            //   main: colors.grey[500],
            //   light: colors.grey[100],
            // },
            background: {
              // default: colors.primary[900],
              default: colors.background,
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};



// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});


export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
        // setMode(themeName),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
