import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { handleApiCallsVisualizationEngine } from '../../../../../../actions/actionHelper';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from '../../../../../../actions/globalSnackbarActions';


export default function DateTimeSelect({kpiMappingFormData, setkpiMappingFormData,itemName,index,input_item}) {
  const [selectedDateTimeFormat, setSelectedDateTimeFormat] = React.useState('');
  console.log("kpiMappingFormData",kpiMappingFormData)
  console.log("itemName,index,input_item",itemName,index,input_item)
  const dispatch = useDispatch()
  const [DateTimeFormatList,setDateTimeFormatList] = React.useState([])
  
  React.useEffect(async ()=>{
    try {
      const SupportedDateTimeResp = await handleApiCallsVisualizationEngine("get","/datetime","")
    //   console.log("SupportedDateTimeResp",SupportedDateTimeResp)
      if(SupportedDateTimeResp[2]!==""){
        throw new Error(SupportedDateTimeResp[2])
      }
      setDateTimeFormatList(SupportedDateTimeResp[1])
    } catch (error) {
      await dispatch(showGlobalSnackbar(error.message,"error"))
    }
  },[])
  const handleChange = (event) => {
    console.log(event.target.value)
    setSelectedDateTimeFormat(event.target.value);
    let newItemName= [...kpiMappingFormData[itemName]]
    newItemName[index]["dataColumnSpecifications"] = { format: event.target.value }
    newItemName[index]["dataColumnType"] = "TIMESTAMP"
    setkpiMappingFormData({
      ...kpiMappingFormData,
      [itemName] : newItemName
    })
    console.log(newItemName)
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select your desired DateTime</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={kpiMappingFormData[itemName][index]?.dataColumnSpecifications?.format||""}
          label="Select your desired DateTime"
          onChange={handleChange}
        >
          {
            DateTimeFormatList.map((elm)=>{ return (
                <MenuItem value={elm["datetimeFormat"]}>{elm["datetimeFormat"]}</MenuItem>
            )})
          }
        </Select>
      </FormControl>
    </Box>
  );
}
