import { DISLIKE_KPI, REACT_KPI, SET_KPI_UNIQUE_KEYS_OBJ } from "../constants/reactionConstants";

const initialState = {
    allKpiReactions:{}
}

export function reactionReducer(state=initialState,action){
    switch (action.type) {
        case SET_KPI_UNIQUE_KEYS_OBJ :
            return {
                ...state,
                allKpiReactions:action.payload
            }

        case REACT_KPI:
            
            return {
                ...state,
                allKpiReactions:action.payload
            };
        // case DISLIKE_KPI: 
        //     return {
        //         ...state,
        //         allKpiReactions:action.payload
        //     }
        default:
            return state
    }
}