import axios from "axios";
import {
  USER_LAST_LOGIN_REQUEST,
  USER_LAST_LOGIN_SUCCESS,
  USER_LAST_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_SUCCESS,
  IDENTIFY_USER_RESP,
  SET_USER_PASSWORD_RESP,
  LOCAL_USER_CREDENTIALS,
  AUTHENTICATION_TYPE
} from "../constants/userConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { handleApiCalls } from "./actionHelper";


export const UserlastLoginCheck = (UserData,setemailSectionVisible,navigate,setemailEnabled) => async (dispatch) => {

  dispatch({ type: USER_LAST_LOGIN_REQUEST });
  setemailEnabled(false)
  try {
    // let accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
    const config = {
      headers: {
        "content-type": "application/json",
        tenant: "bridgewater",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    const identifyUserResp = await axios.post(
      `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/login/identify-user`,
      { username: UserData },
      config
    )
    // console.log("identifyUserResp", identifyUserResp)
    await dispatch({
      type: IDENTIFY_USER_RESP,
      payload: identifyUserResp.data
    })

    await setemailSectionVisible(false)
    await dispatch({
      type: USER_LAST_LOGIN_SUCCESS,
      payload: typeof identifyUserResp.data.result == "object" ? !identifyUserResp.data.result.first_login : !identifyUserResp.data.result.first_login
      //true // set to false for 1st time login ,
    });

    // if(identifyUserResp.data.result.is_locked[0]){
    //   await navigate("/Account-Locked")
    // }
  } catch (error) {
    
    // console.log(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/login/identify-user`)
    // console.log(error)
    await setemailEnabled(true)
    
    await dispatch({
      type: USER_LAST_LOGIN_FAIL,
      payload: error,
    });

    if(error.message === 'Network Error'){
      await dispatch(showGlobalSnackbar(error.message,"error"))
    }
    else{ 
      if(error.response.data.result.is_locked[0]){
        return await  navigate(`/Account-Locked?lockedUntil=${error.response.data.result.is_locked[1]}`)
      }
     return await dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"))
    }
    
  }
};


export const Userlogin = (UserData) => async (dispatch) => {
  try {

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: UserData,
    });

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error,
    });
  }
};

export const UserlogOut = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
  } catch (error) {
    console.log(error)
  }
};


export const UserNewPasswordSet = (userData, navigate,setUser,setLoginEnbledFlag) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
        tenant: "bridgewater",

      },
    };
    let setPasswordResp = await axios.post(
      `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/login/set-user-password`,
      userData,
      config
    ).catch((err) => {
      throw err
    })


    await dispatch({
      type: LOCAL_USER_CREDENTIALS,
      payload: userData
    })
    // userData={ username: email.trim(), new_password: password, temporary_password: tempPassword }
    console.log("setPasswordResp", setPasswordResp)
    await dispatch({type:AUTHENTICATION_TYPE,payload:setPasswordResp.data.result.mfa_enabled[1]})

    await dispatch({
      type: SET_USER_PASSWORD_RESP,
      payload: setPasswordResp.data
    })
    await dispatch(showGlobalSnackbar("Password Set Success", "success"));
    
    // 1st time login
    if (!setPasswordResp.data.result.mfa_enabled[0] && setPasswordResp.data.result.set_password) {
      console.log("/userData",userData)
      // return await navigate("/")
      return await dispatch(verifyUser(userData,navigate,setUser,setLoginEnbledFlag))
    }
    if (setPasswordResp.data.result.mfa_enabled[0] && setPasswordResp.data.result.mfa_enabled[1] == "authenticator") {
      // console.log("/Authenticator")
      return await navigate("/QRCode-Authentication")
    }
    if (setPasswordResp.data.result.mfa_enabled[0] && setPasswordResp.data.result.mfa_enabled[1] !== "authenticator") {
      // console.log("/OTP")
      return await navigate("/OTP-Authentication")
    }

    //navigate to mfa or otp
  } catch (error) {
    console.log(error);
    await dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"))

    // for going to back to the state of page with email only
    // reload
    // return await navigate(0)
  }
};



export function SendUserOTP(OTPWithCred, navigate, setUser,setSendOTPFlag) {
  return async (dispatch) => {
    const config = {
      headers: {
        "content-type": "application/json",
        tenant: "bridgewater",

      },
    };
    try {
      let OTPResp = await axios.post(
        `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/login/verify-otp`,
        OTPWithCred,
        config
      ).catch((err) => {
        throw err
      })

      // console.log("OTPResp", OTPResp)
      if(!OTPResp.data.result.is_otp_valid){ throw OTPResp.data["error-message"]}

      await dispatch(
        showGlobalSnackbar(
          "Login Succesfull",
          "success"
        )
      )

      let userData = {
        user_email: OTPResp.data.result.auth_data.user_email,
        permission: OTPResp.data.result.auth_data.permission,
        role: OTPResp.data.result.auth_data.role,
        chatbot_token: OTPResp.data.result.auth_data.chatbot_token
      }
      await dispatch(Userlogin(userData))

      // console.log("userData", userData)
      // console.log("OTPResp.data.result", OTPResp.data.result)

      sessionStorage.setItem("authTokens", JSON.stringify(OTPResp.data.result.auth_data.accessToken));
      sessionStorage.setItem("user", JSON.stringify(userData));

      // console.log("AuthTokens SS", sessionStorage.getItem("authTokens"))
      // console.log("userData SS", sessionStorage.getItem("user"))

      await setUser(sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null)

      await navigate("/");
      // 

      // console.log("AuthTokens after navigating", sessionStorage.getItem("authTokens"))
      // console.log("userData after navigating", sessionStorage.getItem("user"))

      // userData['user_email'] = data.result.user_email;
      // userData["permission"] = data.result.permission;
      // userData["role"] = data.result.role;

      //Actions login process
      // dispatch(Userlogin(userData));
      // sessionStorage.setItem("authTokens",JSON.stringify(data.result.accessToken));
      // sessionStorage.setItem("user", JSON.stringify(userData));
      // navigate("/");


      //   {
      //     "result": {
      //         "is_otp_valid": true,
      //         "set_password": true,
      //         "auth_data": {
      //             "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNywidG9rZW5fdHlwZSI6ImFjY2VzcyIsImlhdCI6MTcwMjk3MTcwOCwiZXhwIjoxNzM0NTI5MzA4LCJhdWQiOiJUT0IiLCJpc3MiOiJCQUFSVGVjaCJ9.hGrjfVRmGknf3L_ZCnChKq_ygsCJe0lnQY0prX8pTgw",
      //             "user_email": "oishik.sinha@baar.ai",
      //             "role": "Consumer",
      //             "permission": [
      //                 "approveUser",
      //                 "viewRoles",
      //                 "modifyRole",
      //                 "deleteRole"
      //             ]
      //         }
      //     },
      //     "error-message": ""
      // }



    } catch (error) {
      console.log("Error",error)
      await setSendOTPFlag(false)
      if(typeof error === "string"){
        await dispatch(
          showGlobalSnackbar(
            error,
            "error"
          )
        )
      }
      else{
        await dispatch(
          showGlobalSnackbar(
            error.message,
            "error"
          )
        )
      }
    }
  }
}


export function verifyUser(userData, navigate, setUser,setLoginEnbledFlag) {
  return async (dispatch, getState) => {
    const config = {
      headers: {
        "content-type": "application/json",
        tenant: "bridgewater",

      },
    };
    setLoginEnbledFlag(false)
    try {
      const verifyUserResp = await axios.post(
        `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/login/verify-password`,
        {
          username:userData.username,
          password:userData.new_password
        },
        config
      ).catch((err) => {
        throw err
      })

      // console.log("verifyUserResp", verifyUserResp)
      await dispatch({
        type: LOCAL_USER_CREDENTIALS,
        payload: userData
      })

      await dispatch({
        type:AUTHENTICATION_TYPE,
        payload:verifyUserResp.data.result.mfa_enabled[1]
      })

      // await dispatch(showGlobalSnackbar("Login successfull", "success"))

      // const {LOCAL_USER_CREDENTIALS}=await getState().userReducer2

      // console.log("LOCAL_USER_CREDENTIALS",LOCAL_USER_CREDENTIALS)

      // console.log("verifyUserResp.data.result.is_password_valid && verifyUserResp.data.result.mfa_enabled[0])")
      // console.log(verifyUserResp.data.result.is_password_valid && verifyUserResp.data.result.mfa_enabled[0])
      console.log(verifyUserResp.data , verifyUserResp.data)

      // for admin and consumer
      if (verifyUserResp.data.result.is_password_valid && verifyUserResp.data.result.mfa_enabled[0]) {
        // console.log("/OTP-Authentication")
        return await navigate("/OTP-Authentication")
      }

      // for super admin
      if(verifyUserResp.data.result.is_password_valid && !verifyUserResp.data.result.mfa_enabled[0]){
        let userData = {
          user_email: verifyUserResp.data.result.auth_data.user_email,
          permission: verifyUserResp.data.result.auth_data.permission,
          role: verifyUserResp.data.result.auth_data.role,
          chatbot_token:verifyUserResp.data.result.auth_data.chatbot_token

        }
        await dispatch(Userlogin(userData))
  
        // console.log("userData", userData)
        // console.log("authToken", verifyUserResp.data.result.auth_data.accessToken)
  
        sessionStorage.setItem("authTokens", JSON.stringify(verifyUserResp.data.result.auth_data.accessToken));
        sessionStorage.setItem("user", JSON.stringify(userData));
  
        // console.log("AuthTokens SS", sessionStorage.getItem("authTokens"))
        // console.log("userData SS", sessionStorage.getItem("user"))
  
        await setUser(sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null)
  
        return await navigate("/");
      }

      if(verifyUserResp.data.result.is_password_valid){
        throw "Password invalid please try again"
      }


    } catch (error) {
      console.log(error)
      setLoginEnbledFlag(true)
      if(error.response.data.result.is_locked[0]){
        return await navigate(`/Account-Locked?lockedUntil=${error.response.data.result.is_locked[1]}`)
      }

      return await dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"))
    }
  }
}