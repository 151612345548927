import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent,IconButton, Skeleton, Typography,TextField,MenuItem } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { MuiColorInput } from 'mui-color-input'
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Slide from '@mui/material/Slide';
import DateTimeSelect from './dataTimeSelect';


const AddInputField = ({DataTables,itemName, graphConfig, kpiMappingFormData, setkpiMappingFormData, selectedTableColumns, errorActive, input_item, index, AgrFunction, input_index}) => {
  console.log("DataTables",DataTables)
  // React.useEffect(()=>{
  //   if(kpiMappingFormData){
  //     console.log(
  //       NestedObjParser(
  //         kpiMappingFormData[itemName][index][input_item],
  //         DataTables)
  //     )
  //   }
  // },[kpiMappingFormData])
  switch (input_item) {
    case 'dataColumn':
      console.log("kpiMappingFormData[itemName][index][input_item]",kpiMappingFormData[itemName][index][input_item])
      return (
        <Box display={"flex"} justifyContent={"space-between"} gap={"10px"} flexDirection={"column"}>
          <TextField
            fullWidth
            id={`add_${itemName}_dataColumn_${index}`}
            select
            label="Select A Column"
            variant="outlined"
            name='dataColumn'
            value={kpiMappingFormData[itemName][index][input_item] || ''}
            error={errorActive ? kpiMappingFormData[itemName][index][input_item] === '' : false}
            onChange={e => {
              let change_join_data = { ...kpiMappingFormData }
              let { name, value } = e.target
              change_join_data[itemName][index][input_item] = value;
              setkpiMappingFormData({ ...kpiMappingFormData, ...change_join_data });
            }}
          >
            <MenuItem key={`add_${itemName}_please_select_dataColumn_${index}`} value='' disabled>
              Please Select A Column
            </MenuItem>
            {selectedTableColumns.map((option) => (
              <MenuItem key={`add_${itemName}_dataColumn_${index}_${option}`} value={option}>
                {option.replace(/["`']/g, '').replace(/[\[\]]/g, '')}
              </MenuItem>
            ))}
          </TextField>
          { 
            NestedObjParser(
              kpiMappingFormData[itemName][index][input_item],
              DataTables
            )?.dataType === "TIMESTAMP" ?
            <DateTimeSelect 
              kpiMappingFormData={kpiMappingFormData} 
              setkpiMappingFormData={setkpiMappingFormData} 
              itemName={itemName}
              index={index}
              input_item={input_item}
            />:
            null
          }
        </Box>
        );
    case 'agrFunction':
      return (
        <TextField
          fullWidth
          id={`add_${itemName}_agrFunction_${index}`}
          select
          label="Select A Aggregate Function (Optional)"
          variant="outlined"
          name = 'agrFunction'
          value={kpiMappingFormData[itemName][index][input_item] || ' '}
          error={errorActive ? kpiMappingFormData[itemName][index][input_item] === '': false}
          onChange={e => {
            let change_join_data = {...kpiMappingFormData}
            let {name, value} = e.target
            change_join_data[itemName][index][input_item] = value;
            // console.log('change_join_data')
            // console.log(change_join_data)
            setkpiMappingFormData({...kpiMappingFormData, ...change_join_data});
          }}
        >
            <MenuItem key={`add_${itemName}_please_select_agrFunction_${index}`} value=' ' disabled>
                Please Select A Aggregate Function
            </MenuItem>
            {AgrFunction.map((option) => (
            <MenuItem key={`add_${itemName}_agrFunction_${index}_${option.value}`} value={option.value}>
                {option.label}
            </MenuItem>
            ))}
        </TextField>
      );
    case 'color':
      return (
        <MuiColorInput
          fullWidth
          key={`add_${itemName}_${input_item}_${index}_${input_index}`}
          // label={`Enter ${input_item}`}
          // variant="outlined"
          name = {`${input_item}`}
          // value={'#000'}
          value={kpiMappingFormData[itemName][index][input_item] || ''}
          error={errorActive ? kpiMappingFormData[itemName][index][input_item] === '': false}
          onChange={(color) => {
            let change_join_data = {...kpiMappingFormData}
            // let {name, value} = e.target
            change_join_data[itemName][index][input_item] = color;
            setkpiMappingFormData({...kpiMappingFormData, ...change_join_data});
          }}
        >
        </MuiColorInput>)
    case 'label':
      return (
        <TextField
          fullWidth
          id={`add_${itemName}_${input_item}_${index}_${input_index}`}
          label={`Enter ${input_item}`}
          variant="outlined"
          name = {`${input_item}`}
          value={kpiMappingFormData[itemName][index][input_item] || ''}
          error={errorActive ? kpiMappingFormData[itemName][index][input_item] === '': false}
          onChange={e => {
            let change_join_data = {...kpiMappingFormData}
            let {name, value} = e.target
            change_join_data[itemName][index][input_item] = value;
            setkpiMappingFormData({...kpiMappingFormData, ...change_join_data});
          }}
        >
        </TextField>
      )
    default:
      return <></>
  }
}

function NestedObjParser (nestedKeyString,nestedObj){
  // console.log("nestedKeyString",nestedKeyString)
  // console.log("nestedObj",nestedObj)
  // console.log("nestedKeyString",nestedKeyString.split(/\.(?=[^\.]+$)/))
  if(nestedObj){
    return (
      nestedKeyString
        .split(/\.(?=[^\.]+$)/)
        .reduce(
          (o, i) => {
            return o[i]
          }, nestedObj
        )
    )
  }
  else{
    return nestedObj
  }
}


function AddDimension({DataTables,itemName, graphConfig, kpiMappingFormData, setkpiMappingFormData, selectedTableColumns, errorActive}){
    const handleRemoveDataItem = (index, keyName) => {
        let newObj={}
        const allItems = [...kpiMappingFormData[keyName]];
        allItems.splice(index, 1);
        newObj[keyName]=[...allItems]
        setkpiMappingFormData({...kpiMappingFormData, ...newObj});
    };

    const AgrFunction = [
        {
          value: 'MAX',
          label: 'MAX',
        },
        {
          value: 'MIN',
          label: 'MIN',
        },
        {
          value: 'COUNT',
          label: 'COUNT',
        },
        {
          value: 'SUM',
          label: 'SUM',
        },
        {
          value: 'AVG',
          label: 'AVG',
        },
      ]

      // console.log("DataTables",DataTables)
      // console.log("selectedTableColumns",selectedTableColumns)

    return (
    Object.keys(kpiMappingFormData).length > 0 ?
      <Box p={2}>
        {/* {Array(graphConfig[itemName].count).fill().map((_,i) => i).map((item, index) => ( */}
         {kpiMappingFormData[itemName].map((item, index) => (
          <Box key={`add_${itemName}_${index}`} pb={2}
            sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
          >
            <Box width={"100%"}
            sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
            >
              <Box width={"100%"}
              sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
              >
                {Object.keys(item).map((input_item, input_index) => (
                  <Box width={`${(100/(Object.keys(item).length))-1}%`} key={`add_${itemName}_${input_item}_box_${index}`}>
                    <AddInputField DataTables={DataTables} itemName={itemName} graphConfig={graphConfig} kpiMappingFormData={kpiMappingFormData} setkpiMappingFormData={setkpiMappingFormData} selectedTableColumns={selectedTableColumns} errorActive={errorActive} input_item={input_item} index={index} AgrFunction={AgrFunction} input_index={input_index}/>
                  </Box>
                ))}
                {/* <Box width={`${(100/(Object.keys(graphConfig[itemName].fields).length + 2))-1}%`} id={`add_${itemName}_agrFunction_box_${index}`}>
                    <TextField
                        fullWidth
                        id={`add_${itemName}_agrFunction_${index}`}
                        select
                        label="Select A Aggregate Function"
                        variant="outlined"
                        name = 'agrFunction'
                        value={''}
                        //   value={kpiDetailsData.havingClauseValue[index].agrFunction || ''}
                        //   error={errorActive ? kpiDetailsData.havingClauseValue[index].agrFunction === '': false}
                        //   onChange={e => {
                        //   let change_join_data = [...kpiDetailsData.havingClauseValue]
                        //   let {name, value} = e.target
                        //   change_join_data[index][name] = value;
                        //   setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                        //   }}
                    >
                        <MenuItem key={`add_${itemName}_please_select_agrFunction_${index}`} value='' disabled>
                            Please Select A Aggregate Function
                        </MenuItem>
                        {AgrFunction.map((option) => (
                        <MenuItem key={`add_${itemName}_agrFunction_${index}_${option.value}`} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box width={`${(100/(Object.keys(graphConfig[itemName].fields).length + 2))-1}%`} id={`add_${itemName}_dataColumn_box_${index}`}>
                    <TextField
                        fullWidth
                        id={`add_${itemName}_dataColumn_${index}`}
                        select
                        label="Select A Aggregate Function"
                        variant="outlined"
                        name = 'dataColumn'
                        value={''}
                        //   value={kpiDetailsData.havingClauseValue[index].agrFunction || ''}
                        //   error={errorActive ? kpiDetailsData.havingClauseValue[index].agrFunction === '': false}
                        //   onChange={e => {
                        //   let change_join_data = [...kpiDetailsData.havingClauseValue]
                        //   let {name, value} = e.target
                        //   change_join_data[index][name] = value;
                        //   setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                        //   }}
                    >
                        <MenuItem key={`add_${itemName}_please_select_dataColumn_${index}`} value='' disabled>
                            Please Select A Column
                        </MenuItem>
                        {selectedTableColumns.map((option) => (
                        <MenuItem key={`add_${itemName}_dataColumn_${index}_${option}`} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </TextField>
                </Box>
                {Object.keys(graphConfig[itemName].fields).map((input_item, input_index) => (
                    <Box width={`${(100/(Object.keys(graphConfig[itemName].fields).length + 2))-1}%`} key={`add_${itemName}_${input_item}_box_${index}`}>
                    {input_item ==='color' ? 
                    <MuiColorInput
                        fullWidth
                        key={`add_${itemName}_${input_item}_${index}_${input_index}`}
                        // label={`Enter ${input_item}`}
                        // variant="outlined"
                        name = {`${input_item}`}
                        value={'#000'}
                        //   value={kpiDetailsData.havingClauseValue[index].agrFunction || ''}
                        //   error={errorActive ? kpiDetailsData.havingClauseValue[index].agrFunction === '': false}
                        //   onChange={e => {
                        //   let change_join_data = [...kpiDetailsData.havingClauseValue]
                        //   let {name, value} = e.target
                        //   change_join_data[index][name] = value;
                        //   setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                        //   }}
                    >
                    </MuiColorInput>
                    :
                    <TextField
                        fullWidth
                        id={`add_${itemName}_${input_item}_${index}_${input_index}`}
                        select
                        label={`Enter ${input_item}`}
                        variant="outlined"
                        name = {`${input_item}`}
                        value={''}
                        //   value={kpiDetailsData.havingClauseValue[index].agrFunction || ''}
                        //   error={errorActive ? kpiDetailsData.havingClauseValue[index].agrFunction === '': false}
                        //   onChange={e => {
                        //   let change_join_data = [...kpiDetailsData.havingClauseValue]
                        //   let {name, value} = e.target
                        //   change_join_data[index][name] = value;
                        //   setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                        //   }}
                    >
                        <MenuItem key={`add_${itemName}_please_select_${input_item}_${index}_${input_index}`} value='' disabled>
                            Please Select A Aggregate Function
                        </MenuItem>
                        {AgrFunction.map((option) => (
                        <MenuItem key={`add_${itemName}_${input_item}_${index}_${input_index}_${option.value}`} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                    }
                    </Box>
                ))} */}
              </Box>
            </Box>
            <Box height={'100%'} pt={1}
            sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
            >
            <IconButton disabled={index === 0}
            // sx={{position: "relative", top: "-72px"}}
                onClick={() => handleRemoveDataItem(index, itemName)}>
                <Delete />
            </IconButton>
            </Box>
          </Box>
        ))} 
      </Box>
      :
      <></>
    );
  }
  
  export default AddDimension;