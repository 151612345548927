import {
  GET_ALL_CRM_EVENT_REQUEST,
  GET_ALL_CRM_EVENT_SUCCESS,
  GET_ALL_CRM_EVENT_FAIL,
  CRM_EVENT_APPROVAL_SUCCESS,
  SET_APPROVAL_FAILURE_STATUS,
} from "../constants/adminUserConstants";

let userCrmEventsInitialstate = {
  loading: true,
  userCRMEvents: [],
  error: "",
  approvalFailureStatus:null
};
export const userCRMEventsReducer = (
  state = userCrmEventsInitialstate,
  action
) => {
  switch (action.type) {
    case GET_ALL_CRM_EVENT_REQUEST:
      return {
        ...userCrmEventsInitialstate,
        loading: true,
        userCRMEvents: [],
      };

    case GET_ALL_CRM_EVENT_SUCCESS:
      return {
        ...userCrmEventsInitialstate,
        loading: false,
        userCRMEvents: action.payload,
      };

    case GET_ALL_CRM_EVENT_FAIL:
      return {
        ...userCrmEventsInitialstate,
        loading: false,
        error: action.payload,
      };

    case CRM_EVENT_APPROVAL_SUCCESS:
      var final_data = [];
      state.userCRMEvents.map((events) => {
        if (events.approval_id === action.payload[1]) {
          final_data.push({ ...events, approval_status: action.payload[0] });
        }
        final_data.push(events);
      });

      return {
        ...userCrmEventsInitialstate,
        loading: false,
        userCRMEvents: final_data,
      };

    case SET_APPROVAL_FAILURE_STATUS:
      return {
        ...userCrmEventsInitialstate,
        approvalFailureStatus:action.payload
      }

    default:
      return state;
  }
};
