import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';
// import {FormControl} from '@mui/material/FormControl';
// import {Select} from '@mui/material/Select';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputAdornment, Typography } from '@mui/material'
import { Box, InputBase, IconButton, Button } from '@mui/material'
import { setAdminData } from '../../../actions/adminManagementAction';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useContext } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ColorModeContext, tokens } from "../../../theme";
import { slideUpEditInstanceAction, changeSlideStatus, updateAdminData } from '../../../actions/adminManagementAction';
import { showGlobalSnackbar } from '../../../actions/globalSnackbarActions';

export default function SimpleSlide({ admnAddBtnVis, setadmnAddBtnVis }) {
  let dispatch = useDispatch()
  const adminManagementReducer = useSelector((state) => state.adminManagementReducer)

  // const userLogin = useSelector((state)=>state.useLogin)
  // const {userInfo} = userLogin

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, userInfo } = userLogin;



  let { slideUpEditInstance, slideStatus, getRoles } = adminManagementReducer

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  // for media queries
  let View769px = useMediaQuery('(max-width:769px)');
  let View431px = useMediaQuery('(max-width:431px)');

  // useEffect(()=>{
  //   console.log("View769px",View769px)
  // },[View769px])

  // useEffect(()=>{
  //     // console.log("theme.palette",theme.palette.mode)
  //     console.log("theme.palette.mode",theme.palette.mode)
  // },[theme.palette.mode])

  const [checked, setChecked] = useState(false);

  const [details, setDetails] = useState(
    {
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      role_id: "",
      phone: "",
      username: "",
      mfa_method: "authenticator",
      // password:"",
      // confirmPassword:""
    }
  )

  const handleChange = () => {
    // setChecked((prev) => !prev);
    // console.log("changeSlideStatus","value",!slideStatus)
    dispatch(changeSlideStatus(!slideStatus))
    setadmnAddBtnVis((prev) => { return !prev })

    // clears inputs when unmounting
    setDetails({
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      role_id: "",
      phone: "",
      username: "",
      mfa_method: "authenticator",
      // password:"",
      // confirmPassword:""
    })
  };

  // for single slide approach using redux
  useEffect(() => {
    // console.log(
    //   'slideUpEditInstance in SimpleSlide',
    //   slideUpEditInstance
    // )
    if (slideUpEditInstance) {
      // setDetails({
      //   ...slideUpEditInstance.adminRowDetails
      // })

      // getting data of the particular instance from redux
      setDetails((prev) => {
        return prev = { ...slideUpEditInstance.adminRowDetails }
      })
    }

    // on unmount
    // return ()=>{

    // }
  }, [slideUpEditInstance])

  // useEffect(() => {
  //   console.log("getRolesReducer", getRoles)
  //   console.log("userInfo", userInfo)
  // }, [getRoles])

  return (
    <Box
      sx={{
        // height: 210, removes the invisible height slideUp when not mounted
        width: 130,
        position: 'relative',
        zIndex: 1,

      }}
    >
      <FormControlLabel
        control={
          <Button
            variant="contained"
            onClick={handleChange}
            endIcon={<AddIcon />}
            sx={{ display: !slideStatus/*admnAddBtnVis*/ ? "span" : "none" }}
          >Add Admins</Button>
        }
      // label="Add Admins"
      // sx={{height:"5vh"}}
      />
      <Slide
        direction="up"
        in={slideStatus/*checked*/}
        mountOnEnter unmountOnExit

      >
        {/* Slide */}
        {/* slide Up is same as box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "25px",
            flexWrap: "wrap",
            position: "relative",
            left: !View431px ? "-92vw" : "-72vw",//position of slide up panel
            width: "115vw",//changes the width of slide up panel
            height: "87.5vh",
            backgroundColor: theme.palette.mode == "light" ? "whitesmoke" : "#1f1f1f",
            // textAlign:"center"

          }}

        >

          <Box sx={{
            display: "flex",
            // justifyContent: "space-around",
            flexDirection: "column",
            gap: "25px",

          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", borderRadius: '5px', border: '1px solid lightgrey' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <EmailOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Email"
                value={details.email}
                // defaultValue={details.email}
                // tabIndex={0}
                // disabled={slideUpEditInstance ? true : false}
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      email: event.target.value
                    })
                  }
                }
                label="Email"
              />

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", border: '1px solid lightgrey', borderRadius: '5px' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="First Name">
                <PermIdentityOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="First Name"
                value={details.first_name}
                // defaultValue={details.first_name}
                // tabIndex={0}
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      first_name: event.target.value
                    })

                  }
                }
              />

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", border: '1px solid lightgrey', borderRadius: '5px' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="Last Name">
                <PermIdentityOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Last Name"
                value={details.last_name}
                // defaultValue={details.last_name}
                // tabIndex={0}
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      last_name: event.target.value
                    })
                  }
                }
              />

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", borderRadius: '5px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Authenticator</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={details.mfa_method}
                  // defaultValue={details.mfa_method??""}
                  label="Authenticator"
                  // tabIndex={0}
                  disabled={true}
                >
                  <MenuItem value={details.mfa_method}>{details.mfa_method}</MenuItem>

                </Select>
              </FormControl>
            </Box>
            {/* for alignment */}
            <Box sx={{ display: !View769px ? "flex" : "none", visibility: "hidden", alignItems: 'center', width: !View431px ? "35vw" : "80vw", borderRadius: '5px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">alignment</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"alignment"}
                  // defaultValue={details.alignment??""}
                  label="alignment"
                // tabIndex={0}
                >
                  <MenuItem value={"alignment"}>alignment</MenuItem>

                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            // justifyContent: "space-around",
            flexDirection: "column",
            gap: "25px",
            height: "90%"

          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", border: '1px solid lightgrey', borderRadius: '5px' }}>

              <IconButton type="button" sx={{ p: '10px' }} aria-label="Phone number">
                <ContactPhoneOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Phone number 012-345-6789"
                value={details.phone}
                // defaultValue={details.phone}
                // tabIndex={0}
                // type='number'
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      phone: event.target.value
                    })
                  }
                }
              />

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", border: '1px solid lightgrey', borderRadius: '5px' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="Middle Name">
                <PermIdentityOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Middle Name"
                value={details.middle_name}
                // defaultValue={details.middle_name}
                // tabIndex={0}
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      middle_name: event.target.value
                    })
                  }
                }
              />

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", border: '1px solid lightgrey', borderRadius: '5px' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="Username">
                <PermIdentityOutlinedIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Username"
                value={details.username}
                // defaultValue={details.username}
                // tabIndex={0}
                disabled={slideUpEditInstance ? true : false}
                onChange={
                  (event) => {

                    setDetails({
                      ...details,
                      username: event.target.value
                    })
                  }
                }
              />

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', width: !View431px ? "35vw" : "80vw", borderRadius: '5px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={details.role_id}
                  // defaultValue={details.role_id??""}
                  label="Roles"
                  // tabIndex={0}
                  onChange={(event) => {

                    setDetails({
                      ...details,
                      role_id: event.target.value
                    })
                  }}
                >
                  {
                    getRoles.map((x) => {
                      if (x.role_name !== "Consumer") { return <MenuItem key={x.role_id} value={x.role_id}>{x.role_name}</MenuItem> }
                      else { return null }
                    })
                  }

                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: "flex-end", alignItems: 'flex-end', width: !View431px ? "35vw" : "80vw", gap: "20px", borderRadius: '5px' }}>
              <Button variant='outlined' height="100%"
                onClick={(event) => {
                  handleChange(false)

                  // for single slide approach using redux
                  // setting the editInstaceData to null
                  dispatch(slideUpEditInstanceAction(null))
                }}>
                Cancel
              </Button>

              <Button
                variant='outlined'
                height="100%"

                disabled={
                    details.email.length > 0 &&
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(details.email) &&
                    details.first_name.length > 0 &&
                    details.last_name.length > 0 &&
                    details.username.length > 0 &&
                    details.phone.length === 10 &&
                    details.role_id !== "" ?
                    false :
                    true
                }
                onClick={(event) => {
                  if (!slideUpEditInstance) {
                    dispatch(setAdminData(details))
                  }
                  else {
                    dispatch(updateAdminData(details))
                  }

                  // for single slide approach using redux
                  // setting the editInstaceData to null
                  dispatch(slideUpEditInstanceAction(null))

                  handleChange(false)
                }}>
                {!slideUpEditInstance ? "Save" : "Update"}
              </Button>

            </Box>
          </Box>
        </Box>

      </Slide>
    </Box>
  );
}