import * as React from 'react';
import {Box,Button,Stack,Divider,TextField,MenuItem,IconButton,  Typography,FormControl, Select } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
    "& .MuiFilledInput-root": {
        background: "rgb(232, 241, 250)"
    },
    '& label.Mui-focused': {
      borderRadius: 10,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e0e0e0',
    },
    '& .MuiInputLabel-root': {
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: 10,
      background: "#f2f2f2",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
      '&.Mui-focused fieldset': {
        borderRadius: 10,
        borderColor: '#e0e0e0',
      },
    },
  });

  
function AddHavingClause({kpiDetailsData, setkpiDetailsData, errorActive}){

    const Operators =  [
      {
        value: '=',
        label: '=',
      },
      {
        value: '>',
        label: '>',
      },
      {
        value: '<',
        label: '<',
      },
      {
        value: '>=',
        label: '>=',
      },
      {
        value: '<=',
        label: '<=',
      },
      {
        value: '!=',
        label: '!=',
      },
    ];
    

    
    const AgrFunction = [
      {
        value: 'MAX',
        label: 'MAX',
      },
      {
        value: 'MIN',
        label: 'MIN',
      },
      {
        value: 'COUNT',
        label: 'COUNT',
      },
      {
        value: 'SUM',
        label: 'SUM',
      },
      {
        value: 'AVG',
        label: 'AVG',
      },
    ]

    const handleRemoveHavingClauseItem = (index, keyName) => {
        let newObj={}
        const allItems = [...kpiDetailsData[keyName]];
        allItems.splice(index, 1);
        newObj[keyName]=[...allItems]
        setkpiDetailsData({...kpiDetailsData, ...newObj});
      };

    return (
      <Box p={2}>
      {kpiDetailsData.havingClauseValue.map((item, index) => (
        <Box key={`having_${index}`} pb={2}
          sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
        >
          <Box width={"100%"}
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
          >
            <Box width={"100%"}
            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
            >
              <Box width={"24%"} id={`agrFunction_box_${index}`}>
                <TextField
                    fullWidth
                    id={`agrFunction_${index}`}
                    select
                    label="Select A Aggregate Function"
                    variant="outlined"
                    name = 'agrFunction'
                    value={kpiDetailsData.havingClauseValue[index].agrFunction || ''}
                    error={errorActive ? kpiDetailsData.havingClauseValue[index].agrFunction === '': false}
                    onChange={e => {
                    let change_join_data = [...kpiDetailsData.havingClauseValue]
                    let {name, value} = e.target
                    change_join_data[index][name] = value;
                    setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                    }}
                >
                    <MenuItem key={`please_select_agrFunction_${index}`} value='' disabled>
                        Please Select A Aggregate Function
                    </MenuItem>
                    {AgrFunction.map((option) => (
                    <MenuItem key={`agrFunction_${index}_${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box width={"24%"} key={`havingcolumn_box_${index}`}>
                <TextField
                    fullWidth
                    id={`havingColumn_${index}`}
                    select
                    label="Select A Column"
                    variant="outlined"
                    name = 'havingColumn'
                    value={kpiDetailsData.havingClauseValue[index].havingColumn || ''}
                    error={errorActive ? kpiDetailsData.havingClauseValue[index].havingColumn === '': false}
                    onChange={e => {
                    let change_join_data = [...kpiDetailsData.havingClauseValue]
                    let {name, value} = e.target
                    change_join_data[index][name] = value;
                    setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                    }}
                >
                    <MenuItem key={`please_select_havingColumn_${index}`} value='' disabled>
                    Please Select A Column
                    </MenuItem>
                    {kpiDetailsData.selectedTableColumns.map((option) => (
                    <MenuItem key={`havingColumn_${index}_${option}`} value={option}>
                        {option}
                    </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box width={"24%"} id={`havingOperator_box_${index}`}>
                <TextField
                fullWidth
                id={`havingOperator_${index}`}
                select
                label="Select A Operator"
                variant="outlined"
                name = 'havingOperator'
                value={kpiDetailsData.havingClauseValue[index].havingOperator || ''}
                error={errorActive ? kpiDetailsData.havingClauseValue[index].havingOperator === '': false}
                onChange={e => {
                    let change_join_data = [...kpiDetailsData.havingClauseValue]
                    let {name, value} = e.target
                    change_join_data[index][name] = value;
                    setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                }}
                >
                <MenuItem key={`please_select_havingOperator_${index}`} value='' disabled>
                    Please Select A Operator
                </MenuItem>
                {Operators.map((option) => (
                    <MenuItem key={`havingOperator_${index}_${option.value}`} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
              </Box>
              <Box width={"24%"} id={`havingValue_box_${index}`}>
                <TextField
                fullWidth
                id={`havingValue_${index}`}
                label="Enter Value"
                variant="outlined"
                name = 'havingValue'
                value={kpiDetailsData.havingClauseValue[index].havingValue || ''}
                error={errorActive ? kpiDetailsData.havingClauseValue[index].havingValue === '': false}
                onChange={e => {
                    let change_join_data = [...kpiDetailsData.havingClauseValue]
                    let {name, value} = e.target
                    change_join_data[index][name] = value;
                    setkpiDetailsData({...kpiDetailsData, havingClauseValue : change_join_data});
                }}
                >
                </TextField>
              </Box>
            </Box>
          </Box>
          <Box height={'100%'} pt={1}
          sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
          >
          <IconButton 
          // sx={{position: "relative", top: "-72px"}}
              onClick={() => handleRemoveHavingClauseItem(index, 'havingClauseValue')}>
              <Delete />
          </IconButton>
          </Box>
        </Box>
      ))}
  </Box>
    );
}

  
export default AddHavingClause;