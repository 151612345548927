import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UserlastLoginCheck } from '../../actions/userActions';
import { Button,Grid,Typography,Container,TextField,Box,CssBaseline,Paper } from "@mui/material";
import { Link } from "react-router-dom";
import PasswordComponent from './passwordComponent';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';



export default function LogInPage() {
  const userReducer2=useSelector((state)=>state.userReducer2)

  const dispatch = useDispatch();

  const navigate=useNavigate()

  const [emailSectionVisible, setemailSectionVisible] = useState(true);
  const [emailEnabled,setemailEnabled] = useState(true)
  const [email, setEmail] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    const storedRememberMe = localStorage.getItem('rememberMe');
    if (storedRememberMe === 'true') {
      setEmail(storedEmail || '');
    }
  }, []);

  const handleLogInUser = (event) => {
    // setemailSectionVisible(false)
    if(email !== ''){
      dispatch(UserlastLoginCheck(email,setemailSectionVisible,navigate,setemailEnabled))
    }
    
  }

  // useEffect(()=>{
  //   console.log("userReducer2",userReducer2)
  // },[userReducer2])

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 16,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              // backgroundImage: `url(../../../assets/logIn.svg)`,
              backgroundImage: `url(../../../assets/LogInNew.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize:"cover",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            pt={1}
            pb={4}
            pl={4}
            pr={4}
            minHeight={400}
          >
            <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                <Box component="img"
                    sx={{
                    height: 100,
                    // display: { xs: 'flex', md: 'none' },
                    mt:2
                    }}
                    alt="BridgeWater Logo"
                    src="../../assets/Final_Energize_Bridgewater.png"
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
                {/* <Typography variant='h3'>
                    Energy Management Information System
                </Typography> */}
                <Typography variant='h3' textAlign={"left"} width={"100%"} >
                    Log In
                </Typography>
                { emailSectionVisible ?
                  <Box width="100%" pt={1}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        autoFocus
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled = {validator.isEmail(email) && emailEnabled? false : true}
                      onClick={handleLogInUser}
                      >
                      Next
                    </Button>
                    <Box width='100%' display='flex' flexDirection='row' >
                      <Button pt={0}
                        component={Link}
                        to={'/forget-password'}
                        >
                        <Typography variant='subtitle2'>
                            Forgot Password
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                  : 
                  <PasswordComponent email={email}/>
                }
            </Box>
          </Grid>
          <Grid container>
              <Grid item xs >
          </Grid>
        </Grid>
        </Grid>
      </Box>
    </Container>
  );
}