import * as React from 'react';
import {useState,useEffect} from 'react';
import {Box,Typography,IconButton,TableBody,Table,Skeleton,TableRow,TableCell,TableHead,Tooltip,Button,TextField} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AccessControl from "../../../AccessControl";
import { GetPermisssionListSingleRole, ModifyPermisssionListToSingleRole} from '../../../actions/roleActions';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    padding: '0px !important',
    // marginLeft: theme.spacing(1),
  },
  '& .MuiButtonBase-root' :{
    margin: '0px !important',
    padding: '0px !important',
    // height: "15px",
  }

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
//   border: '1px solid rgba(0, 0, 0, 0)',
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardExpanded({roleData,permissionList,expanded,handleChange,roleDeleteFunc}) {
    const dispatch = useDispatch()

    // useEffect(()=>{
    //     dispatch(GetPermisssionListSingleRole(roleData.role_id))
    // }, [])

    const rolesPermissioDetails = useSelector(state => state.rolesPermissioDetails)
    const {error: rolesPermissionsDataError, loading: rolesPermissionsDataLoading, rolesPermissionsData } = rolesPermissioDetails

    const animatedComponents = makeAnimated();

    const [selectedPermissionsOption, setSelectedPermissionsOption] = useState(null);
    const [roleEditInput, setroleEditInput] = useState(false);
    const [needToDeletePermissiondata, setneedToDeletePermissiondata] = React.useState({});
    const [deletePermissionConfirmDialougeController, setdeletePermissionConfirmDialougeController] = React.useState(false);
    const [roleDescription, setroleDescription] = useState(roleData.role_description);

    const [deleteRoleConfirmDialougeController, setdeleteRoleConfirmDialougeController] = React.useState(false);
    const [needToDeleteRoledata, setneedToDeleteRoledata] = React.useState({});

    const permissionAdd = (roleData) => {

      if(selectedPermissionsOption!==null && selectedPermissionsOption.length>=1){
        var selected_permissions = [];
        selectedPermissionsOption.forEach(function (item, index) {
            selected_permissions.push(item.permission_id.toString());
          });
        dispatch(ModifyPermisssionListToSingleRole(
            {
                "role_id": roleData.role_id.toString(),
                "permissions": selected_permissions,
                "operation": "add-permission"
            },
            roleData.role_id, true
        ));
        setSelectedPermissionsOption(null);
      }
      else{
        setSelectedPermissionsOption(null);
      }

    };

    const permissiondeletefromRole = (roleData,permissiondata) => {
        setneedToDeletePermissiondata(permissiondata);
        setdeletePermissionConfirmDialougeController(true);
    }

    const handledeletePermissionConfirmDialougeClose = () => {
        setdeletePermissionConfirmDialougeController(false);
    };

    const handledeletePermissionConfirmDialougeAccept = (roleData,permissiondata) => {

        dispatch(ModifyPermisssionListToSingleRole(
            {
                "role_id": roleData.role_id.toString(),
                "permissions": [permissiondata.permission_id.toString()],
                "operation": "remove-permission"
            },
            roleData.role_id, true
        ));
        // setSelectedPermissionsOption(()=>{
        //   roleData.Permissions.splice(roleData.Permissions.indexOf(permissiondata),1)
        // });
        setdeletePermissionConfirmDialougeController(false);
    }

    const rolesEdit = (roleData) => {
      setroleEditInput(!roleEditInput);
    };
    
    const RolesEditDataOnChange = (event) => {
        setroleDescription(event.target.value);
    };

    const rolesEditedDataSave = (roleData) => {
        dispatch(ModifyPermisssionListToSingleRole(
            {
                "role_id": roleData.role_id.toString(),
                "role_description": roleDescription,
                "operation": "modify"
            },
            roleData.role_id, false
        ));  
        setroleEditInput(false);
    };

    const ShowRoleDeleteConfirmation = (roleData) =>{
        setneedToDeleteRoledata(roleData);
        setdeleteRoleConfirmDialougeController(true);
    }

    const handledeleteRoleConfirmDialougeClose = () => {
        setdeleteRoleConfirmDialougeController(false);
    };



    return (
        <Box>
            <Accordion expanded={expanded === `panel${roleData.role_id}`} onChange={handleChange(`panel${roleData.role_id}`, roleData.role_id)} >
                <AccordionSummary aria-controls={`panel${roleData.role_id}d-content`} id={`panel${roleData.role_id}d-header`}>
                    <Table aria-label="collapsible table" >
                        <TableBody>
                            <TableRow>
                                <TableCell width="50" sx={{borderBottom: "none",  }} align="left">{roleData.role_id}</TableCell>
                                <TableCell width="100" sx={{borderBottom: "none", fontSize: 16,fontWeight: 'bold' }} align="left">{roleData.role_name}</TableCell>
                                { roleEditInput ? 
                                <TableCell width="300" sx={{borderBottom: "none", }} align="left">
                                    <TextField
                                        required
                                        size='small'
                                        fullWidth
                                        id="outlined-required"
                                        defaultValue={roleData.role_description}
                                        onChange={RolesEditDataOnChange}
                                    />
                                </TableCell>
                                :
                                <TableCell width="300" sx={{borderBottom: "none", }} align="left">{roleData.role_description}</TableCell> }
                                <TableCell width="50" sx={{borderBottom: "none", }} align="left">
                                    
                                    <Box display={'flex'} flexDirection={'row'} alignContent={'center'} justifyContent={'space-around'} width={'100%'}>
                                    { roleEditInput ? 
                                        <Tooltip title="Save" placement="bottom">
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => rolesEditedDataSave(roleData)}
                                            >
                                            <SaveAsOutlinedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    :
                                        null
                                    }
                                    <AccessControl allowedPermissions={["modifyRole"]} >
                                        <Tooltip title="Edit" placement="bottom">
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => rolesEdit(roleData)}
                                            >
                                            <EditNoteOutlinedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </AccessControl>
                                    <AccessControl allowedPermissions={["deleteRole"]} >
                                        <Tooltip title="Delete" placement="right">
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => ShowRoleDeleteConfirmation(roleData)}
                                            >
                                            <DeleteOutlinedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </AccessControl>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        style={{display: 'flex', flexDirection:'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',width:'100%'}}
                    >
                        <Box width={'50%'}>
                            <Typography variant="h5" gutterBottom component="div" sx={{ fontSize: 16,fontWeight: 'bold'}}>
                                Permissions List
                            </Typography>
                        </Box>
                        <AccessControl allowedPermissions={["modifyRole"]} >
                            {rolesPermissionsDataLoading ? 
                                <Box style={{display: 'flex', width:'50%',  alignItems: 'center', flexDirection:'row', justifyContent: 'space-between'}}>
                                    <Skeleton variant="rounded" width={'98%'} height={30} />
                                </Box>
                            : rolesPermissionsDataError ?
                                <Box style={{display: 'flex', width:'50%',  alignItems: 'center', flexDirection:'row', justifyContent: 'space-between'}}>
                                    <Typography>Failed to Get Role's Permissions</Typography>
                                </Box>
                            :
                                <Box
                                    style={{display: 'flex', width:'50%', alignItems: 'center', flexDirection:'row', justifyContent: 'space-between'}}
                                >
                                
                                    {/* <Select
                                        placeholder='Add New Permissions'
                                        isMulti
                                        isSearchable
                                        closeMenuOnSelect={false}
                                        isClearable={true}
                                        options={permissionList}
                                        components={animatedComponents}
                                        value={selectedPermissionsOption}
                                        onChange={setSelectedPermissionsOption}
                                        classNamePrefix="select"
                                        maxMenuHeight={80}
                                        noOptionsMessage={()=>"No More Permissions Avaliable..."}
                                        styles={{
                                        placeholder:(baseStyles, states) => ({
                                            ...baseStyles,
                                            color:"black"
                                        }),
                                        dropdownIndicator:(baseStyles) => ({
                                            ...baseStyles,
                                            color:"black"
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: 'grey',
                                            height: '35px',
                                            width:'370px'
                                        }),
                                        valueContainer: (base) => ({
                                            ...base,
                                            maxHeight: 35,
                                            overflowY: "auto"
                                        }),
                                        }}
                                    /> */}
                                    <Box display='flex' width={'82%'}>
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={permissionList}
                                            disableCloseOnSelect
                                            limitTags={2}
                                            getOptionLabel={(option) => option.permission_name}
                                            renderOption={(props, option, { selected }) => (
                                                rolesPermissionsData[roleData.role_id].some(el => el.permission_name === option.permission_name) ?
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        disabled
                                                        checked={true}
                                                    />
                                                    {option.permission_name}
                                                </li>
                                                :
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.permission_name}
                                                </li>
                                            )}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Add New Permissions" 
                                                    placeholder="Permissions"  size='medium'
                                                />
                                            )}
                                            // value={Object.keys(selectedPermissionsOption).length > 0 ? selectedPermissionsOption : }
                                            onChange={(event,value) => setSelectedPermissionsOption(value)}
                                            getOptionDisabled={(option) =>
                                                rolesPermissionsData[roleData.role_id].some(el => el.permission_name === option.permission_name) 
                                            }
                                        />
                                    </Box>

                                    <Box display='flex' width={'18%'} justifyContent={'end'} pl={1}>
                                        <Button 
                                            flexdirection='column' 
                                            variant="contained"
                                            // size="small"
                                            onClick={() => {
                                                permissionAdd(roleData);
                                            }}
                                        >
                                            Add Permission
                                        </Button>
                                    </Box>
                                
                                </Box>
                            }
                        </AccessControl>
                    </Box>
                    <Box key={`spacing-${roleData.role_id}`} sx={{ m: 1 }} />
                    <Box>
                        <Table size="small" aria-label="permissions" >
                            <TableHead >
                                <TableRow>
                                    <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission ID</TableCell>
                                    <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission Name</TableCell>
                                    <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission Description</TableCell>
                                    <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Created Date</TableCell>
                                    <TableCell align="center" sx={{ fontSize: 13,fontWeight: 'bold'}}>
                                        <AccessControl allowedPermissions={["deletePermission"]} >
                                            Actions
                                        </AccessControl>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rolesPermissionsDataLoading ? 
                                    <TableRow key='Data load'>
                                        <TableCell component="th" scope="row"><Skeleton variant="rounded" animation="wave"/></TableCell>
                                        <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                        <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                        <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                        <TableCell align="center">
                                            <AccessControl allowedPermissions={["deletePermission"]} >
                                                <Skeleton variant="rounded" animation="wave"/>
                                            </AccessControl>
                                        </TableCell> 
                                    </TableRow>
                                    : rolesPermissionsDataError ?
                                    <TableRow key='Data fetch Failed'>
                                    </TableRow>
                                    :rolesPermissionsData[roleData.role_id]?.length === 0 || rolesPermissionsData[roleData.role_id] === undefined ?
                                    <TableRow key='NO Data key'>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>No Permission Assigned to this role</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    :
                                    <>
                                        {rolesPermissionsData[roleData.role_id].map((permission) => (
                                            <TableRow key={permission.permission_id}>
                                                <TableCell component="th" scope="row">{permission.permission_id}</TableCell>
                                                <TableCell>{permission.permission_name}</TableCell>
                                                <TableCell>{permission.permission_description}</TableCell>
                                                <TableCell>{ moment(permission.created_on).format("MMMM Do, YYYY - h:mm A") }</TableCell>
                                                <TableCell align="center">
                                                    <AccessControl allowedPermissions={["deletePermission"]} >
                                                        <Tooltip title="Delete" placement="right">
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => {
                                                                        permissiondeletefromRole(roleData,permission);
                                                                    }
                                                                }
                                                            >
                                                                <DeleteOutlinedIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </AccessControl>
                                                </TableCell> 
                                            </TableRow>
                                        ))}
                                    </>
                                    
                                }
                            </TableBody>
                        </Table>
                        <Dialog
                            open={deletePermissionConfirmDialougeController}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handledeletePermissionConfirmDialougeClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{`Do you want to delete ${needToDeletePermissiondata.permission_name} Permission for the ${roleData.role_name} role?`}</DialogTitle>
                            <DialogActions>
                                <Button onClick={handledeletePermissionConfirmDialougeClose}>Cancel</Button>
                                <Button onClick={()=>{handledeletePermissionConfirmDialougeAccept(roleData,needToDeletePermissiondata)}}>OK</Button>
                            </DialogActions>
                        </Dialog>

                    </Box>
                </AccordionDetails>
            </Accordion>
            <Box key={roleData.role_id} sx={{ m: 1 }} />

            <Dialog
                open={deleteRoleConfirmDialougeController}
                TransitionComponent={Transition}
                keepMounted
                onClose={handledeleteRoleConfirmDialougeClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Do you want to delete ${needToDeleteRoledata.role_name} permanenetly?`}</DialogTitle>
                <DialogActions>
                    <Button onClick={handledeleteRoleConfirmDialougeClose}>Cancel</Button>
                    <Button onClick={()=>{roleDeleteFunc(needToDeleteRoledata);handledeleteRoleConfirmDialougeClose()}}>OK</Button>
                </DialogActions>
            </Dialog>

        </Box>
)
}

// import * as React from 'react';
// import {useState} from 'react';
// import {Box,Typography,IconButton,TableBody,Table,useTheme,Skeleton,TableRow,TableCell,TableHead,Tooltip,Button,TextField} from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
// import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from "moment";
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
// import AccessControl from "../../../AccessControl";
// import { GetPermisssionListSingleRole, ModifyPermisssionListToSingleRole} from '../../../actions/roleActions';
// import { tokens } from "../../../theme";

// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `2px solid ${theme.palette.divider}`,
//   borderRadius:'10px',
// //   '&:not(:last-child)': {
// //     borderBottom: 0,
// //   },
//   '&:before': {
//     display: 'none',
//   },

// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     margin: '0px !important',
//     padding: '0px !important',
//     // marginLeft: theme.spacing(1),
//   },
//   '& .MuiButtonBase-root' :{
//     margin: '0px !important',
//     padding: '0px !important',
//   }

// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
// //   borderTop: '1px solid rgba(0, 0, 0, .125)',
// //   border: '1px solid rgba(0, 0, 0, 0)',
// }));


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// export default function CardExpanded({roleData,permissionList,expanded,handleChange,roleDeleteFunc}) {

//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);

//     const dispatch = useDispatch()

//     // useEffect(()=>{
//     //     dispatch(GetPermisssionListSingleRole(roleData.role_id))
//     // }, [])

//     const rolesPermissioDetails = useSelector(state => state.rolesPermissioDetails)
//     const {error: rolesPermissionsDataError, loading: rolesPermissionsDataLoading, rolesPermissionsData } = rolesPermissioDetails

//     const animatedComponents = makeAnimated();

//     const [selectedPermissionsOption, setSelectedPermissionsOption] = useState(null);
//     const [roleEditInput, setroleEditInput] = useState(false);
//     const [needToDeletePermissiondata, setneedToDeletePermissiondata] = React.useState({});
//     const [deletePermissionConfirmDialougeController, setdeletePermissionConfirmDialougeController] = React.useState(false);
//     const [roleDescription, setroleDescription] = useState(roleData.role_description);

//     const [deleteRoleConfirmDialougeController, setdeleteRoleConfirmDialougeController] = React.useState(false);
//     const [needToDeleteRoledata, setneedToDeleteRoledata] = React.useState({});

//     const permissionAdd = (roleData) => {

//       if(selectedPermissionsOption!==null && selectedPermissionsOption.length>=1){
//         var selected_permissions = [];
//         selectedPermissionsOption.forEach(function (item, index) {
//             // console.log("item",item)
//             // console.log("selected_permissions",selected_permissions)
//             selected_permissions.push(item.permission_id.toString());
//           });
//         dispatch(ModifyPermisssionListToSingleRole(
//             {
//                 "role_id": roleData.role_id.toString(),
//                 "permissions": selected_permissions,
//                 "operation": "add-permission"
//             },
//             roleData.role_id, true
//         ));
//         setSelectedPermissionsOption(null);
//       }
//       else{
//         setSelectedPermissionsOption(null);
//       }

//     };

//     const permissiondeletefromRole = (roleData,permissiondata) => {
//         setneedToDeletePermissiondata(permissiondata);
//         setdeletePermissionConfirmDialougeController(true);
//     }

//     const handledeletePermissionConfirmDialougeClose = () => {
//         setdeletePermissionConfirmDialougeController(false);
//     };

//     const handledeletePermissionConfirmDialougeAccept = (roleData,permissiondata) => {

//         dispatch(ModifyPermisssionListToSingleRole(
//             {
//                 "role_id": roleData.role_id.toString(),
//                 "permissions": [permissiondata.permission_id.toString()],
//                 "operation": "remove-permission"
//             },
//             roleData.role_id, true
//         ));
//         // setSelectedPermissionsOption(()=>{
//         //   roleData.Permissions.splice(roleData.Permissions.indexOf(permissiondata),1)
//         // });
//         setdeletePermissionConfirmDialougeController(false);
//     }

//     const rolesEdit = (roleData) => {
//       setroleEditInput(!roleEditInput);
//     };
    
//     const RolesEditDataOnChange = (event) => {
//         setroleDescription(event.target.value);
//     };

//     const rolesEditedDataSave = (roleData) => {
//         dispatch(ModifyPermisssionListToSingleRole(
//             {
//                 "role_id": roleData.role_id.toString(),
//                 "role_description": roleDescription,
//                 "operation": "modify"
//             },
//             roleData.role_id, false
//         ));  
//         setroleEditInput(false);
//     };

//     const ShowRoleDeleteConfirmation = (roleData) =>{
//         setneedToDeleteRoledata(roleData);
//         setdeleteRoleConfirmDialougeController(true);
//     }

//     const handledeleteRoleConfirmDialougeClose = () => {
//         setdeleteRoleConfirmDialougeController(false);
//     };



//     return (
//         <Box>
//             <Accordion expanded={expanded === `panel${roleData.role_id}`} onChange={handleChange(`panel${roleData.role_id}`, roleData.role_id)} >
//                 <AccordionSummary aria-controls={`panel${roleData.role_id}d-content`} id={`panel${roleData.role_id}d-header`}>
//                     <Table aria-label="collapsible table" >
//                         <TableBody>
//                             <TableRow>
//                                 <TableCell width="50" sx={{borderBottom: "none",  }} align="left">{roleData.role_id}</TableCell>
//                                 <TableCell width="100" sx={{borderBottom: "none", fontSize: 16,fontWeight: 'bold' }} align="left">{roleData.role_name}</TableCell>
//                                 { roleEditInput ? 
//                                 <TableCell width="300" sx={{borderBottom: "none", }} align="left">
//                                     <TextField
//                                         required
//                                         size='small'
//                                         id="outlined-required"
//                                         defaultValue={roleData.role_description}
//                                         onChange={RolesEditDataOnChange}
//                                     />
//                                 </TableCell>
//                                 :
//                                 <TableCell width="300" sx={{borderBottom: "none", }} align="left">{roleData.role_description}</TableCell> }
//                                 <TableCell width="50" sx={{borderBottom: "none", }} align="left">
//                                     { roleEditInput ? 
//                                         <Tooltip title="Save" placement="bottom">
//                                             <IconButton
//                                             aria-label="expand row"
//                                             size="small"
//                                             onClick={() => rolesEditedDataSave(roleData)}
//                                             >
//                                             <SaveAsOutlinedIcon/>
//                                             </IconButton>
//                                         </Tooltip>
//                                     :
//                                         null
//                                     }
//                                     <AccessControl allowedPermissions={["modifyRole"]} >
//                                         <Tooltip title="Edit" placement="bottom">
//                                             <IconButton
//                                             aria-label="expand row"
//                                             size="small"
//                                             onClick={() => rolesEdit(roleData)}
//                                             >
//                                             <EditNoteOutlinedIcon/>
//                                             </IconButton>
//                                         </Tooltip>
//                                     </AccessControl>
//                                     <AccessControl allowedPermissions={["deleteRole"]} >
//                                         <Tooltip title="Delete" placement="right">
//                                             <IconButton
//                                             aria-label="expand row"
//                                             size="small"
//                                             onClick={() => ShowRoleDeleteConfirmation(roleData)}
//                                             >
//                                             <DeleteOutlinedIcon/>
//                                             </IconButton>
//                                         </Tooltip>
//                                     </AccessControl>
//                                 </TableCell>
//                             </TableRow>
//                         </TableBody>
//                     </Table>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <Box
//                         style={{display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'space-between',}}
//                     >
//                         <Typography variant="h5" gutterBottom component="div" sx={{ fontSize: 16,fontWeight: 'bold'}}>
//                             Permissions List
//                         </Typography>
//                         <Box
//                             style={{float: 'right' , display: 'flex', width:500}}
//                         >
//                             <AccessControl allowedPermissions={["modifyRole"]} >
//                                 <Select
//                                     placeholder='Add New Permissions'
//                                     isMulti
//                                     isSearchable
//                                     closeMenuOnSelect={false}
//                                     isClearable={true}
//                                     options={permissionList}
//                                     components={animatedComponents}
//                                     value={selectedPermissionsOption}
//                                     onChange={setSelectedPermissionsOption}
//                                     classNamePrefix="select"
//                                     maxMenuHeight={80}
//                                     noOptionsMessage={()=>"No More Permissions Avaliable..."}
//                                     styles={{
//                                     placeholder:(baseStyles, states) => ({
//                                         ...baseStyles,
//                                         color:"black"
//                                     }),
//                                     dropdownIndicator:(baseStyles) => ({
//                                         ...baseStyles,
//                                         color:"black"
//                                     }),
//                                     control: (baseStyles, state) => ({
//                                         ...baseStyles,
//                                         borderColor: 'grey',
//                                         height: '35px',
//                                         width:'370px'
//                                     }),
//                                     valueContainer: (base) => ({
//                                         ...base,
//                                         maxHeight: 35,
//                                         overflowY: "auto",
//                                     }),
//                                     option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
//                                     ...baseStyles,
//                                     backgroundColor: isFocused ? "grey" : colors.ReactSelectDropDownOptionsBackground,
//                                     })
//                                     }}
//                                 />
//                                 &nbsp;&nbsp;&nbsp;
//                                 <Button 
//                                     flexdirection='column' 
//                                     variant="contained"
//                                     // size="small"
//                                     onClick={() => {
//                                         permissionAdd(roleData);
//                                     }}
//                                 >
//                                     Add Permission
//                                 </Button>
//                             </AccessControl>
//                         </Box>
//                     </Box>
//                     <Box key={`spacing-${roleData.role_id}`} sx={{ m: 1 }} />
//                     <Box>
//                         <Table size="small" aria-label="permissions" >
//                             <TableHead >
//                                 <TableRow>
//                                     <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission ID</TableCell>
//                                     <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission Name</TableCell>
//                                     <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Permission Description</TableCell>
//                                     <TableCell sx={{ fontSize: 13,fontWeight: 'bold'}}>Created Date</TableCell>
//                                     <TableCell align="center" sx={{ fontSize: 13,fontWeight: 'bold'}}>
//                                         <AccessControl allowedPermissions={["deletePermission"]} >
//                                             Actions
//                                         </AccessControl>
//                                     </TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {
//                                     rolesPermissionsDataLoading ? 
//                                     <TableRow key='Data load'>
//                                         <TableCell component="th" scope="row"><Skeleton variant="rounded" animation="wave"/></TableCell>
//                                         <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
//                                         <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
//                                         <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
//                                         <TableCell align="center">
//                                             <AccessControl allowedPermissions={["deletePermission"]} >
//                                                 <Skeleton variant="rounded" animation="wave"/>
//                                             </AccessControl>
//                                         </TableCell> 
//                                     </TableRow>
//                                     : rolesPermissionsDataError ?
//                                     <TableRow key='Data fetch Failed'>
//                                     </TableRow>
//                                     :rolesPermissionsData[roleData.role_id]?.length === 0 || rolesPermissionsData[roleData.role_id] === undefined ?
//                                     <TableRow key='NO Data key'>
//                                         <TableCell component="th" scope="row"></TableCell>
//                                         <TableCell></TableCell>
//                                         <TableCell>No Permission Assigned to this role</TableCell>
//                                         <TableCell></TableCell>
//                                         <TableCell></TableCell>
//                                     </TableRow>
//                                     :
//                                     <>
//                                         {rolesPermissionsData[roleData.role_id].map((permission) => (
//                                             <TableRow key={permission.permission_id}>
//                                                 <TableCell component="th" scope="row">{permission.permission_id}</TableCell>
//                                                 <TableCell>{permission.permission_name}</TableCell>
//                                                 <TableCell>{permission.permission_description}</TableCell>
//                                                 <TableCell>{ moment(permission.created_on).format("MMMM Do, YYYY - h:mm A") }</TableCell>
//                                                 <TableCell align="center">
//                                                     <AccessControl allowedPermissions={["deletePermission"]} >
//                                                         <Tooltip title="Delete" placement="right">
//                                                             <IconButton
//                                                                 aria-label="expand row"
//                                                                 size="small"
//                                                                 onClick={() => {
//                                                                         permissiondeletefromRole(roleData,permission);
//                                                                     }
//                                                                 }
//                                                             >
//                                                                 <DeleteOutlinedIcon/>
//                                                             </IconButton>
//                                                         </Tooltip>
//                                                     </AccessControl>
//                                                 </TableCell> 
//                                             </TableRow>
//                                         ))}
//                                     </>
                                    
//                                 }
//                             </TableBody>
//                         </Table>
//                         <Dialog
//                             open={deletePermissionConfirmDialougeController}
//                             TransitionComponent={Transition}
//                             keepMounted
//                             onClose={handledeletePermissionConfirmDialougeClose}
//                             aria-describedby="alert-dialog-slide-description"
//                         >
//                             <DialogTitle>{`Do you want to delete ${needToDeletePermissiondata.permission_name} Permission for the ${roleData.role_name} role?`}</DialogTitle>
//                             <DialogActions>
//                                 <Button onClick={handledeletePermissionConfirmDialougeClose}>No</Button>
//                                 <Button onClick={()=>{handledeletePermissionConfirmDialougeAccept(roleData,needToDeletePermissiondata)}}>Yes</Button>
//                             </DialogActions>
//                         </Dialog>

//                     </Box>
//                 </AccordionDetails>
//             </Accordion>
//             <Box key={roleData.role_id} sx={{ m: 1 }} />

//             <Dialog
//                 open={deleteRoleConfirmDialougeController}
//                 TransitionComponent={Transition}
//                 keepMounted
//                 onClose={handledeleteRoleConfirmDialougeClose}
//                 aria-describedby="alert-dialog-slide-description"
//             >
//                 <DialogTitle>{`Do you want to delete ${needToDeleteRoledata.role_name} permanenetly?`}</DialogTitle>
//                 <DialogActions>
//                     <Button onClick={handledeleteRoleConfirmDialougeClose}>No</Button>
//                     <Button onClick={()=>{roleDeleteFunc(needToDeleteRoledata);handledeleteRoleConfirmDialougeClose()}}>Yes</Button>
//                 </DialogActions>
//             </Dialog>

//         </Box>
// )
// }
