import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem,IconButton } from '@mui/material';
import { Delete } from "@mui/icons-material";

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Slide from '@mui/material/Slide';



const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


function LimitDetailsForm({kpiLimitationFormData, setkpiLimitationFormData, selectedTableColumns, errorActive}){

  const handleRemoveDataItem = (index, keyName) => {
    let newObj={}
    const allItems = [...kpiLimitationFormData[keyName]];
    allItems.splice(index, 1);
    newObj[keyName]=[...allItems]
    setkpiLimitationFormData({...kpiLimitationFormData, ...newObj});
  };

  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [cardExpanded, setcardExpanded] = React.useState('');
  
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
        <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_limit_box`}>
          <Accordion key={`panel_data_limit`} expanded={cardExpanded === `panel_data_limit`} 
            onChange={handleCardExpansionChange(`panel_data_limit`)}
          >
            <AccordionSummary aria-controls={`panel_data_limit-content`} id={`panel_data_limit-header`}>
              <Typography sx={{fontSize:"20px"}}>Limit</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                <Typography  sx={{fontSize:"15px"}}>Add data for Limit</Typography>
                <Button 
                  variant="contained"
                  disabled={Object.keys(kpiLimitationFormData).includes('limitValue')}
                  size="small"
                  onClick={() => {
                      setkpiLimitationFormData({...kpiLimitationFormData, limitValue: ''})
                  }}
                >
                  Add Limit Value
                </Button>
              </Box>
              {Object.keys(kpiLimitationFormData).includes('limitValue') ?
                <Box p={2}>
                <Box key={`add_Limit`} pb={2}
                  sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                >
                  <Box width={"100%"}
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
                  >
                    <TextField
                        fullWidth
                        id={`add_limit_field`}
                        label="Enter Query Limit"
                        variant="outlined"
                        name = 'limitValue'
                        type='number'
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
                            e.preventDefault()
                          }
                        }}
                        value={kpiLimitationFormData.limitValue || ''}
                        error={errorActive ? kpiLimitationFormData.limitValue === '': false}
                        onChange={e => {
                        // let change_join_data = [...kpiDetailsData.havingClauseValue]
                        let {name, value} = e.target
                        // change_join_data[index][name] = value;
                        setkpiLimitationFormData({...kpiLimitationFormData, limitValue: value})
                        }}
                    >
                    </TextField>
                  </Box>
                  <Box height={'100%'} pt={1}
                    sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                  >
                    <IconButton
                      // sx={{position: "relative", top: "-72px"}}
                      onClick={() => {
                        let newObj = {...kpiLimitationFormData}
                        delete newObj.limitValue
                        setkpiLimitationFormData({...newObj})
                      }}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              </Box> : <></>}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_offset_box`}>
          <Accordion key={`panel_data_offset`} expanded={cardExpanded === `panel_data_offset`} 
            onChange={handleCardExpansionChange(`panel_data_offset`)}
          >
            <AccordionSummary aria-controls={`panel_data_offset-content`} id={`panel_data_offset-header`}>
              <Typography sx={{fontSize:"20px"}}>Offset</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                <Typography  sx={{fontSize:"15px"}}>Add data for Offset</Typography>
                <Button 
                  variant="contained"
                  disabled={Object.keys(kpiLimitationFormData).includes('offsetValue')}
                  size="small"
                  onClick={() => {
                      setkpiLimitationFormData({...kpiLimitationFormData, offsetValue: ''})
                  }}
                >
                  Add Offset Value
                </Button>
              </Box>
              {Object.keys(kpiLimitationFormData).includes('offsetValue') ?
                <Box p={2}>
                <Box key={`add_offset`} pb={2}
                  sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                >
                  <Box width={"100%"}
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
                  >
                    <TextField
                        fullWidth
                        id={`add_offset_field`}
                        label="Enter Query Offset"
                        variant="outlined"
                        name = 'offsetValue'
                        type='number'
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
                            e.preventDefault()
                          }
                        }}
                        value={kpiLimitationFormData.offsetValue || ''}
                        error={errorActive ? kpiLimitationFormData.offsetValue === '': false}
                        onChange={e => {
                        // let change_join_data = [...kpiDetailsData.havingClauseValue]
                        let {name, value} = e.target
                        // change_join_data[index][name] = value;
                        setkpiLimitationFormData({...kpiLimitationFormData, offsetValue: value})
                        }}
                    >
                    </TextField>
                  </Box>
                  <Box height={'100%'} pt={1}
                    sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                  >
                    <IconButton
                      // sx={{position: "relative", top: "-72px"}}
                      onClick={() => {
                        let newObj = {...kpiLimitationFormData}
                        delete newObj.offsetValue
                        setkpiLimitationFormData({...newObj})
                      }}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              </Box> : <></>}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_orderBy_box`}>
          <Accordion key={`panel_data_orderBy`} expanded={cardExpanded === `panel_data_orderBy`} 
            onChange={handleCardExpansionChange(`panel_data_orderBy`)}
          >
            <AccordionSummary aria-controls={`panel_data_orderBy-content`} id={`panel_data_orderBy-header`}>
              <Typography sx={{fontSize:"20px"}}>Order By</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                <Typography  sx={{fontSize:"15px"}}>Add data for Order By</Typography>
                <Button 
                  variant="contained"
                  // disabled={Object.keys(kpiLimitationFormData).includes('orderByValue')}
                  size="small"
                  onClick={() => {
                    // let change_join_data = [...kpiLimitationFormData.orderByValue]
                    // let {name, value} = e.target
                    // change_join_data[input_index].dataColumn = value;
                    setkpiLimitationFormData({...kpiLimitationFormData, orderByValue: [...kpiLimitationFormData.orderByValue, {dataColumn: "", sortType: "ASC"}]});
                  }}
                >
                  Add Order By
                </Button>
              </Box>
              {Object.keys(kpiLimitationFormData).includes('orderByValue') ?
                <Box p={2}>
                  {kpiLimitationFormData.orderByValue.map((input_item, input_index) => (
                  <Box key={`add_orderBy`} pb={2}
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                  >
                    <Box width={"100%"}
                      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
                    >
                      <Box width={"100%"} key={`order_by_box_${input_index}`}
                        sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                      >
                        <Box width={`49%`} key={`add_order_by_column_box_${input_index}`}>
                          <TextField
                            fullWidth
                            id={`add_order_by_column_field_${input_index}`}
                            select
                            label="Select A Column"
                            variant="outlined"
                            name = 'dataColumn'
                            value={kpiLimitationFormData.orderByValue[input_index].dataColumn || ''}
                            error={errorActive ? kpiLimitationFormData.orderByValue[input_index].dataColumn === '': false}
                            onChange={e => {
                            let change_join_data = [...kpiLimitationFormData.orderByValue]
                            let {name, value} = e.target
                            change_join_data[input_index].dataColumn = value;
                            setkpiLimitationFormData({...kpiLimitationFormData, orderByValue: change_join_data});
                            }}
                          >
                            <MenuItem key={`add_please_select_dataColumn_${input_index}`} value='' disabled>
                                Please Select A Column
                            </MenuItem>
                            {selectedTableColumns.map((option) => (
                            <MenuItem key={`add_dataColumn_${input_index}_${option}`} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        <Box width={`49%`} key={`add_order_by_sort_type_box_${input_index}`}>
                          <TextField
                            fullWidth
                            id={`add_order_by_sort_type_field_${input_index}`}
                            select
                            label="Select A Sorting Order"
                            variant="outlined"
                            name = 'sortType'
                            value={kpiLimitationFormData.orderByValue[input_index].sortType || 'ASC'}
                            error={errorActive ? kpiLimitationFormData.orderByValue[input_index].sortType === '': false}
                            onChange={e => {
                            let change_join_data = [...kpiLimitationFormData.orderByValue]
                            let {name, value} = e.target
                            change_join_data[input_index].sortType = value;
                            setkpiLimitationFormData({...kpiLimitationFormData, orderByValue: change_join_data});
                            }}
                          >
                            <MenuItem key={`add_sortType_ASC_${input_index}`} value='ASC'>
                              Ascending Order
                            </MenuItem>
                            <MenuItem key={`add_sortType_DESC_${input_index}`} value='DESC'>
                              Descending Order
                            </MenuItem>
                          </TextField>
                        </Box>
                      </Box>
                    </Box>
                    <Box height={'100%'} pt={1}
                      sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                    >
                      <IconButton
                        // sx={{position: "relative", top: "-72px"}}
                        onClick={() => handleRemoveDataItem(input_index, 'orderByValue')}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                  ))}
              </Box> : <></>}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
    </Box>
  );
}


export default LimitDetailsForm;
  