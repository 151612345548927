import { 
    SET_TEXT_EDITOR_DATA,
    SET_ARTICLE_DATA,
    SET_CATEGORY_DATA ,
    SET_CATEGORY_DATA_LOADING,
    SET_DOCUMENTATION_DATA
} from "../constants/adminDocumentationconstants"


let initialState={
    textEditorData:null,
    articleData:null,
    articleDataLoading:true,
    categoryData:null,
    category_data_loading:true,
    documentationData:null,
    defaultImage:"https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=900",

}

export let adminDocumentationReducer=(state=initialState,action)=>{
    // console.log("action dispatched",action)
    switch(action.type){
        case (SET_TEXT_EDITOR_DATA) : return (
            state = {
                ...state,
                textEditorData:action.payload
            }
        )

        case (SET_ARTICLE_DATA) : return (
            state = {
                ...state,
                articleData:action.payload
            }
        )

        case (SET_CATEGORY_DATA) : return (
            state = {
                ...state,
                categoryData:action.payload
            }
        )

        case (SET_CATEGORY_DATA_LOADING) : return (
            state = {
                ...state,
                category_data_loading:action.payload
            }
        )

        case (SET_DOCUMENTATION_DATA) : return (
            state= {
                ...state,
                documentationData:action.payload
            }
        )

        default : return state
    }
}