import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";

function AreaGraph({ data, width, height }) {
  console.log(data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [hashedKeyData,setData] = useState({})

  useEffect(()=>{
    data.data.infoData.yAxis.map((item,index)=>{
      setData({
        ...hashedKeyData,
        [`${index}`+Math.random().toString("16").slice(2)]:item
      })
    })
  },[])
  

  return (
    <AreaChart
      width={width}
      height={height}
      data={data.data.data}
      margin={{
        top: 5,
        right: 25,
        bottom: 5,
      }}
    >
      <defs>
        {
          Object.keys(hashedKeyData).map((key,index)=>{
            console.log(key)
            return(
              <linearGradient id={key} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={hashedKeyData[key].color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={hashedKeyData[key].color} stopOpacity={0} />
              </linearGradient>
            )
          })
        }
      </defs>
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey={data.data.infoData.xAxis} />
      <YAxis />
      <Tooltip />
      <Legend />

      {
        Object.keys(hashedKeyData).map((key, index) => {
          console.log(key)
          return (
            <Area
              key={index}
              type="monotone"
              dataKey={hashedKeyData[key].label}
              // stroke="#8884d8"
              stroke={hashedKeyData[key].color}
              strokeWidth={2}
              fillOpacity={1}
              fill={`url(#${key})`}
            />
          )
        })
      }
    </AreaChart>
  );
}

export default AreaGraph;

// import React from 'react';
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";



// function AreaGraph({ data, width, height }) {
//   return (
//     <AreaChart
//       width={width}
//       height={height}
//       data={data.data.data}
//       margin={{
//         top: 5,
//         right: 25,
//         bottom: 5,
//       }}
//     >
//       <defs>
//         <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//           <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
//           <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
//         </linearGradient>
//       </defs>
//       {/* <CartesianGrid strokeDasharray="3 3" /> */}
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Legend />
//       <Area
//         type="monotone"
//         dataKey="uv"
//         // stroke="#8884d8"
//         stroke="#4a3aff"
//         strokeWidth={2}
//         fillOpacity={1}
//         fill="url(#colorUv)"
//       />
//     </AreaChart>
//   );
// }

// export default AreaGraph