import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, FormControl, FormControlLabel, Checkbox, Button, Grid, createTheme, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import AuthContext from "../../Auth/authProvider"
import PasswordChecklist from "react-password-checklist"
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { UserNewPasswordSet, verifyUser } from "../../actions/userActions";
import { useNavigate } from 'react-router-dom';
import { showGlobalSnackbar } from '../../actions/globalSnackbarActions';


function PasswordComponent({ email }) {

    let { loginUser,setUser } = useContext(AuthContext)

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { error: userInfoError, loading: userInfoLoading, userInfo, regularUser } = userLogin;

    const navigate = useNavigate()

    // const navigate=useNavigate()

    const [showTempPassword, setShowTempPassword] = React.useState(false);
    const [createNewPasswordButton, setcreateNewPasswordButton] = React.useState(true);
    
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = React.useState(false);

    const [rememberMe, setRememberMe] = useState(false);

    const [tempPassword, settempPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")

    const [TempPasswordFlag, setTempPasswordFlag] = React.useState(true);
    const [loginEnbledFlag,setLoginEnbledFlag] = React.useState(true)

    useEffect(() => {
        const storedRememberMe = localStorage.getItem('rememberMe');
        if (storedRememberMe === 'true') {
            setRememberMe(true);
        }
    }, []);


    const handleLogin = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get("password") !== '') {
            // loginUser({ email_id: email.trim(), password: data.get("password") });
            dispatch(
                verifyUser(
                    {username:email.trim(),new_password:data.get("password")},
                    navigate,
                    setUser,
                    setLoginEnbledFlag
                )
            )
            if (rememberMe) {
                localStorage.setItem('rememberedEmail', email.trim());
                localStorage.setItem('rememberMe', true);
            }
            else {
                localStorage.removeItem('rememberedEmail');
                localStorage.removeItem('rememberMe');
            }
        }

    };

    function handleSetNewPassword(event) {
        // event.preventDefault();
        // console.log("handleSetNewPassword",password,password)
        if (password !== '' && confirmPassword !== '') {
            dispatch(
                UserNewPasswordSet(
                    { username: email.trim(), new_password: password, temporary_password: tempPassword ,confirm_new_password:confirmPassword},
                    navigate,
                    setUser,
                    setLoginEnbledFlag
                )
            );
        }

    };

    // custom themes
    // const buttonDisabledError = createTheme({
    //     palette: {
    //         action: {
    //             disabledBackground: "#CD0B0B",
    //             disabled:"white",
    //         }
    //     }
    // })


    return (
        <>
            {regularUser === true && (userInfoLoading === false) ?
                <Box
                    component="form"
                    autoComplete="on"
                    onSubmit={handleLogin}
                    sx={{ mt: 3 }}
                >

                    <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />}
                        label="Remember me"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!loginEnbledFlag}
                    >
                        Log In
                    </Button>
                </Box>
                : regularUser === false && (userInfoLoading === false) ?
                    <Box
                        component="form"
                        autoComplete="on"
                        // onSubmit={handleLogin}
                        sx={{ mt: 3 }}
                    >
                        <FormControl variant="outlined" fullWidth required sx={{ mb: 1.5 }}>
                            <InputLabel htmlFor="outlined-adornment-password">Temporary Password</InputLabel>
                            <OutlinedInput
                                id="temppassword"
                                name="temppassword"
                                label="Temporary Password"
                                type={showTempPassword ? 'text' : 'password'}
                                value={tempPassword} //present for testing
                                autoComplete="current-password"
                                onChange={(event) => {
                                    settempPassword(event.target.value)
                                    // console.log("createNewPasswordButton: ", createNewPasswordButton )
                                    if ( event.target.value !== "" && event.target.value !== password) {
                                        // console.log("onchage", password )
                                        setTempPasswordFlag(false)
                                    } 
                                    else {
                                        setTempPasswordFlag(true)
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowTempPassword((show) => !show)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showTempPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // This is used to disable the copy,cut and paste options on the temporary password field. To enable it just uncomments the lines below-
                                // onCut={(e) => e.preventDefault()}
                                // onCopy={(e) => e.preventDefault()}
                                // onPaste={(e) => e.preventDefault()}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth required sx={{ mb: 1.5 }}>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    if(tempPassword !== e.target.value){
                                        setTempPasswordFlag(false)
                                    }
                                    else{
                                        setTempPasswordFlag(true)
                                    }
                                }}
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth required sx={{ mb: 1.5 }}>
                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setshowConfirmPassword((show) => !show)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setconfirmPassword(e.target.value)
                                    if(tempPassword !== password){
                                        setTempPasswordFlag(false)
                                    }
                                    else{
                                        setTempPasswordFlag(true)
                                    }
                                }}
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </FormControl>

                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "lowercase", "capital", "match"]}
                            minLength={8}
                            value={password}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => {
                                if (isValid && tempPassword !== "") {
                                    console.log("isValid",isValid)
                                    setcreateNewPasswordButton(false)
                                } else {
                                    console.log("isValid else",isValid)
                                    setcreateNewPasswordButton(true)
                                }
                            }}
                            // style={{ backgroundColor : 'blue'}}
                            iconComponents={{ ValidIcon: <DoneAllOutlinedIcon sx={{ color: 'green', mr: 0.5 }} />, InvalidIcon: <BlockOutlinedIcon sx={{ color: 'orange', mr: 0.5 }} /> }}
                        />
                        {/* <ThemeProvider theme={buttonDisabledError}> */}
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={createNewPasswordButton || TempPasswordFlag}
                                onClick={(event)=>{
                                    handleSetNewPassword(event)
                                    console.log("createNewPasswordButton",createNewPasswordButton)
                                    console.log("password",password)
                                    console.log("tempPassword",tempPassword)
                                    
                                    // && tempPassword != password && password === confirmPassword && password !== "" && confirmPassword !== "" ? false : true
                                    //  && tempPassword !== password && password === confirmPassword
                                    // if(createNewPasswordButton){
                                    //     handleSetNewPassword(event)
                                    // }
                                    // else{
                                    //     dispatch(
                                    //         showGlobalSnackbar(
                                    //             "Check if your password satisfies the password policy",
                                    //             "error"
                                    //         )
                                    //     )
                                    // }
                                    
                                     
                                }}
                                color={"success"}
                                // color={tempPassword !== password ? "success" : "error"}
                            >
                                Create New Password 
                                {/* {
                                    tempPassword !== password ? "Create New Password" : "Temporary Password can't be same as the new passowrd"
                                } */}
                            </Button>
                        {/* </ThemeProvider> */}

                    </Box>
                    :
                    <></>
            }

            <Grid container>
                <Grid item xs >
                </Grid>
                <Grid item>
                    <Button pt={0}
                        component={Link}
                        to={'/forget-password'}
                    >
                        <Typography variant='subtitle2' textAlign='right'>
                            Forgot Password
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PasswordComponent