import {
  GET_ALL_CRM_EVENT_REQUEST,
  GET_ALL_CRM_EVENT_SUCCESS,
  GET_ALL_CRM_EVENT_FAIL,
  CRM_EVENT_APPROVAL_SUCCESS,
} from "../constants/adminUserConstants";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";

export const GetCRMEventPaginatedData = (limit, offset) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: GET_ALL_CRM_EVENT_REQUEST });

    responseData = await handleApiCalls(
      "get",
      `approval/fetch-approval-request?limit=${limit}&pageNo=${offset+1}`,
      "approveUser"
    );

    if (responseData[2] === "") {
      dispatch({
        type: GET_ALL_CRM_EVENT_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: GET_ALL_CRM_EVENT_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    dispatch({
      type: GET_ALL_CRM_EVENT_FAIL,
      payload: error,
    });
    dispatch(showGlobalSnackbar(error, "error"));
  }
};


export const userCRMEventApproval = (approvalData,setApprovalFailureStatus) => async (dispatch) => {
  let responseData = [];

  try {
    setApprovalFailureStatus(false)
    responseData = await handleApiCalls(
      "post",
      `approval/modify-approval-request`,
      "fetchApproveUser",
      {
        approval_id: approvalData[1],
        approval_status: approvalData[0],
      }
    );
      console.log("responseData",responseData) 
    if(+responseData[0]===206){
       dispatch(showGlobalSnackbar(responseData[2],"error"))
       setTimeout(()=>{
        setApprovalFailureStatus(null)
      },20000)
    }
    else if (responseData[2] === "") {
      // dispatch({
      //   type: CRM_EVENT_APPROVAL_SUCCESS,
      //   payload: approvalData,
      // });
      dispatch(GetCRMEventPaginatedData(10,0));
    }
    else {
      dispatch(showGlobalSnackbar(responseData[2], "error"));
      // throw responseData[2]
    }

    

  } catch (error) {
    console.log("error",error.response.data["error-message"])
    
    dispatch(showGlobalSnackbar(error.response.data["error-message"], "error"));
    setTimeout(()=>{
      setApprovalFailureStatus(null)
    },20000)
  }
};